import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

	componentDidMount() {
		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/theme.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {
			$(window).scrollTop(0);
		});
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			
			<footer className="footer pt-3  ">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="copyright text-center text-sm text-muted text-lg-start">
                © 2022, TextMercato
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                <li className="nav-item">
                    <Link  onClick={() => {window.location.href="/"} } to="/"  className="nav-link text-muted">Home</Link>
                 
                </li>
                <li className="nav-item">
                    <Link onClick={() => {window.location.href="/product"} } to="/product" className="nav-link text-muted">Product</Link>
                </li>
                <li className="nav-item">
                    <Link onClick={() => {window.location.href="/platform"} } to="/platform" className="nav-link text-muted">Platform</Link>
                </li>
                <li className="nav-item">
                    <Link onClick={() => {window.location.href="/Services"} } to="/Services"  className="nav-link text-muted">Services</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

		)
	}
}


export default Footer