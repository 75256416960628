import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV8 extends Component {

    render() {

        let publicUrl = process.env.REACT_APP_PUBLIC_URL+'/'

    return	<section className="service-four  go-top">
		  <div className="container">
		    <div className="row">

                <div className="col-lg-4 col-md-12">
                    <div className="cta-three__content  go-top">
                        <div className="block-title-two text-left my-auto">
                            <p className='mt-5'>why choose us</p>
                            <h3>One-stop-solution for all your digital content requirements</h3>
                        
                        </div>{/* /.block-title-two */}
                        
                        <p className='mt-3'>
 Be it Text, Graphics, Translations, or any other needs. We have you covered.</p>
                        
                         <Link to="/get-started" className="thm-btn banner-two__btn mt-3 ">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}

                        {/* /.thm-btn cta-three__btn */}
                    </div>{/* /.cta-three__content */}
                </div>
                <div className="col-lg-8 col-md-12">
                    <div className='row'>
                    
                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-file-lines" />
                        <h3><Link to="/services/text-content" data-target="#text">Text</Link></h3>
                        <p>Over 10K approved writers are ready to share their expertise on your requirements. Be it Blogs, Articles, SEO, Social Media Posts, Emailers,  Website Copy, or any other content.</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}
                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-bezier-curve" />
                        <h3><Link to="/services/graphics" data-target="#graphics">Graphics</Link></h3>
                        <p>A good graphic can explain simple or complex ideas in an easy-to-understand, recallable way. Use infographics, illustrations, images, and more to communicate with your customers.</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}
                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-language" />
                        <h3><Link to="/services/translations" data-target="#translations" >Translations</Link></h3>
                        <p>A majority of readers are more comfortable consuming content in their regional language. Our translation services in more than 50+ languages helps you connect better with your target audience.</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}

                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-file-audio" />
                        <h3><Link  to="/services#transcriptions" data-target="#transcriptions">Transcriptions</Link></h3>
                        <p>Video to Text, Audio to Text! Let us know what your transcription needs are, and we can contribute positively.</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}
                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-video" />
                        <h3><Link  to="/services#video" data-target="#video">Video</Link></h3>
                        <p>Coming soon</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}
                    <div className="col-lg-4 col-6 col-md-6">
                        <div className="service-four__single">
                        <i className="fal fa-microphone" />
                        <h3><Link  to="/services#audio" data-target="#audio">Audio</Link></h3>
                        <p>Coming soon</p>
                        </div>{/* /.service-four__single */}
                    </div>{/* /.col-lg-3 col-md-6 */}
                    



                    </div>
                </div>


		      
		    </div>{/* /.row */}
		    
         


		  </div>{/* /.container */}
		</section>



        }
}

export default ServiceV8