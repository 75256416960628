import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./libs/auth/authSlice";



export const preloadedStateConfig = () => {
    if (typeof window !== 'undefined') {
        let token = localStorage.getItem('token');
        let name = localStorage.getItem('name');
        let email = localStorage.getItem('email');
        let role = localStorage.getItem('role');

        let state = { user: {value: {name:name, email:email, role:role, token: token}}}
        console.log(state);
        return state;
    }
}

const Store = configureStore({
    reducer:{
        user: authSlice
    },
    preloadedState: preloadedStateConfig()
})

export default Store;