import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FaqV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="faq-two">
        <div className="container">
          <div className="row">
            
           
            
            
            <div className="col-lg-5">
              <div className="faq-two__content">
                <div className="block-title-two text-left">
                  
                  <h3>FAQs</h3>
                </div>{/* /.block-title-two */}

                <ul className='faq-questions-list'>
                  <li><a href='#faq-1'>Is there a cost to create an account?</a></li>
                  <li><a href='#faq-2'>I have multiple pieces to be written. How do I go about it?</a></li>
                  <li><a href='#faq-3'>I have a large requirement, how do I go about it?</a></li>
                  <li><a href='#faq-4'>What if I do not like the work? </a></li>
                  <li><a href='#faq-5'>Can I choose my writer/content creator? </a></li>
                  
                </ul>

                

                
                
              </div>{/* /.faq-two__content */}
            </div>{/* /.col-lg-6 */}


            <div className="col-lg-7 d-flex justify-content-center">
              
            <div className="inner">
                <div id='faq-1' className='faq-answer-block active'>
                  <h2>Is there a cost to create an account?</h2>
                  <p> No, the account creation is free. The payment is required only when you place an order. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                  
                </div>
                <div id='faq-2' className='faq-answer-block '>
                  <h2>I have multiple pieces to be written. How do I go about it?</h2>
                  <p>Create an account, go to the Order Creation screen, where you can place a bulk order. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-3' className='faq-answer-block '>
                  <h2>I have a large requirement, how do I go about it?</h2>
                  <p>We are here to fulfill all your needs.  Click to contact us and we will connect with you to understand your needs better. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-4' className='faq-answer-block '>
                  <h2>We Provide Best Consulting Service</h2>
                  <p>In a rare case, where the content creator has not adhered to the brief agreed upon or there are concerns regarding the quality of delivery, the amount is refunded. However, the first step is to address your concerns and provide you with the solution. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-5' className='faq-answer-block '>
                  <h2>Can I choose my writer/content creator? </h2>
                  <p>We are in the process of enabling the option to access your chosen writers or onboard your own writers. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                
            </div>{/* /.inner */}
                
            
            </div>{/* /.col-lg-6 */}




            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>

        }
}

export default FaqV5