import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect } from 'react';

const PostProject = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const id_token = localStorage.getItem('id_token');
    
    const history = useHistory();
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('');

    
    useEffect(() => {
        const logincssUrl =   publicUrl + "assets/css/login.css";
        const loginstyle = document.createElement("link");
        loginstyle.href = logincssUrl;
        loginstyle.rel = "stylesheet";
        loginstyle.async = true;
        document.head.appendChild(loginstyle);

        
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/post-project.js";
        document.body.appendChild(minscript);
    })

    const handleOnClick = e => {
    e.preventDefault();
        setLoading(true);
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{                            
            // submitData(token);
            history.push("/client-order-review");
        }
    }



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                   
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                       

                        <div className="main active">
                            
                        <div className="text mb-4 mt-0">
                                <h3 className='color-blue'> Images   </h3>
                                
                            </div>

                            
                            <div className='requirements '>
                                <div className='requirement_summary info'>
                                    <table  className="table borderless">
                                    
                                            <tbody>
                                                <tr>
                                                    <td className="topic_col">
                                                        <div className="media">
                                                            <a className="thumbnail pull-left" href="#">  </a>
                                                            <div className="media-body">
                                                                <strong className="media-heading expand-requirement"><i class="fa fa-angle-down"></i> Article 1</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td className="content_type_col">Article's Name</td>
                                                    <td className="category_col">Blogs/Articles</td>
                                                    <td className="category_col"><i class="fa fa-rupee-sign"></i>500</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='requirment_edit images_edit'>
                                        <div className="input-text radio-group" >
                                            <div className="input-div choose_image_option">
                                                
                                                <label className='sourceofpublish'><input type='radio' name='image[0]'  data-message="Please select atleast one option" required require value="Your Image" /> <span>Your Image</span></label>
                                                <label className='sourceofpublish'><input type='radio' name='image[0]' data-message="Please select atleast one option" required require value="Image from Text Mercato" /> <span> Image from Text Mercato</span></label>
                                            </div>
                                        </div>


                                        <div className='own_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                    <strong>Upload your image *</strong><br></br><br></br>
                                                        <input type="file" name="own_image" placeholder='Upload source file'  />
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='textMercato_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Banner (enter size) *</label>
                                                        <input type="text" name="banner_size"  />
                                                    </div>
                                                </div>
                                                <div className="input-text"  >
                                                        <div className="input-div">
                                                            <label>Image Ratio * </label>
                                                            <select name='image_ratio'  data-other="structure_preference_other"  className=''>
                                                                <option value=''>Select </option>
                                                                <option value="Potrait  ">Potrait  </option>
                                                                <option value="Landscape">Landscape</option>
                                                                <option value="Square">Square</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Comments </label>
                                                        <textarea name="title"  />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>







                                <div className='requirements '>
                                <div className='requirement_summary info'>
                                    <table  className="table borderless">
                                    
                                            <tbody>
                                                <tr>
                                                    <td className="topic_col">
                                                        <div className="media">
                                                            <a className="thumbnail pull-left" href="#">  </a>
                                                            <div className="media-body">
                                                                <strong className="media-heading expand-requirement"><i class="fa fa-angle-down"></i> Article 1</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td className="content_type_col">Article's Name</td>
                                                    <td className="category_col">Blogs/Articles </td>
                                                    <td className="category_col"><i class="fa fa-rupee-sign"></i>200</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='requirment_edit images_edit'>
                                        <div className="input-text radio-group" >
                                            <div className="input-div choose_image_option">
                                                
                                                <label className='sourceofpublish'><input type='radio' required require name='image[1]' data-message="Please select atleast one option"  value="Your Image" /> <span>Your Image</span></label>
                                                <label className='sourceofpublish'><input type='radio' required require name='image[1]' data-message="Please select atleast one option"  value="Image from Text Mercato" /> <span> Image from Text Mercato</span></label>
                                            </div>
                                        </div>


                                        <div className='own_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                    <strong>Upload your image *</strong><br></br><br></br>
                                                        <input type="file" name="own_image" placeholder='Upload source file'  />
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='textMercato_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Banner (enter size) *</label>
                                                        <input type="text" name="banner_size"  />
                                                    </div>
                                                </div>
                                                <div className="input-text"  >
                                                        <div className="input-div">
                                                            <label>Image Ratio * </label>
                                                            <select name='image_ratio' data-other="structure_preference_other"  className=''>
                                                                <option value=''>Select </option>
                                                                <option value="Potrait  ">Potrait  </option>
                                                                <option value="Landscape">Landscape</option>
                                                                <option value="Square">Square</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Comments </label>
                                                        <textarea name="comment"  />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                            
 
 
 

                           

                            

                          
                            <div className="buttons button_space">
                                <Link to="/client-order-content-project-details" className="backBtn thm-btn">Back</Link>
                                <Link className="nextBtn thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Continue'}</Link>
                            </div>
                        </div>


                        
 
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="title" required require />
                                    <span>Enter Title</span>
                                </div>
                                
                            </div>


                            <div className="input-text" data-custom="word_count_custom_modal" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Enter Word Count</h5>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>700</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>1500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2000</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div  className="input-text  customBox">
                                <div id='word_count_custom_modal' className="input-div hidden">
                                    <input type="tel" name="custom_count"  />
                                    <span>Enter Word Count </span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="keywords" required require />
                                    <span>Please enter your keywords or say "Add Keywords" if you want us to </span>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Enter Keyword density, internal linking requirement or others </span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="target_audience" required require />
                                    <span>Tell us about your readers </span>
                                    
                                </div>
                            </div>

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>

    </div>
}

export default PostProject

