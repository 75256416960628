import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';


import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const PostProjectGraphics = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    <div className="left-side">
                       
                        
                        <div className="steps-content">
                            <h3>Step <span className="step-number">1</span></h3>
                            <p className="step-number-content active">Enter your project information.</p>
                            <p className="step-number-content d-none">Let's get started with the basic details to create your project.</p>
                            <p className="step-number-content d-none">Customize your items with all the details.</p>
                           
                            <p className="step-number-content d-none">Dos / Don’t's </p>                            
                            <p className="step-number-content d-none">Choose the experience and skill level of your creators</p>
                            <p className="step-number-content d-none">Your writing is tailored based on your goals and audiences.</p>
                            
                            <ul className="progress-bar">
                                <li className="active">What do you want to create today?</li>
                                <li>Create a Project</li>
                                <li>Add Project Details</li>
                               
                                <li>Dos / Don’t's </li>                                
                                <li>Choose Your Creator </li>
                                <li>Review Order </li>
                            </ul>
                        </div>
                        
                        

                        
                    </div>
                    <div className="right-side post-content">
                        <div className='registration-form' >    
                        <div className="main active">
                        
                            <div className="text">
                                <h2>What do you want to create today?</h2>
                               
                            </div>

                            <div className='row mt-5'>
                    
                            <Link to="/post-project" for="text" className="col-lg-4 col-md-6 content-type ">
                                    <input id="text" type='radio' name="type" value="text" />
                                    <div className="service-four__single">
                                        <i className="fal fa-file-lines" />
                                        <h3><span>Text Content</span></h3>
                                        <p>Over 10K approved writers are ready to share their expertise on your requirements. Be it Blogs, Articles, SEO, Social Media Posts, Emailers,  Website Copy, or any other content.</p>
                                    </div>{/* /.service-four__single */}
                                </Link>{/* /.col-lg-3 col-md-6 */}

                                <Link to="/post-project-translation" for="translation" className="col-lg-4 col-md-6 content-type ">
                                    <input id='translation' type='radio'   name="type" value="translation" />
                                    <div className="service-four__single">
                                        <i className="fal fa-language" />
                                        <h3><span  >Translation </span></h3>
                                        <p>A majority of readers are more comfortable consuming content in their regional language. Our translation services in more than 50+ languages helps you connect better with your target audience.</p>
                                    </div>{/* /.service-four__single */}
                                </Link>{/* /.col-lg-3 col-md-6 */}

                                <Link to="/post-project-graphics"  for="graphics" className="col-lg-4 col-md-6 content-type active">
                                    <input id="graphics" type='radio' checked="" name="type" value="graphics" />
                                    <div className="service-four__single">
                                        <i className="fal fa-bezier-curve" />
                                        <h3><span >Graphics</span></h3>
                                        <p>A good graphic can explain simple or complex ideas in an easy-to-understand, recallable way. Use infographics, illustrations, images, and more to communicate with your customers.</p>
                                    </div>{/* /.service-four__single */}
                                </Link>{/* /.col-lg-3 col-md-6 */}

                            </div>




                             
                            
                         
                            <div className="buttons">
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>
                        <div className="main">
                            
                            <div className="text">
                                <h2>Create a Project</h2>
                                <p>Let's get started with the basic details to create your project</p>
                            </div>
                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name='project_name' required require />
                                    <span>Enter Project Name </span>
                                </div>
                                
                            </div>
                         
 
  


                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>


                        <div className="main">
                            
                            <div className="text">
                                <h2>Add Project Details</h2>
                                <p>Customize your items with all the details</p>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name='project_name' required require />
                                    <span>Enter Graphics Name </span>
                                </div>
                                
                            </div>
                            

                            <div className="input-text">
                                <div className="input-div">
                                <input type="text" name='project_name' required require />
                                <span>Google drive/Onedrive links </span>
                                   
                                </div>
                            </div>
                         

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Please enter your project information</span>
                                </div>
                            </div>

                       
                           


                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>




 


                        <div className="main">
                            
                            <h2> Dos / Don’t's </h2>
                                
                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="dos"  placeholder='' ></textarea>
                                    <span>Dos</span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="donts"  placeholder=" " ></textarea>
                                    <span>Don’t's</span>
                                </div>
                            </div>

                                
    
    
    

                                <div className="input-text">
                                    <div className="input-div">
                                        <textarea name="comment"  placeholder='' ></textarea>
                                        <span>Any other comments ?</span>
                                    </div>
                                </div>

                                <div className="input-text">
                                    <div className="input-div">
                                    <strong>Upload reference</strong><br></br><br></br>
                                        <input type="file" name="reference" placeholder='Upload source file'  />
                                    
                                    </div>
                                </div>
 

                            
                                <div className="buttons button_space">
                                    <button className="back_button thm-btn">Back</button>
                                    <button className="next_button thm-btn">Next Step</button>
                                </div>
                        </div>


                        





                        <div className="main ">
                        
                            <div className="text">
                                <h2>Choose the experience and skill level of your creators</h2>
                               
                            </div>

                            <div className='row mt-5'>
                    
                                <label for="generic" className="col-lg-4 col-md-6 content-type active skill_level">
                                    <input id="generic" type='radio' checked="" name="skill_level" value="generic" />
                                    <div className="service-four__single">
                                        <i className="fal fa-dollar-sign" />
                                        <h3><span>Experienced Designers</span></h3>
                                        <p>1-4 years of design experience. Upto 2 revisions allowed.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                <label for="advanced" className="col-lg-4 col-md-6 content-type skill_level">
                                    <input id='advanced' type='radio' name="skill_level" value="advanced" />
                                    <div className="service-four__single">
                                        <i className="fal fa-dollar-sign" /> <i className="fal fa-dollar-sign" />
                                        <h3><span  >Professional Designers </span></h3>
                                        <p>5+ years of design experience who also understand your industry and target audience. Upto 2 revisions allowed.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                

                            </div>




                             
                            
                         
                            <div className="buttons button_space ">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>













                        
                        
                        
                        <div className="main">
                           
                            <div className="text">
                                <h2>Review Order</h2>
                                <p>Your writing is tailored based on your goals and audiences.</p>
                            </div>
                           
                            <div className=" orderReview mt-5">
                                <div className="row">
                                    
                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-pull-12 col-sm-pull-12">
                                        <div className="panel panel-default">
                                           
                                            <div className="panel-body">
                                            <table id='projectsTable' className="table borderless">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Project Name </strong></td>
                                                        <td><strong>Graphics Name </strong></td>
                                                        
                                                        <td className="text-center"><strong>Quantity</strong></td>
                                                        <td className="text-right"><strong>Cost</strong></td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-3">
                                                            <div className="media">
                                                                <a className="thumbnail pull-left" href="#">  </a>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading"> Product Name</h6>
                                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                       
                                                        <td className="">Name</td>
                                                        <td className="text-center">1</td>
                                                        <td className="text-right"><i class="fa fa-rupee-sign"></i> 32.99</td>
                                                        <td className="text-right reviewOrderAction">
                                                            <a data-title="Article's Name" data-count="5" data-customCount="" data-keywords="" data-instructions="" data-target_audience="" className='editRow ' href='#'><i class="fa fa-pencil"></i></a>
                                                            <a className='deleteRow' href='#'><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-3">
                                                            <div className="media">
                                                                <a className="thumbnail pull-left" href="#">  </a>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading"> Product Name</h6>
                                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                       
                                                        <td className="">Name</td>
                                                        <td className="text-center">1</td>
                                                        <td className="text-right"><i class="fa fa-rupee-sign"></i> 32.99</td>
                                                        <td className="text-right reviewOrderAction">
                                                            <a data-title="Article's Name" data-count="5" data-customCount="" data-keywords="" data-instructions="" data-target_audience="" className='editRow ' href='#'><i class="fa fa-pencil"></i></a>
                                                            <a className='deleteRow' href='#'><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-push-12 col-sm-push-12">
                                        <div className="panel panel-default order-review-total">
                                           
                                            <div className="panel-body">
                                                    <div className="col-md-12">
                                                        <strong>Subtotal </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>200.00</span></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <strong>Tax (GST)</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>50.00</span></div>
                                                    </div>
                                                   
                                                    <div className="col-md-12">
                                                        <strong>Order Total</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>250.00</span></div>
                                                       
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>



                            <div className="buttons button_space mt-5">
                                <button id='submitNew' className="thm-btn thm-btn2" data-toggle="modal" data-target="#addnew"><i className="fal fa-plus" />  Add another graphic </button>
                                <button className="back_button thm-btn">Back</button>
                                <button className="submit_button thm-btn">Pay</button>
                            </div>
                        </div>


                        <div className="main">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            
                            <div className="text congrats">
                                <h2>Congratulations!</h2>
                                <p>Thanks Mr./Mrs. <span className="shown_name"></span> your information have been submitted successfully for the future reference we will contact you soon.</p>
                            </div>
                        </div>

                        </div>                    
                    

                    

                    </div>
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>

        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name='title' required require />
                                    <span>Enter Graphics Name </span>
                                </div>
                                
                            </div>
                            

                            <div className="input-text">
                                <div className="input-div">
                                <input type="text" name='project_refrence' required require />
                                <span>Google drive/Onedrive links </span>
                                   
                                </div>
                            </div>
                         

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Please enter your project information</span>
                                </div>
                            </div>

                       
                           


                             
 
 
 

                             

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>



    </div>
}

export default PostProjectGraphics

