import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect } from 'react';

const PostProject = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const id_token = localStorage.getItem('id_token');
    const [toast, setToast] = useState(null);
    const history = useHistory();
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('quick');

    const [formId, setFormId] = useState('quick');

    const [uploadBrief, setUploadBrief] = useState('');
    const [requirment, setRequirment] = useState('');

    const [goal, setGoal] = useState('');
    const [PointofView, setPointofView] = useState('');
    const [writing_tone, setTone] = useState('');
    const [preference, setPreference] = useState('');
    const [dos, setDos] = useState('');
    const [donts, setDonts] = useState('');
    const [reference, setReference] = useState('');
    const [aboutReaders, setAboutReaders] = useState('');
    const [Guidelines, setGuidelines] = useState('');
    const [metaDescription , setMetaDescription] = useState('');
    
    const [customGoal, setCustomGoal] = useState(false);
    const [customTone, setCustomTone] = useState(false);
    const [customPreference, setcustomPreference] = useState(false);

    

    const handleOnChange = e => {
        
         let value = e.target.value;
         let name = e.target.name;
         
         let sectionId = e.target.closest('.collapse').id;
         let selectedTab = 'quick';
         if(sectionId == 'detail_brief'){ selectedTab = 'detail'; }

         if(name === 'goal' && value === 'custom'){ if(customGoal === false){ setCustomGoal(true); }else{ setCustomGoal(false); } }
         if(name === 'writing_tone' && value === 'custom'){ if(customTone === false){ setCustomTone(true); }else{ setCustomTone(false); } }
         if(name === 'preference' && value === 'custom'){ if(customPreference === false){ setcustomPreference(true); }else{ setcustomPreference(false); } }

         if(selectedTab !== formId){
            let tabName = 'Detail Client brief';
            if(selectedTab == 'detail'){ tabName = 'Quick Client brief ' }
            setToast( "All the data has been removed from "+tabName );            
        }


        setFormId(selectedTab);
    }

    const dismisToast = e => {
        setToast(null)
    }
    


    useEffect(() => {
        const logincssUrl =   publicUrl + "assets/css/login.css";
        const loginstyle = document.createElement("link");
        loginstyle.href = logincssUrl;
        loginstyle.rel = "stylesheet";
        loginstyle.async = true;
        document.head.appendChild(loginstyle);

        
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/post-project.js";
        document.body.appendChild(minscript);
    })
    
    const handleTabChange = e => {
        
        
        let val = e.target.value;

        if(val !== activeTab){
            let tabName = 'Detail Client brief';
            if(val == 'detail'){ tabName = 'Quick Client brief ' }
            // setToast( "All the data has been removed from "+tabName );
            setActiveTab(val);
        }
        

        // if(e.target.classList.contains('Inputerrors')){
        //     setLoading(false);
        // }else{                            
        //     // submitData(token);
        //     history.push("/client-order-images");
        // }
    }


    const handleOnClick = e => {
        e.preventDefault();
        setLoading(true);
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{                            
            // submitData(token);
            history.push("/client-order-images");
        }
    }



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
        
       
        



            <div className="card">
                <div className="form">
                  
                    <div className="right-side post-content">
                    <div className='registration-form' >    


 
 

                        <div className="main active" >
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'> Enter Your Project Details</h3>
                                <p>To get the best outcome, please give us as relevant details as possible</p>
                            </div>


                            <div className="input-text radio-group" data-custom="goal">
                                <div className="input-div">
                                    
                                    
                                    <label className='sourceofpublish'><input onClick={(e) => handleTabChange(e)} checked={ activeTab === 'quick' ? 'checked' : '' } type='radio' name='quick' value="quick" /> <span>Quick Client brief</span></label>
                                    <label className='sourceofpublish'><input onClick={(e) => handleTabChange(e)} checked={ activeTab === 'detail' ? 'checked' : '' } type='radio' name='quick' value="detail" /> <span>Detail Client brief </span></label>
                                    
                                    <div className='clearfix'></div>
                                    <label>Please select only one option</label>


                                    {
            toast !== null ? (
                <div class="toast" data-autohide="false" style={{ marginTop: '-100px', right:0 }}>
            <div class="toast-header">
            <strong class="mr-auto text-primary">Notification</strong>
            {/* <small class="text-muted"></small> */}
            <button type="button" onClick={(e) => dismisToast(e)}  class="ml-2 mb-1 close" data-dismiss="toast">&times;</button>
            </div>
            <div class="toast-body">{ toast }</div>
        </div>
            ) : ''
        }
                                   
                                </div>
                            </div>


                            <div className='clientBrefieAccordian'>

                                <div style={{ borderTop: '1px solid rgba(223, 243, 253, 0.57)', marginTop:'30px', borderRadius:'20px' }}  className={ activeTab === 'quick' ? 'collapse show' : 'collapse' } id="quick_brief">


                                    <div className="input-text">
                                        <div className="input-div">
                                            <label>Tell us about your requirment </label>
                                            <textarea onChange={(e) => handleOnChange(e)} name="about_readers"  placeholder='' ></textarea>
                                        </div>
                                    </div>

                                    <div className="input-text">
                                        <div className="input-div">
                                            <label>Upload brief </label><br></br>
                                            <input onChange={(e) => handleOnChange(e)} type="file" name="reference" placeholder='Upload source file'  />                                            
                                        </div>
                                    </div>
                            </div>
 

                            <div style={{ borderTop: '1px solid rgba(223, 243, 253, 0.57)', marginTop:'30px', borderRadius:'20px' }}  className={ activeTab === 'detail' ? 'collapse show' : 'collapse' }  id="detail_brief">
                           
                            <div className="input-text radio-group" data-custom="goal">
                                <div className="input-div">
                                    <label>Project Goal </label><br></br>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='goal' value="Promotional/Leads" /> <span>Promotional/Leads</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='goal' value="SEO/Traffic " /> <span>SEO/Traffic </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='goal' value="Educational/Informative " /> <span>Educational/Informative </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='goal' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>
                            
                        { customGoal === true ? (
                            <div id='goal' className="input-text ">
                                <div className="input-div">
                                <label>Custom Project Goal </label>
                                    <input type="text" name="goal_custom"  />
                                </div>
                            </div>
                        ) : '' }
                            

                           

                            <div className="input-text radio-group oneCheckboxAllowed" data-custom="point_of_view">
                                <div className="input-div">
                                    <label>Point of View </label><br></br>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='PointofView' value="First person" /> <span>First person</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='PointofView' value="Second person" /> <span>Second person</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='PointofView' value="Third person" /> <span>Third person</span></label>
                                </div>
                            </div>



                            <div className="input-text radio-group oneCheckboxAllowed" data-custom="writing_tone_custom" >
                                <div className="input-div">
                                    <label>Tone of voice </label><br></br>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Formal" /> <span>Formal</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Humorous/Entertaining" /> <span>Humorous/Entertaining</span></label>
                                    
                                   
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Informal / Casual" /> <span>Informal / Casual </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Optimistic " /> <span>Optimistic </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Friendly " /> <span>Friendly </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Assertive " /> <span>Assertive </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Curious" /> <span>Curious</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Worried" /> <span>Worried</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Encouraging " /> <span>Encouraging </span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="Worried" /> <span>Surprised</span></label>
                                    <label className='sourceofpublish'><input onChange={(e) => handleOnChange(e)} type='checkbox' name='writing_tone' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>
                            

                            { customTone === true ? (
                            <div id='writing_tone_custom' className="input-text ">
                                <div className="input-div">
                                <label>Custom Tone of voice </label>
                                    <input  onChange={(e) => handleOnChange(e)} type="text" name="writing_tone_custom"  />
                                    
                                </div>
                            </div>
                            ) : '' }









                            <div className="input-text radio-group oneCheckboxAllowed" data-custom="structure_preference_custom" >
                                <div className="input-div">
                                    <label>Do you have a structure preference?  </label><br></br>
                                    <label className='sourceofpublish'><input type='checkbox'  onChange={(e) => handleOnChange(e)} name='preference' value="Sub-headings" /> <span>Sub-headings</span></label>
                                    <label className='sourceofpublish'><input type='checkbox'  onChange={(e) => handleOnChange(e)} name='preference' value="Bulleted" /> <span>Bulleted</span></label>
                                    <label className='sourceofpublish'><input type='checkbox'  onChange={(e) => handleOnChange(e)} name='preference' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>
                        { customPreference === true ? (
                            <div id='structure_preference_custom' className="input-text ">
                                <div className="input-div">
                                <label>Custom Structure Preference </label>
                                    <input type="text" onChange={(e) => handleOnChange(e)} name="preference"  />
                                    
                                </div>
                            </div>
                        ) : '' }








                            <div className="input-text">
                                <div className="input-div">
                                <label>Dos</label>
                                    <textarea name="dos" onChange={(e) => handleOnChange(e)}  placeholder='' ></textarea>                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                <label>Don’t's</label>
                                    <textarea name="donts" onChange={(e) => handleOnChange(e)}  placeholder=" " ></textarea>
                                    
                                </div>
                            </div>


                            <div className="input-text">
                                <div className="input-div">
                                    <label>Any Reference Blogs/Links</label>
                                    <textarea name="reference_link" onChange={(e) => handleOnChange(e)}  placeholder='' ></textarea>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <label>Tell us about your readers? </label>
                                    <textarea name="about_readers" onChange={(e) => handleOnChange(e)}  placeholder='' ></textarea>
                                </div>
                            </div>
                            

                            <div className="input-text">
                                <div className="input-div">
                                <label>Any Guidelines</label><br></br>
                                <input type="file" name="guidelines" onChange={(e) => handleOnChange(e)} placeholder='Upload source file'  />
                                
                                </div>
                            </div>

                            
                            <div className="input-text terms-condition-checkbox">
                                <div className="input-div form-group">
                                    <input id='is_meta_description' onChange={(e) => handleOnChange(e)} type="checkbox" name='meta_description' value="1"  />
                                    <label for="is_meta_description">Do you need meta description for all? </label>

                                </div>
                            </div>

                            </div></div>


                            
                            <div className='mt-5 clearfix'></div>
                          
                            <div className="buttons button_space">
                                <Link to="/client-order-content-requirements" className="backBtn thm-btn">Back</Link>
                                <Link className="nextBtn thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Continue'}</Link>
                            </div>
                            <p className=''>Since multiple content types have been chosen, the team will get in touch with you for updating rest of the content briefs</p>
                        </div>

                    

                        
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        
 

    </div>
}

export default PostProject

