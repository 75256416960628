import React, { useEffect } from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const PostProject = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const id_token = localStorage.getItem('id_token');
    const get_project_id = localStorage.getItem('project_id');
    const history = useHistory();
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [master, setMaster] = useState(null);
    const [errors, setErrors] = useState(null);
    const [project_id, setProject_id] = useState(get_project_id);
    const [customCount, setCustomCount] = useState(false);


    const [inputFields, setInputFields] = useState([
        {title: '', order_content_type_id:'', content_creator_id:'', keywords:'', words_id:'', custom_words:'', images_id:'', additional_comments:'', order_type_id:type, industry_id:industry, project_id:project_id}
    ])

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;

        if(event.target.name == 'words_id' && event.target.value === 'custom_count'){
            setCustomCount(true)
        }else{
            setCustomCount(false)
        }

        console.log(customCount);
        setInputFields(data);
     }

     const addFields = () => {
        let newfield =  {title: '', order_content_type_id:'', content_creator_id:'', keywords:'', words_id:'', custom_words:'', images_id:'', additional_comments:'', order_type_id:type, industry_id:industry, project_id:project_id}
    
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const handleOnClick = e => {
        e.preventDefault();
            setLoading(true);
            if(e.target.classList.contains('Inputerrors')){
                setLoading(false);
            }else{                            
                submitData(token);
                history.push("/client-order-content-project-details");
            }
        }

        const submitData = token => {
            let articles = { articles: inputFields }
            // call a backend API to verify reCAPTCHA response
            fetch('http://devapi.textmercato.com/order/api/create-order-requirement/'+project_id, {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+id_token
              },
              body: JSON.stringify(articles)
            }).then(res => {
                console.log(res);
                if(res.status == 200){
                    return res.json();
                }else{
                    let data = res.json();
                    setErrors(data.message);
                }
                
            
            }).then(res => {
                setLoading(false);
                if(res.status == 200 || res.status == 201){
                history.push("/client-order-content-project-details");
                }
                // if(res.data.projectID){
                    
                    // localStorage.setItem('project_id', res.data.projectID);
                    // setProject_id(res.data.projectID);
                    // history.push("/client-order-content-requirements");
                // }                               
            });
          }

          const MasterData = token => {
            fetch('http://devapi.textmercato.com/order/api/get-masterData', {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+id_token
                }
            }).then(res => res.json()).then(res => {            
                if(res.data){
                    setMaster(res.data[0]);
                    localStorage.setItem('masterData', JSON.stringify(res.data[0]))

                }
            });
        }

        const getProjectInfo = projectID => {
            fetch('http://devapi.textmercato.com/order/api/get-order-requirement/'+projectID, {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+id_token
                }
            }).then(res => res.json()).then(res => {      
                console.log(res);      
                // if(res.data){ 
                //     console.log(res);
                //     // if(res.data.project_name !== null){ setName(res.data.project_name) }
                //     if(res.data.industry_id !== null){ setIndustry(res.data.industry_id) }
                // }
            });
        }
    
        useEffect(() => {
            getProjectInfo(project_id);
            let getMaster = localStorage.getItem('masterData');            
            if(getMaster){                
                setMaster(JSON.parse(getMaster));
                if(master !== null){
                    MasterData(token);
                }
            }else{
                MasterData(token);
            }
        }, []);
        

    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                     
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                          

                        <div className="main active">
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'>Your Requirements </h3>
                                <p className='sub-heading-link'>Choose a single piece or multiple pieces of content (one per line). </p>

                                {/* <Link className='upload-bulk thm-btn thm-btn2' to="/bulk-upload">Bulk Upload</Link> */}
                            </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            <div className='requirements table-header '>

                            <div className='requirement_summary '>
                                <table  className="table borderless">
                                <thead>
                                    <tr>
                                        <td className='topic_col'><strong>Title/Topic</strong></td>
                                        <td className='content_type_col'><strong>Content Type  </strong></td>
                                        <td className="category_col"><strong>Creator Category</strong></td>
                                        <td className="words_col"><strong>Words</strong></td>
                                        <td className="cost_col"><strong>Price</strong></td>
                                        <td className="cost_col"><strong>Delivery</strong></td>
                                        <td className='text-right'></td>
                                    </tr>
                                </thead>
                                </table>
                                </div>
                                </div>

                            { inputFields.map((input, index) => {

                                return (

                            <div className='requirements aritcle-requirment first_item'>

                            <div className='requirement_summary info'>
                                <table  className="table borderless">
                                
                                        <tbody>
                                            <tr>
                                                <td className="topic_col">
                                                    <div className="media">
                                                        <a className="thumbnail pull-left" href="#">  </a>
                                                        <div className="media-body">
                                                            <strong className="media-heading expand-requirement"><i className="fa fa-angle-down"></i> Article 1</strong>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td className="content_type_col">Article's Name</td>
                                                <td className="category_col">Blogs/Articles </td>
                                                
                                                <td className="words_col">500</td>
                                                <td className="cost_col"><i className="fa fa-rupee-sign"></i> 32.99</td>
                                                <td className="words_col">3-5 days</td>
                                                <td className="text-right reviewOrderAction"><a onClick={() => removeFields(index)} className='deleteRequirment' href='javascript:void(0)'><i className="fa fa-times"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                
                                <div className='requirment_edit'>


                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Title/Topic *</label>
                                        <input onChange={event => handleFormChange(index, event)}   placeholder='Example: "10 things to do in London' type="text" className='updateTabTitle' name="title" required require="true" />
                                        {/* <p className='note'>"</p> */}
                                    </div>
                                </div>

                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Content Type * </label>
                                        <select name='industry' data-other="Content_type_other" required require="true" className=''>
                                            <option value=''>Select </option>
                                            <option value="Blogs/Articles ">Blogs/Articles </option>
                                            <option value="SEO Articles">SEO Articles</option>
                                            <option value="Website Content ">Website Content </option>
                                            <option value="Landing Page">Landing Page</option>
                                            <option value="other">Other (Enter Custom)</option>
                                        </select>
                                    </div>
                                </div>
                            
                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Creator Category * </label>
                                        <select name='industry' data-other="Creator_Category_other" required require="true" className=''>
                                            <option value=''>Select </option>
                                            <option value="Blogs/Articles ">Blogs/Articles </option>
                                            <option value="SEO Articles">SEO Articles</option>
                                            <option value="Website Content ">Website Content </option>
                                            <option value="Landing Page">Landing Page</option>
                                            <option value="other">Other (Enter Custom)</option>
                                        </select>
                                    </div>
                                </div>

                                

                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Keywords </label>
                                        <input onChange={event => handleFormChange(index, event)} type="text" placeholder='Separate using commas' name="keywords"  />
                                    
                                    </div>
                                </div>


                                <div className="input-text" data-custom="word_count_custom" >
                                    <div className="input-div">
                                        <label>Words * </label>

                                        <select name='words' data-other="custom_count" required require="true" className=''>
                                            <option value=''>Select </option>
                                            <option value="500">500</option>
                                            <option value="700">700</option>
                                            <option value="1500">1500</option>
                                            <option value="2000">2000</option>
                                            <option value="2500">2500</option>
                                            <option value="custom_count">Custom</option>
                                        </select>
                                       
                                    </div>
                                </div>
                            {
                                customCount == true ? (
                                    <div className="input-text">
                                    <div className="input-div">
                                        <label>Custom </label>
                                        <input onChange={event => handleFormChange(index, event)} type="text" placeholder='No of words' name="custom_count"  />
                                    </div>
                                </div>
                                ) : ''
                            }
                                


                                <div className="input-text"  >
                                    <div className="input-div">
                                        <label>Need images  </label>
                                        <select name='no_of_images'  className=''>
                                            <option value=''>Select </option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="input-text"  >
                                <div className="input-div ">
                                    <label>Additional Comments </label>
                                    <textarea onChange={event => handleFormChange(index, event)} name="additional_comments"  className='' placeholder='Enter any specific article requirement here, general requirements are captured in the following screens' ></textarea> 
                                    </div>
                                </div>


                            </div>
                            </div>



                                )
                            })}

                            

                            </div>


                            <div onClick={addFields} className='add-more-requirements mb-5'><i className="fa fa-plus"></i> Add More</div>
                           
                            <div className='col-md-12 summary-total'>
                                <div className="panel panel-default order-review-total">
                                    <h4>Your Order </h4>
                                    <div className="panel-body">
                                            <div className="col-md-12">
                                                <strong>Total  </strong>
                                                <div className="pull-right"><i className="fa fa-rupee-sign"></i><span>200.00</span></div>
                                            </div>
                                            <div className="col-md-12">
                                                <strong>Tax/GST </strong>
                                                <div className="pull-right"><i className="fa fa-rupee-sign"></i><span>50.00</span></div>
                                            </div>
                                            
                                            <div className="col-md-12">
                                                <strong>To Pay </strong>
                                                <div className="pull-right"><i className="fa fa-rupee-sign"></i><span>250.00</span></div>
                                                
                                            </div>
                                            
                                    </div>
                                    
                                </div>
                                <p className='note'>The prices here are for text content, if images are selected it will be updated on the next screens</p>
                                
                            </div>

                            



                        </div>

                          
                            <div className="buttons button_space">
                                <Link to="/client-order-enter-project-name" className="backBtn thm-btn">Back</Link>
                                {/* <Link to="/client-order-content-project-details" className="nextBtn thm-btn">Continue</Link> */}
                                <Link className="nextBtn thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Continue'}</Link>
                            </div>
                            <p className=''>Since multiple content types have been chosen, the team will get in touch with you for updating rest of the content briefs</p>
                        </div>



 

  
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


    

    </div>
}

export default PostProject

