import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v2 extends Component {

	componentDidMount() {
		const $ = window.$;

		let publicUrl = process.env.REACT_APP_PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/theme.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {
			$(window).scrollTop(0);
		});
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			<div className="site-footer-two  go-top">
				
				<div className="site-footer-two__upper">
					<div className="container">
						<div className="row">
							
							
							<div className="col-xl-4 col-lg-6">
								<div className="footer-widget footer-widget__about">
									<Link to="/">
										<img width="150" src={publicUrl + "assets/images/logo-2-1.png"} alt={imgattr} />
									</Link>
									<p>Quality content is the key to add value to the success of your business, connect with your audience, and keep them coming back for more. TextMercato is designed to power your digital content needs to the next level.</p>
									<div className="footer-widget__social">
										<a href="https://www.facebook.com/tmcontentwriting" target='_blank'><i className="fab fa-facebook-f" /></a>
										<a href="https://twitter.com/TextMercato" target='_blank'><i className="fab fa-twitter" /></a>
										<a href="https://www.instagram.com/textmercatocontentwriting/" target='_blank'><i className="fab fa-instagram" /></a>
										<a href="https://www.linkedin.com/company/text-mercato-solutions-private-limited/mycompany/" target='_blank'><i className="fab fa-linkedin" /></a>
									</div>{/* /.footer-widget__social */}
								</div>{/* /.footer-widget footer-widget__about */}
							</div>{/* /.col-lg-3 */}
							



							<div className="col-xl-8 col-lg-6">
								<div className='row'>
										<div className="col-xl-3  col-6  col-lg-6">
											<div className="footer-widget footer-widget__links">
												<h3 className="footer-widget__title">Content Type</h3>{/* /.footer-widget__title */}
												<ul className="footer-widget__links-list list-unstyled">
													<li><Link to="/">Website Blogs</Link></li>
													<li><Link to="#">Website Content</Link></li>
													<li><Link to="/">SEO Articles</Link></li>
													<li><Link to="/">Copywriting</Link></li>
													<li><Link to="/">Ebooks</Link></li>
													<li><Link to="/">Whitepapers</Link></li>

													<li><Link to="/">Social Media</Link></li>
													<li><Link to="#">Thought Leadership</Link></li>
													<li><Link to="/">Product Descriptions</Link></li>
													<li><Link to="/">Buying & Selling Guide</Link></li>
													<li><Link to="/">Market Reports</Link></li>
												</ul>{/* /.footer-widget__links-list */}
											</div>{/* /.footer-widget footer-widget__post */}
										</div>{/* /.col-lg-3 */}


										






										{/* <div className="col-xl-2 col-lg-6">
											<div className="footer-widget footer-widget__links">
												<h3 className="footer-widget__title">Who We Are</h3>
												<ul className="footer-widget__links-list list-unstyled">
													<li><Link to="/">Company</Link></li>
													<li><Link to="/">Our Culture</Link></li>
													<li><Link to="/">News</Link></li>
													<li><Link to="/">Contact us</Link></li>
													<li><Link to="/">Careers</Link></li>
													<li><Link to="/">Privacy Policy</Link></li>
												</ul>
											</div>
										</div> */}


		
										<div className="col-xl-2  col-6  col-lg-6">
											<div className="footer-widget footer-widget__links">
												<h3 className="footer-widget__title">Services</h3>{/* /.footer-widget__title */}
												<ul className="footer-widget__links-list list-unstyled">
												<li><Link to="/services/text-content" data-target="#text-content" >Text Content</Link></li>
												<li><Link to="/services#video"  data-target="#video">Video</Link></li>
												<li><Link to="/services/graphics" data-target="#graphics">Graphics</Link></li>
												<li><Link to="/services#audio" data-target="#audio">Audio</Link></li>
												<li><Link to="/services#transcriptions" data-target="#transcriptions">Transcriptions</Link></li>
												<li><Link to="/services/translations" data-target="#translations">Translations</Link></li>
												</ul>{/* /.footer-widget__links-list */}
											</div>{/* /.footer-widget footer-widget__post */}
										</div>{/* /.col-lg-3 */}

										



										

										<div className="col-xl-2  col-6  col-lg-6">
											<div className="footer-widget footer-widget__links">
												<h3 className="footer-widget__title">Companies</h3>{/* /.footer-widget__title */}
												<ul className="footer-widget__links-list list-unstyled">
													<li><Link to="/">Startups</Link></li>
													<li><Link to="/">SMEs</Link></li>
													<li><Link to="/">Midmarket</Link></li>
													<li><Link to="/">Enterprise</Link></li>
													
												</ul>{/* /.footer-widget__links-list */}
											</div>{/* /.footer-widget footer-widget__post */}
										</div>{/* /.col-lg-3 */}

										








										

										<div className="col-xl-2  col-6  col-lg-6">
											<div className="footer-widget footer-widget__links">
												<h3 className="footer-widget__title">Industries</h3>{/* /.footer-widget__title */}
												<ul className="footer-widget__links-list list-unstyled">
													<li><Link to="/">Finance</Link></li>
													<li><Link to="/">Education</Link></li>
													<li><Link to="/">Technology</Link></li>
													<li><Link to="/">E-commerce</Link></li>
													<li><Link to="/">Fashion</Link></li>
													<li><Link to="/">Travel</Link></li>

													<li><Link to="/">Real Estate</Link></li>
													<li><Link to="/">Hospitality</Link></li>
													<li><Link to="/">Healthcare</Link></li>
													<li><Link to="/">Automotive</Link></li>
												</ul>{/* /.footer-widget__links-list */}
											</div>{/* /.footer-widget footer-widget__post */}
										</div>{/* /.col-lg-3 */}


								</div>
							</div>
							
							


							


							

						</div>{/* /.row */}
					</div>{/* /.container */}
				</div>{/* /.site-footer-two__upper */}
				<div className="site-footer-two__bottom">
					<div className="container">
						<p>&copy; 2023 <a >Text Mercato Solutions Pvt Ltd.</a> All Rights Reserved. <a href={publicUrl+"cookie-policy"} >Cookie policy</a>  |
						<a href={publicUrl+"privacy-policy"} >Privacy policy</a> | 
						<a href={publicUrl+"terms-of-use"} >Terms of use</a>
						 </p>
					</div>{/* /.container */}
				</div>{/* /.site-footer-two__bottom */}
			</div>
		)
	}
}


export default Footer_v2