import React from 'react';
import Navbar from './global-components/navbar-v2';
import Contact from './section-components/contact-page';
import Footer from './global-components/footer-v2';
import { Helmet } from 'react-helmet';
const ContactPage = () => {
    return <div>
        <Helmet>
            <title>Contact us | Text Mercato</title>
            <meta name="Contact Us." />
        </Helmet>
        <Navbar />
        <section className="page-header" >
				  <div className="container">
				    <h2>We’d love to hear from you</h2>
				    <p className='text-white mt-2'>Have questions about our products, features, trials, or pricing? Need a demo? <br></br>
Our teams will help you.</p>
				  </div>{/* /.container */}
			</section>
        <Contact />
        
        <Footer className="noTopPadding" />
    </div>
}

export default ContactPage

