import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import NavbarV2 from '../global-components/navbar-v2';
import Footer_v2 from '../global-components/footer-v2';
import Calltoaction from '../section-components/cta';

const Tags = () => {


	let publicUrl = process.env.PUBLIC_URL+'/'
	let limit = 12;
	let offset = 1;
	//let page = 1;
	
	let { slug, page } = useParams();
	let PaginationPath = "blog/tag/"+slug+"/page/";
	const location = useLocation();
	if(page === undefined){ page = 1 }


	// var path = window.location.pathname;
	// var pageNumber = parseInt(path.split('/').pop());

	// if(Number.isInteger(pageNumber)){  page = pageNumber; }

	if(page > 1){ offset = (page - 1) * limit; }

	let total = 0;
	const li = [];
	const [error, setError] = useState(null);
	const [blogs, setblogs] = useState([]);

	
	
	let apiURl = 'https://www.textmercato.com/cms/api/blogs?filters[tags][slug][$eq]='+slug+'&populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';

	
	
	useEffect(() => {
		axios
		.get(apiURl)
		.then(({ data }) => setblogs(data))
		.catch((error) => setError(error));

        

 


	}, [])

	if (error) {
		// Print errors if any
		return <div>An error occured: {error.message}</div>;
	}
	
	if(blogs.meta){
		let totalPosts = blogs.meta.pagination.total;
		if(totalPosts >= 24){
			total = Math.round(totalPosts / limit);
		}else if(totalPosts > 12 && totalPosts < 24 ){
			total = 2;
		}else{
			total = 1;
		}
		
		for(let i = 1; i <= total; i++){
			li.push(i);
		}
	}
	

	const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    
	
	const category = (categories) => {		
		let CatTitle = [{'title' : '', 'slug' : ''} ]
		if(categories.data){
			categories.data.map((cat, index) => (
				CatTitle['title'] = cat.attributes.Title,
				CatTitle['slug'] = cat.attributes.Slug
			));
		}
		return CatTitle;
	}
	
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric"}
		return new Date(dateString).toLocaleDateString(undefined, options)
	}
	
    let imagealt = 'image'
    
    return (
		<><div>
            <NavbarV2></NavbarV2>

            <section className="page-header" >
				  <div className="container">
				    <h2>Tag: {slug ? (slug.replace('-', ' ').charAt(0).toUpperCase() + slug.replace('-', ' ').substr(1).toLowerCase()) : ''}</h2>
				    <ul className="list-unstyled thm-breadcrumb">
				      <li><Link to="/">Home</Link></li>
                      <li><Link to="/blog">Blog</Link></li>
                      <li><Link >Tag</Link></li>
				      <li><span>{slug ? (slug.replace('-', ' ').charAt(0).toUpperCase() + slug.replace('-', ' ').substr(1).toLowerCase()) : ''}</span></li>
				    </ul>{/* /.list-unstyled thm-breadcrumb */}
				  </div>{/* /.container */}
			</section>


          
            <section className="blog-grid  go-top pt-5 pb-5">
                <div className="container">
                    
                    <div className="row">


                      



                        {blogs.data ? (

                            blogs.data.map((post, index) => (


                                <div className="col-lg-3 col-md-6">
                                    <div className="blog-one__single">
                                        <Link to={"/blog/" + post.attributes.Slug} className="blog-one__image">
                                            <img src={ post.attributes.Featured.data ? ("https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url) : '' } alt={post.attributes.Title} />
                                        </Link>
                                        <div className="blog-one__content">
                                            <h6><Link to={"/category/" + category(post.attributes.categories)['slug']}>{category(post.attributes.categories)['title']}</Link> <span>{formatDate(post.attributes.createdAt)} </span></h6>
                                            <h3><Link to={"/blog/" + post.attributes.Slug}>{post.attributes.Title}</Link></h3>

                                            <p>{post.attributes.Content.substring(0, 150)}...</p>
                                            {/* <div className="blog-one__author">
                    <img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg" }alt={imagealt} />
                    <p className='authorName'></p>
                </div> */}


                                        </div>
                                    </div>
                                </div>








                            ))) : (<div className="loader content_preloader">Loading...</div>)}







                    </div>{/* /.row */}


                    <div className="post-pagination">
					{ blogs.data &&  page > 1 ? (
					<a href={publicUrl+PaginationPath+(parseInt(page) - 1)} ><i className="fa fa-angle-left" />{ }</a>
					) : '' }
					{  li.length > 0 ? (
						
						li.map((parsedData, index) => (						
							<a className={ parsedData == page ? 'active' : '' } href={publicUrl+PaginationPath+ (parsedData) }>{ parsedData }</a>
						))
						
					)	: ""
					} 

					
					{page < total ? (
					<a href={publicUrl+PaginationPath+(parseInt(page) + 1)} ><i className="fa fa-angle-right" /></a>
					) : '' }
			    </div>

                </div>{/* /.container */}
            </section>
          
        
        </div>
        <Calltoaction />
        <Footer_v2></Footer_v2>
        </>
    )
}

export default Tags;
