import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV5 extends Component {
        componentDidMount() {

      const $ = window.$;
      

        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        

    return  <section className="testimonials-one testimonials-one__testimonials-page">
  <div className="container">
    <div className="testimonials-one__top">
      <div className="block-title-two text-center ml-auto mr-auto">
        <h3>What Our Writers Say About Us</h3>
      </div>{/* /.block-title */}
    </div>{/* /.testimonials-one__top */}
   
    <div className="testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme" data-carousel-prev-btn=".testimonials-one__carousel-btn-left" data-carousel-next-btn=".testimonials-one__carousel-btn-right" data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 65, &quot;stagePadding&quot;: 50, &quot;items&quot;: 2, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 20 }, &quot;1199&quot;: { &quot;items&quot;: 2 }, &quot;1200&quot;: { &quot;items&quot;: 2 } }}">
      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>Working with TextMercato has been a fulfilling experience. I have been writing with them as a freelancer for over a year now. The team is quite supportive and cooperative. There's ample scope to learn and hone the skills of content writing.</p>
            <h3>-	Subhasri Mukherjee</h3>
            <span>Siliguri</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>I have been working as a freelance writer with TextMercato for quite some time. All the editors and content managers are very supportive. I have learnt so much more since I joined this esteemed organisation. The timely payment has given me a stable source of income.</p>
            <h3>Barnali Barman </h3>
            <span>Guwahati</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>Excellent! One word to describe my experience as a freelance writer with TextMercato. I started writing as timepass, never knew it would become my profession. All thanks to TextMercato and my friend who motivated me to join. With an amazing team of professionals, TextMercato guides, helps, corrects, and ensures your growth as a writer. Whatever your writing genre or niche is, there is something for everyone. From technical to lifestyle, from travel to recipes, and so much more. You get the flexibility to pick up articles as per your choice, convenience, and comfort level. I have worked with TextMercato since 2018 and I look forward to many more years of learning and growing with them.</p>
            <h3>-	Anchal Shukla </h3>
            <span>Delhi</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>I started my journey with TextMercato in early 2021 and since then there's been no looking back. It gave me financial independence while maintaining my work-home balance. It's the best platform for homemakers to express their creativity and make their free time productive. My experience with TextMercato's admins has always been great as they are always ready to guide and help. Looking forward to many successful years with the TextMercato.</p>
            <h3>-	Neha Gupta </h3>
            <span>Amritsar</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
         
          <div className="testimonials-one__content">
            <p>TextMercato has been a big writing opportunity for me. As a freelancing student, i've experienced how it feels to work with a team, connected with a large network of people, and learned essential skills with every new assignment. </p>
            <h3>-	Shayari Roy </h3>
            <span>Kolkata</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
         
          <div className="testimonials-one__content">
            <p>Writing is my passion, and TextMercato allows me to follow it. As a freelance writer, I enjoy writing with Textmercato, a great place to learn and earn money. It is an excellent freelancing company with fantastic clients, many varied projects, a helpful  professional team, and quick and friendly responses to my queries. Every month, they pay promptly without any surprises. I hope to continue to work with them for many years to come! </p>
            <h3>-	Nithiya R </h3>
            <span>Chennai</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
         
          <div className="testimonials-one__content">
            <p>TextMercato has been delivering quality content for years now. They have the best managers and editors on their platform managingng extensive and exclusive content. Their unmatched passion, professionalism, and respect towards their writers is commendable.  Quick response from editors and efficient administration makes TextMercato writer-friendly and supportive. TextMercato is my favorite place to work because the zeal,dedication, and quality assurance are beyond compare. </p>
            <h3>-	Rupinder Kaur</h3>
            <span>Chandigadh</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      <div className="item">
        <div className="testimonials-one__single">
         
          <div className="testimonials-one__content">
            <p>I have been working with TextMercato as a freelance writer since 2017. In all these years, I have only gained experience and knowledge in my association with them. It has been a pleasure to work with a  friendly team that strives to achieve the values and mission of a content writing firm.  The ethics and culture at TextMercato have only motivated and inspired me to take up more work which helps to fuel my creative pursuits and also builds close professional relationships.</p>
            <h3>Suchitra Subramaniam</h3>
            <span>UK</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}
      
      
      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>I've been a freelance writer with TextMercato since 2016. Their account managers and editorial teams are some of the friendliest people I have worked with so far. The projects are engaging and the teams are always around for support when you hit a bump on the road.
I look forward to working with them for a long time to come.
</p>
            <h3>Gagan Palkar </h3>
            <span>Bangalore</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}

      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>Been working with the TextMercato team since 2019 on various freelance writing projects. Had no issues with my payments and allocations are always smooth. It's that simple. Professional team, 10/10, Keep up the good work :)</p>
            <h3>-	Abhishek Neogi  </h3>
            <span>Bangalore</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}

      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>My experience with TextMercato has been inspirational and helped me grow tremendously as a writer. Everybody is treated with immense respect and given ample time to write well. The writers enjoy a good pay scale and can write with fluency that is enhanced by the supporting editors. The team leaders boost us and are downright respectful.</p>
            <h3>-	Cheryl Rodrigues  </h3>
            <span>Panjim</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}

      <div className="item">
        <div className="testimonials-one__single">
          
          <div className="testimonials-one__content">
            <p>Since joining TextMercato - 2020, the experience has been incredible. Developed my creative talents by writing for a variety of platforms and genres, including e-commerce sites, travel blogs, and the fintech sector. Project managers are kind and happy to assist writers in comprehending the type of work needed for any clientele. <br></br>Delighted to be a member of the TextMercato family.
</p>
            <h3>-	Arpan Ghosh  </h3>
            <span>Kolkata</span>
            <i className="fa fa-quote-right testimonials-one__qoute-icon" />
          </div>{/* /.testimonials-one__content */}
        </div>{/* /.testimonials-one__single */}
      </div>{/* /.item */}


    </div>{/* /.testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme */}

    <div className="testimonials-one__carousel-btn__wrap text-center justify-center ">
    <a href="#" className="testimonials-one__carousel-btn-left"><i className="fa fa-angle-double-left" /></a>
    <a href="#" className="testimonials-one__carousel-btn-right"><i className="fa fa-angle-double-right" /></a>
    </div>{/* /.testimonials-one__carousel-btn__wrap */}


  </div>{/* /.container */}
</section>

        }
}

export default TestimonialV5