import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import PageHeader from './page-header';
import NavbarV2 from '../global-components/navbar-v2';
import Footer_v2 from '../global-components/footer-v2';
import Calltoaction from '../section-components/cta';

const Category = () => {


	let publicUrl = process.env.PUBLIC_URL+'/'
	let limit = 8;
	let offset = 1;
	//let page = 1;
	let PaginationPath = "blog/page/";
	let { slug, page } = useParams();
	
	const location = useLocation();
	if(page === undefined){ page = 1 }


	// var path = window.location.pathname;
	// var pageNumber = parseInt(path.split('/').pop());

	// if(Number.isInteger(pageNumber)){  page = pageNumber; }

	if(page > 1){ offset = (page - 1) * limit; }

	let total = 0;
	const li = [];
	const [error, setError] = useState(null);
	const [blogs, setblogs] = useState([]);
	const [Featured, setFeatured] = useState(null);
    const [faqs, setFaqs] = useState(null);
	
	let apiURl = 'https://www.textmercato.com/cms/api/blogs?populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
	
	if(location.pathname.startsWith('/category/')){
		PaginationPath = "category/"+slug+"/";
		offset = 0;
		if(page > 1){ offset = (page - 1) * limit; }
		apiURl = 'https://www.textmercato.com/cms/api/blogs?filters[categories][slug][$eq]='+slug+'&populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
	}
	
	useEffect(() => {
		axios
		.get(apiURl)
		.then(({ data }) => setblogs(data))
		.catch((error) => setError(error));

        axios
		.get("https://www.textmercato.com/cms/api/faqs?filters[categories][slug][$eq]="+slug+"&populate=category&pagination[start]=1&pagination[limit]="+limit+"&sort=createdAt:DESC")
		.then(({ data }) => setFaqs(data))
		.catch((error) => setError(error));

 


	}, [])

	if (error) {
		// Print errors if any
		return <div>An error occured: {error.message}</div>;
	}
	
	if(blogs.meta){
		let totalPosts = blogs.meta.pagination.total;
		if(totalPosts >= 24){
			total = Math.round(totalPosts / limit);
		}else if(totalPosts > 12 && totalPosts < 24 ){
			total = 2;
		}else{
			total = 1;
		}
		
		for(let i = 1; i <= total; i++){
			li.push(i);
		}
	}
	

	const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    
	
	const category = (categories) => {		
		let CatTitle = [{'title' : '', 'slug' : ''} ]
		if(categories.data){
			categories.data.map((cat, index) => (
				CatTitle['title'] = cat.attributes.Title,
				CatTitle['slug'] = cat.attributes.Slug
			));
		}
		return CatTitle;
	}
	
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric"}
		return new Date(dateString).toLocaleDateString(undefined, options)
	}
	
    let imagealt = 'image'
    
    return (
		<><div>
            <NavbarV2></NavbarV2>

            <section className="page-header" >
				  <div className="container">
				    <h2>Category</h2>
				    <ul className="list-unstyled thm-breadcrumb">
				      <li><Link to="/">Home</Link></li>
                      <li><Link to="/">Resources</Link></li>
                      <li><Link to="/blog">Category</Link></li>
				      <li><span>{slug ? (slug.replace('-', ' ').charAt(0).toUpperCase() + slug.replace('-', ' ').substr(1).toLowerCase()) : ''}</span></li>
				    </ul>{/* /.list-unstyled thm-breadcrumb */}
				  </div>{/* /.container */}
			</section>


          
            <section className="blog-grid  go-top pt-5 pb-5">
                <div className="container">
                <div class="block-title-two text-center "><h3>Blogs </h3></div>
                    
                    <div className="row">


                      



                        {blogs.data ? (

                            blogs.data.map((post, index) => (


                                <div className="col-lg-3 col-md-6">
                                    <div className="blog-one__single">
                                        <Link to={"/blog/" + post.attributes.Slug} className="blog-one__image">
                                            <img src={ post.attributes.Featured.data ? ("https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url) : ''} alt={post.attributes.Title} />
                                        </Link>
                                        <div className="blog-one__content">
                                            <h6><Link to={"/category/" + category(post.attributes.categories)['slug']}>{category(post.attributes.categories)['title']}</Link> <span>{formatDate(post.attributes.createdAt)} </span></h6>
                                            <h3><Link to={"/blog/" + post.attributes.Slug}>{post.attributes.Title}</Link></h3>

                                            <p>{post.attributes.Content.substring(0, 150)}...</p>
                                            {/* <div className="blog-one__author">
                    <img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg" }alt={imagealt} />
                    <p className='authorName'></p>
                </div> */}


                                        </div>
                                    </div>
                                </div>








                            ))) : (<div className="loader content_preloader">Loading...</div>)}



                            <div className="col-lg-12 col-md-12 text-center mt-0 mb-5"><a class="thm-btn banner-two__btn " href="/blog">View all blogs <i class="fa fa-angle-double-right"></i></a></div>




                    </div>{/* /.row */}

                    
                 <hr></hr>
                </div>{/* /.container */}
            </section>
           
            <section className="faq-one  faqListing pt-0 ">
                <div className="container">
                <div class="block-title-two text-center "><h3>FAQs </h3></div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="faq-one__block">
                        
                        <div className="accrodion-grp faqsLink" data-grp-name="faq-one-accrodion">
                        {faqs !== null && typeof faqs === 'object' && Object.keys(faqs).length > 0 ? (
                                    faqs.data.map((post, index) => (
                            <a href={"/faq/"+post.attributes.Slug} className={ index == 0 ? 'accrodion ' : 'accrodion ' }>
                            <div className="accrodion-title">
                                <h4>{ post.attributes.Question }</h4>
                            </div>
                            </a>
                            ))
                            ) : ( <div className="loader content_preloader">Loading...</div>  )
                        }


                            
                        </div>
                        </div>{/* /.faq-one__block */}
                        
                    </div>{/* /.col-lg-8 */}


                    <div className="col-lg-12 col-md-12 text-center mt-5 mb-0"><a class="thm-btn banner-two__btn " href="/faqs">View all FAQs <i class="fa fa-angle-double-right"></i></a></div>
                    
                        
                    
                    </div>{/* /.row */}
                </div>{/* /.container */}
        </section>
        
        </div>
        <Calltoaction />
        <Footer_v2></Footer_v2>
        </>
    )
}

export default Category;
