import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';

import Calltoaction from '../section-components/cta';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestimonialCommon from '../section-components/testimonial-common';
import CsutomerCarousal from '../CsutomerCarousal';

const translation  = () => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div className="homepage">
		<Helmet>
            <title>Premium Translation Service to Localize Your Content</title>
            <meta name="description" content="TextMercato resolves the linguistic barriers for your business to expand your content reach. Explore TextMercato’s professional online translation service and connect with a larger audience." />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3><span> TextMercato Translates - </span>  Professional Content Translation<span> Services in 50+ languages</span></h3>
                    <p>A majority of readers are more comfortable consuming content in their regional language. Take advantage of our multilingual translation services with zero errors in more than 50 recognized languages and connect better with your target audience. 
</p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <img width="85%" src={publicUrl+"assets/services/tranlsation/banner.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>


		<CsutomerCarousal></CsutomerCarousal>






        <section className="service-three white-bg pt-0  go-top">
		  <div className="container">
		    <div className="block-title-two text-center mb-0">
				
		      <h3>Explore Our Translation Services	</h3>   
		    </div>{/* /.block-title-two text-center */}
			

			<div className="row mt-5 services-listing image-as-icon">
				
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/website translation-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						<h3>Website Translation</h3>
						<p>Choose TextMercato vernacular translation services to transcribe the entire website or select web pages. A team of native and professional interpreters can translate your content quickly and accurately.
 </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/blog translator-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Blog Translation</h3>
						<p>TextMercato language translators can pick up niche blog topics and translate them into your preferred vernacular dialect. Get accurate translations for your blog content with us. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/Subtitling-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Subtitles </h3>
						<p>Add subtitles to your videos and make regional language speakers comfortable viewing your content. A team of expert translators will go through each second of your content and create word-for-word translations.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/Chat translation2.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Chat Translation</h3>
						<p>Serve your target audience better by introducing vernacular chat translation to your website. The expertise of TextMercato native translators can help you set up this service.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/App Translation.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>App Translation</h3>
						<p>Translated content is not limited to websites. Fine-tune your app for regional languages to gain more acceptance.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/tranlsation/Technical Translation.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Technical Translation</h3>
						<p>Translation experts will analyze technical content and translate it into your preferred language. Appropriate regional jargon can make the content more natural for your content consumers.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}



				<div className="col-lg-12 col-md-12 text-center mt-5">
					<Link to="/get-started" className="thm-btn banner-two__btn ">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
				</div>


				</div>{/* /.row */}

		  </div>{/* /.container */}
		</section>



		<section className="funfact-one">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-mug-hot" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">1</span>{/* /.counter */} Mn+</h3>
		          <p>Content pieces delivered</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-heart" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">60</span>{/* /.counter */}+</h3>
		          <p>Content types</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-award" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">2500</span>{/* /.counter */}+
		          </h3>
		          <p>Brands we’ve worked with</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-user-alt" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">100000</span>{/* /.counter */}+</h3>
		          <p>Strong creator network</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




		<section id='digital-content' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-7  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Specialized Translation Service to Bring The World to Your Website </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'></p>

					<p className='mt-3 lineheight-normal'>Statistically, 53% of people are more likely to consume content in their own language. The expertise of TextMercato translators is perfect for translating your print and digital content.</p>
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Linguistic Proficiency</h3><p>TextMercato’s pool of multilingual translators have proficiency in over 50 recognized languages. You can trust us to translate your content to reach your regional clientele. </p></div></div>

						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Content That Won’t Get Lost in Translation</h3><p>Rest assured that the meaning and effectiveness of the translated content will be the same as the original. 
 </p></div></div>
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Zero-Error AI-Assisted Content</h3><p>AI-generated translations often have errors that a native speaker can identify immediately. Our translation service adds a layer of expert edits to ensure that the translated content will have little to no errors, thus, strengthening your connection with clients.  </p></div></div>
						


					</div>{/* /.about-four__content */}
                    
				<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-5 my-auto">
		        <img src={publicUrl+"assets/services/tranlsation/translation.png"} className="about-four__moc  " alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<TestimonialCommon></TestimonialCommon>



		<section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    
        




          


        <section className="faq-two">
        <div className="container">
          <div className="row">
            
           
            
            
            <div className="col-lg-5">
              <div className="faq-two__content">
                <div className="block-title-two text-left">
                  
                  <h3>FAQs</h3>
                </div>{/* /.block-title-two */}

                <ul className='faq-questions-list'>
                  <li><a href='#faq-1'>Why should I translate my content?</a></li>
                  <li><a href='#faq-2'>Will TextMercato translators rework delivered content?</a></li>
                  <li><a href='#faq-3'>Why choose TextMercato when there are AI translator applications?</a></li>
                  <li><a href='#faq-4'>oes TextMercato assure a smooth transaction?</a></li>
                  
                </ul>

                


                
              </div>{/* /.faq-two__content */}
            </div>{/* /.col-lg-6 */}


            <div className="col-lg-7 d-flex justify-content-center">
              
            <div className="inner">
                <div id='faq-1' className='faq-answer-block active'>
                  <h2>Why should I translate my content?</h2>
                  <p> Mother tongue is very close to the readers’ hearts, and there is a level of trust created between people who speak the same language. TextMercato offers to establish a comfortable atmosphere for your business and your target audience with regionally translated content. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                  
                </div>
                <div id='faq-2' className='faq-answer-block '>
                  <h2>Will TextMercato translators rework delivered content?</h2>
                  <p>TextMercato’s professional interpreters will rework any content that does not meet the standards and guidelines of the client. Choose TextMercato for assured quality content.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-3' className='faq-answer-block '>
                  <h2>Why choose TextMercato when there are AI translator applications?</h2>
                  <p>AI translator applications can only give you accuracy to a certain level. With TextMercato’s translation services, we add a layer of native edits to validate the translation and deliver it according to your requirements.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-4' className='faq-answer-block '>
                  <h2>oes TextMercato assure a smooth transaction?</h2>
                  <p>TextMercato assigns you a dedicated account manager with industry expertise for your project. Our team ensures that each client gets top-quality content and translation services. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
               
                
            </div>{/* /.inner */}
                
            
            </div>{/* /.col-lg-6 */}




            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>
        <Calltoaction />
        <Footer />
    </div>
}

export default translation 

