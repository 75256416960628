import React, { Component } from 'react';

class CsutomerCarousal extends Component {
        componentDidMount() {
  
    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        

    return  <section className="brand-one brand-one__home-two">
	<div className="container">
		<div className="block-title-two text-center">
		<h3 className="font-30">2500+ Businesses trust us with their content</h3>
		</div>{/* /.block-title-two */}
		<div className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel" data-options="{&quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 5000, &quot;items&quot;: 6, &quot;margin&quot;: 50, &quot;smartSpeed&quot;: 700, &quot;nav&quot;: true, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 2, &quot;margin&quot;: 15 },&quot;575&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 },&quot;767&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 }, &quot;991&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 30 }, &quot;1199&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 75 }, &quot;1200&quot;: { &quot;items&quot;: 6 } }}">
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-1.png"} alt="" />
		</div>{/* /.item */}
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-2.png"} alt="" />
		</div>{/* /.item */}
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-3.png"} alt="" />
		</div>{/* /.item */}
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-4.png"} alt="" />
		</div>{/* /.item */}
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-5.png"} alt="" />
		</div>{/* /.item */}
		<div className="item">
			<img src={publicUrl+"assets/images/brand/brand-1-6.png"} alt="" />
		</div>{/* /.item */}
		
		</div>{/* /.brand-one__carousel owl-carousel owl-theme thm__owl-carousel */}
	</div>{/* /.container */}
</section>

        }
}

export default CsutomerCarousal


