import React, { useEffect } from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const PostProject = () => {

    const [nextPage, setnextPage] = useState('/client-order-content-requirements');

    let publicUrl = process.env.PUBLIC_URL+'/'
    const type = localStorage.getItem('type');
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const id_token = localStorage.getItem('id_token');
    const get_project_id = localStorage.getItem('project_id');
    const history = useHistory();
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [master, setMaster] = useState(null);
    const [errors, setErrors] = useState(null);
    const [project_id, setProject_id] = useState(get_project_id);

    

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);
    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);

    const handleOnClick = e => {
        e.preventDefault();
            setLoading(true);
            if(e.target.classList.contains('Inputerrors')){
                setLoading(false);
            }else{
                history.push(nextPage);
                
            }
        }

        const submitData = token => {
            
            if(project_id !== null){
                fetch('http://devapi.textmercato.com/order/api/update-order-projectname/'+project_id, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer '+id_token
                    },
                    body: JSON.stringify({
                        "order_type_id": type,
                        "project_name": name,
                        "industry_id": industry
                    })
                    }).then(res => {
                
                        if(res.status == 200){
                            history.push(nextPage);
                        }else{
                            let data = res.json();
                            setErrors(data.message);
                        }
                        
                    
                    }).then(res => {
                        setLoading(false);
                        history.push(nextPage);                             
                    });
            }else{
                fetch('http://devapi.textmercato.com/order/api/create-order-projectname', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer '+id_token
                    },
                    body: JSON.stringify({
                        "order_type_id": type,
                        "project_name": name,
                        "industry_id": industry,
                        "user_id": userId
                    })
                    }).then(res => {
                
                        if(res.status == 200){
                            return res.json();
                        }else{
                            let data = res.json();
                            setErrors(data.message);
                        }
                        
                    
                    }).then(res => {
                        setLoading(false);
                        if(res.data.projectID){
                            localStorage.setItem('project_id', res.data.projectID);
                            setProject_id(res.data.projectID);
                            history.push("/client-order-content-requirements");
                        }                               
                    });
            }            
          }

        const MasterData = token => {
            fetch('http://devapi.textmercato.com/order/api/get-masterData', {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+id_token
                }
            }).then(res => res.json()).then(res => {            
                if(res.data){
                    setMaster(res.data[0]);
                    localStorage.setItem('masterData', JSON.stringify(res.data[0]))

                }
            });
        }

        const getProjectInfo = projectID => {
            fetch('http://devapi.textmercato.com/order/api/get-order-projectname/'+projectID, {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+id_token
                }
            }).then(res => res.json()).then(res => {            
                if(res.data){
                    console.log(res.data);
                    if(res.data.project_name !== null){ setName(res.data.project_name) }
                    if(res.data.industry_id !== null){ setIndustry(res.data.industry_id) }
                }
            });
        }
    
        useEffect(() => {
            if (type === 'translation'){
                setnextPage('/client-order-translation-requirements');
            }else if (type === 'graphics'){
                setnextPage('/client-order-graphics-requirements');
            }


            getProjectInfo(project_id);
            let getMaster = localStorage.getItem('masterData');
            
            if(getMaster){
                
                setMaster(JSON.parse(getMaster));
                console.log(JSON.parse(getMaster));
                if(master !== null){
                    MasterData(token);
                }
            }else{
                MasterData(token);
            }
        }, []);
        

    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                      
                        <div className="main active">
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'>Enter Project Name and Industry</h3>
                            </div>
                            <div className="input-text">
                                <div className="input-div">
                                    <label>Enter Project name *</label>
                                    <input onChange={(e) => setName(e.target.value)} value={ name } type="text" data-message="Enter Project name" name='project_name' required require />
                                </div>
                                
                            </div>
                            
                            <div className="input-text">
                                <div className="input-div">
                                    <label>Choose Industry * </label>
                                    <select name='industry' required require className=''>
                                        <option value=''>Select </option>
                                        <option value="Data and Deep-Technology">Data and Deep-Technology</option>
                                        <option value="IT, Software, Infrastructure and Application Development">IT, Software, Infrastructure and Application Development</option>
                                        <option value="Corporate and Entrepreneuship">Corporate and Entrepreneuship</option>
                                        <option value="Banking, Finance and Insurance (BFSI)">Banking, Finance and Insurance (BFSI)</option>
                                        <option value="Government and Politics">Government and Politics</option>
                                        <option value="Art and Design">Art and Design</option>
                                        <option value="Media & Entertainment">Media & Entertainment</option>
                                        <option value="Lifestyle">Lifestyle</option>
                                        <option value="Real Estate & Construction">Real Estate & Construction</option>
                                    </select>
                                </div>
                            </div>

                           

 
                            { errors !== null ? (
                                <p>{ errors }</p>
                            ) : '' }
                           


                            <div className="buttons button_space">
                                <Link to="/client-order-all" className="backBtn thm-btn">Back</Link>
                                <Link className="nextBtn thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Continue'}</Link>
                                
                            </div>
                        </div>
 

                    
                        
                      
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
 

    </div>
}

export default PostProject

