import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Explore TextMercato’s Expert White Paper Writing Services</title>
            <meta name="High-quality white paper writing service establishes credibility. Choose TextMercato white paper design service for industry-leading content." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1><span>A Comprehensive Solution to </span> White Paper Content </h1>
                  <p>Whitepaper writings are persuasive long-format content pieces that build trust and impart knowledge about the brand. A white paper development company like TextMercato performs intensive research to produce high-quality content. Choose TextMercato white paper writing services and acquire the expertise needed for white paper content.</p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/whitepapper.png"} alt="Banner" />
                  </div>


            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Delivery.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>What to Expect From TextMercato White Paper Writing Service?  </h3>
		          </div>{/* /.block-title-two text-left */}
		          
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>A Dedicated Team  </h3><p>Each project is precious to us. We assign expert writers to complete each project with a unique approach. Furthermore, an Account Manager will always stay connected to assist you with further requirements. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>We Take Turn Around Time Seriously  </h3><p>TextMercato writers know that time is valuable for our clients. Marketing strategies depend on content; thus, our writers and editors quickly produce clean content and deliver it within the agreed TAT. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Content That Suits Your Brand  </h3><p>Team TextMercato remains vigilant about the tone of the content. We deliver content that will help you build authority in your subject matter.  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Detailed Quality Content  </h3><p>White papers can be tedious. However, TextMercato’s professional white paper writers can create beautiful, engaging content with in-depth research on the topic. Choose TextMercato white paper writing services to gain your customer’s trust and build authority</p></div></div>

            

					</div>{/* /.about-four__content */}
                    
                    
                  {/* <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link> */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='performance' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>TextMercato Offers Top White Paper Writing Services  </h3>
		          </div>{/* /.block-title-two text-left */}
		         
					<p className='mt-1 mb-3 lineheight-normal'>TextMercato’s competent team of writers can quickly turn a small brief into a magnificent blog. Our writers can comfortably take up any type of write-up, be it a blog post or writing an article. Here are a few types of blog posts we can work on:</p>
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Thought Leadership White Paper  </h3><p>A thought leadership white paper is about sharing an authoritative opinion on a debatable topic in your industry. Textmercato has writers who are nothing less than thought leaders who can write with an authoritative tone. Choose TextMercato white paper writing services to make your brand look more credible. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Educational White Paper  </h3><p>Educational white paper imparts detailed knowledge about a topic to target audiences. Our writers know how to research information and write informative content.</p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Market Research White Paper  </h3><p>Market research white paper shares information about an industry or brand with accurate data and explanation. A white paper development company like TextMercato can research and decipher the data into a simple format for your readers.</p></div></div>
                            <div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Technical White Paper   </h3><p>Technical white paper supports sales by informing the buyers about a specific product or service. TextMercato’s subject matter experts can check all the technicalities and present content based on the research.</p></div></div>
						

					</div>{/* /.about-four__content */}
                    
  
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/White-Paper-Writing-Service.png"} className="about-four__moc  " alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>Are white papers necessary for my business?	</a></li>
                    <li><a href='#faq-2'>Will I get plagiarism-free content?</a></li>
                    <li><a href='#faq-3'>Why should I choose TextMercato blog post writing service?</a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>Are white papers necessary for my business?	</h2>
                    <p> Yes. High-quality content with proper research can help you make a mark in your industry.  Furthermore, impressed audiences can become prospective clients.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Will TextMercato provide original content?</h2>
                    <p>TextMercato writers and editors are trained to write original and unique content. Furthermore, we run each piece of content through recognized plagiarism checkers before delivery.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                    <h2>Is TextMercato a good white paper writing service in India?	</h2>
                    <p>White paper editing and writing require subject matter experts who can do constant research. A white paper development company like TextMecato has a network of over 10,000 writers. We have several experts to research and organize content with the tone you want. </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

