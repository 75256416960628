import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>TextMercato Copywriting Services - A Place For Brands to Excel</title>
            <meta name="Looking for a professional copywriting service? Let us take the helm. TextMercato’s expert writers offer the best SEO copywriting services which your brand deserves." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1><span>Professional </span> Copywriting Services <span> Crafted to Improve Traffic and Search Engine Rank </span></h1>
                  <p>The right keywords can make considerable differences in your company’s marketing strategy. Take advantage of our professional website copywriting services to connect better with your target audience and generate more traffic, sales, and leads. </p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/copywriting.png"} alt="Banner" />
                  </div>


            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Professional-Copywriting-Service.png"} className="about-four__moc  " alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Efficient and Professional Copywriting Service  </h3>
		          </div>{/* /.block-title-two text-left */}
		          
                  <p className='mt-1 mb-3 lineheight-normal'>TextMercato offers one of the best copywriting services. Choose TextMercato for efficient and unique content copywriting services that will fulfill your marketing requirements. </p>

                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>100 % Plagiarism Free </h3><p>Our content is unique and SEO-driven to improve your webpage ranking. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Ask for Revision, Anytime </h3><p>We take responsibility for revising until the content meets your approved guidelines.  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Stay Informed About Project Progress </h3><p>You can always check and track the progress of your project by simply logging in to the dashboard. You can also seek status from your dedicated Account Manager for additional requirements.  </p></div></div>
						 

            

					</div>{/* /.about-four__content */}
                    
                    
                  {/* <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link> */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section className="service-three    go-top">
		  <div className="container">

		  <div className="row ">
		  <div className="col-md-8 offset-2 ">
		    <div className="block-title-two text-center mb-0">
				
		      <h3>Explore Our Copywriting Services </h3>
		    </div>{/* /.block-title-two text-center */}
			 
		</div>
		</div>

		    <div className="row mt-5">
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-pen-clip" />
		          <h3><a className='scroll-to-target' data-target="#" href='#'  >Social Media</a></h3>
		          <p>TextMercato offers advanced copywriting services to boost your social media posts across various industries. We work hand-in-hand with experts to deliver a targeted message. </p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-invoice" />
		          <h3><a   className='scroll-to-target' data-target="#"  href='#'>Blogging Services</a></h3>
		          <p>TextMercato consistently delivers write-ups of relevant and high-quality content. Team TextMercato extensively researches each topic to deliver in-depth information on the write-up. Our keyword research includes long-tailed keyword variations to strike the right balance between relevancy, keyword difficulty, and search volume.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-folder-tree" />
		          <h3><a  className='scroll-to-target' data-target="#"  href='#'>Web Page Copywriting</a></h3>
		          <p>TextMercato’s content copywriting services offer extensive writing services for service pages, landing pages, web pages, etc. TextMercato experts use the right SEO keywords to optimize your web pages for industry phrases and terms. Our professional website copywriting services include service-specific SEO landing pages to direct traffic to your website from local searches. </p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}


              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-pen" />
		          <h3><a  className='scroll-to-target' data-target="#"  href='#'>Ad Copies and Sales Pages</a></h3>
		          <p>Most marketers utilize emails as a marketing channel to reach multiple email users worldwide. TextMercato knows how to create persuasive conversion-driving email sequences and follow the appropriate call-to-action. </p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-globe" />
		          <h3><a  className='scroll-to-target' data-target="#"  href='#'>Drip Email Campaigns</a></h3>
		          <p>Not only can we help create content, but also help you publish. (Coming soon - WordPress Integration)</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-chart-line" />
		          <h3><a  className='scroll-to-target' data-target="#"  href='#'>Copywriting for Marketing Content</a></h3>
		          <p>TextMercato's professional copywriting services include high-value ebooks and whitepapers with client testimonials. Choose TextMercato for attention-grabbing expertly written PPTs, press releases, and more.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}

		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>Why choose TextMercato as copywriting service provider? </a></li>
                    <li><a href='#faq-2'>Does TextMercato assure a smooth transaction? </a></li>
                    <li><a href='#faq-3'>What are some of the copywriting services offered by TextMercato? </a></li>
                    <li><a href='#faq-4'>Do TextMercato have qualified copywriters? </a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>Why choose TextMercato as copywriting service provider? </h2>
                    <p> TextMercato has a repository of over 10,000 approved expert writers. Our writers can quickly pick topics and complete them within a reasonable TAT.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Does TextMercato assure a smooth transaction? </h2>
                    <p>TextMercato assigns you a dedicated account manager with industry expertise for your project. Our team ensures that each client gets top-quality copywriting services. </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                        <h2>What are some of the copywriting services offered by TextMercato? </h2>
                        <p>With TextMercato, you can choose content copywriting services, like B2B copywriting, brand copywriting, SEO copywriting, email copywriting, social media copywriting, and more. </p>
                        <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>

                    <div id='faq-4' className='faq-answer-block '>
                        <h2>Do TextMercato have qualified copywriters? </h2>
                        <p>TextMercato chooses professional and approved writers with core skills like in-depth research, awareness of SEO practices, and adaptability. Capable writers and editors will study your target audience and web the content to create compelling  </p>
                        <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

