import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Expert SEO Content Writing Services to Achieve Your Ranking Goals</title>
            <meta name="TextMercato’s SEO-friendly content writing services systematically optimize website content. Choose TextMercato services and rank higher on top search engines" />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1>Quality SEO Content <span> That Makes Search Engine Rankings Easy </span></h1>
                  <p>Well-written SEO content can take your website to new heights. TextMercato strives to formulate and implement a successful SEO content strategy for each client. Choose TextMercato to write and edit your SEO content and get the desired search results. </p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/seo-articles.png"} alt="Banner" />
                  </div>
            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Efficient-and-Professional-Copywriting.png"} className="about-four__moc  " alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Efficient and Professional Copywriting Service  </h3>
		          </div>{/* /.block-title-two text-left */}
		          
                  <p className='mt-1 mb-3 lineheight-normal'>TextMercato offers one of the best copywriting services. Choose TextMercato for efficient and unique content copywriting services that will fulfill your marketing requirements. </p>

                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Relevant Content  </h3><p>Writing content with zero fluff is the mantra of our writers. Get fresh SEO content on any given topic with content that is relevant to your brand and business. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>SEO Specialists  </h3><p>An in-house team of SEO experts can find accurate keywords for any given industry. Our writers and editors are well-versed in how to write SEO content with the right keywords.</p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Dedicated Account Manager  </h3><p>We will assign you a dedicated account manager who ensures there is little to no space for mistakes. The managers forward the brief and expectations to the writers to ensure smooth content delivery.</p></div></div>

                        <div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Reasonable TAT   </h3><p>Time is money. Team TextMercato’s professionalism regarding established TATs is untarnishable. Select TextMercato SEO content writing services to get quality SEO articles within a reasonable time.</p></div></div>

                        <div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Quality and Quantity    </h3><p>TextMercato verified SEO writers are trained for writing SEO content. Our writers can easily pick large quantities of SEO articles and deliver them error-free.</p></div></div>
						 

            

					</div>{/* /.about-four__content */}
                    
                    
                  {/* <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link> */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


	



        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>Why should I choose an SEO content writing service?</a></li>
                    <li><a href='#faq-2'>Can TextMercato take up large projects?</a></li>
                    <li><a href='#faq-3'>Why should I choose SEO when there is Google Ads?	</a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>Why should I choose an SEO content writing service? </h2>
                    <p> SEO is an important element of website content. It determines your presence on the internet and increases engagement. Seasoned SEO writers can optimize content to match your marketing objectives. TextMercato’s knowledgeable SEO writers can write quality content that will succeed in search engine rankings.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Can TextMercato take up large projects?</h2>
                    <p>TextMercato team can create unique SEO content for your business. They are well-equipped with all the required knowledge to implement keywords on a given topic. You can check SEO content writing samples done by team TextMercato and check testimonials to learn what our partners have to say.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                        <h2>Why should I choose SEO when there is Google Ads?	 </h2>
                        <p>You will get the desired search results as long as you’re paying for Google Ads. Once you stop paying, your visibility on the internet will reduce instantly. However, SEO is not the same. When your content is SEO-rich, your visibility will remain intact. Choose TextMercato SEO content writing services to gain an edge over your competitors on search engine results. </p>
                        <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>

                    
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

