import axios from 'axios';
import React, { Component, useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import HubspotForm from 'react-hubspot-form'
import { useHistory } from 'react-router-dom';

const Sidebar = () => {

	const [inputValue, setInputValue] = useState('')
	const handleChange = (event) => {
		setInputValue(event.target.value);
	}
	const history = useHistory();
	const routeChange = (e) =>{ 
		e.preventDefault();
		let path = '/search/'+inputValue;
		console.log(path); 
		history.push(path);
	}

   let anchor = '#'
   let imagealt = 'image'
   let publicUrl = process.env.PUBLIC_URL+'/'

   const formatDate = (dateString) => {
	const options = { year: "numeric", month: "long", day: "numeric"}
	return new Date(dateString).toLocaleDateString(undefined, options)
}
	
   const [error, setError] = useState(null);
   const [categories, setCategories] = useState([]);
   const [recentPosts, setRecent] = useState([]);
   const [tags, setTags] = useState([]);
   
   useEffect(() => {
		axios
		.get('https://www.textmercato.com/cms/api/categories')
		.then((data) => setCategories(data))
		.catch((error) => setError(error));

		axios
		.get('https://www.textmercato.com/cms/api/blogs?populate=Featured,categories&pagination[start]=1&pagination[limit]=3&sort=createdAt:DESC')
		.then((data) => setRecent(data))
		.catch((error) => setError(error));

		axios
		.get('https://www.textmercato.com/cms/api/tags')
		.then((data) => setTags(data))
		.catch((error) => setError(error));

	}, []);


    
	return (
      <div className="col-lg-4  go-top">
		        <div className="sidebar">
		           <div className="sidebar__single sidebar__search">
		            <form action="/search" className="sidebar__search-form">
		              <input type="text" onChange={handleChange} placeholder="Search Keywords" />
		              <button onClick={routeChange} type="submit"><i className="far fa-search" /></button>
		            </form>
		          </div>
		          
				  <div className="sidebar__single sidebar__category">
		            <h3 className="sidebar__title">Category
		              <span />
		            </h3>{/* /.sidebar__title */}
		            <ul className="sidebar__category-list list-unstyled">
					{typeof categories === 'object' && Object.keys(categories).length > 0 ? (                    
						categories.data.data.map((category, index) => (
						<li><a href={"/category/"+category.attributes.Slug}>{ category.attributes.Title }</a></li>
						))
						) : ( <div className="loader content_preloader">Loading...</div>  )  
					}

		            </ul>{/* /.sidebar__category-list */}
		          </div>{/* /.sidebar__single */}

		          <div className="sidebar__single sidebar__post">
		            <h3 className="sidebar__title">Recent Blog
		              <span />
		            </h3>{/* /.sidebar__title */}
		            
				{typeof recentPosts === 'object' && Object.keys(recentPosts).length > 0 ? (
					recentPosts.data.data.map((post, index) => (

						<div className="sidebar__post-single">
						<div className="sidebar__post-image">
						<img src={"https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url} alt={post.attributes.Featured.data.attributes.name} />
						</div>
						<div className="sidebar__post-content">
							<h3><a href={"/blog/"+post.attributes.Slug}>{post.attributes.Title}</a></h3>
							<span>{ formatDate(post.attributes.createdAt) }</span>
						</div>
						</div>
					))
					) : ( <div className="loader content_preloader">Loading...</div>  )  
				}

		            
		          </div>
		          
				  <div className="sidebar__single sidebar__newsletter">
		            <h3 className="sidebar__title">Newsletters
		              <span />
		            </h3>
		            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor ince didunt ut 
		              labore dolore magne.</p>*/}
		            
					  <HubspotForm
						portalId='22122965'
						formId='99b11386-d20e-4fea-a694-573cbf5b0fe2'
						onSubmit={() => console.log('Submit!')}
						onReady={(form) => console.log('Form ready!')}
						loading={<div>Loading...</div>}
						/>

		          </div>
				 
		          <div className="sidebar__single sidebar__tags">
		            <h3 className="sidebar__title">Tags
		              <span />
		            </h3>
		            <div className="sidebar__tags-list">
					{typeof tags === 'object' && Object.keys(tags).length > 0 ? (
					tags.data.data.map((tag, index) => (
						<Link to={"/blog/tag/" + tag.attributes.Slug  }>{ tag.attributes.Name }</Link>
					))) : ''
					}
		             

		            </div>
		          </div>
		          
		        </div>
		      </div>
    )
}

export default Sidebar;
