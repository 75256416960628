import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { setLoggedIn } from '../libs/auth/authSlice';
import { useHistory } from 'react-router-dom';


const LoginPage = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    const SITE_KEY = "6Ld-9vYkAAAAAMLWfnn9Qq_sFp29E5gpUThbbSs2";
    const SECRECT_KEY = "6Ld-9vYkAAAAAMYfpqIf0CeR2ppRcdfwkTecjyGn";
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    const history = useHistory();

    if(user.token !== null){
        history.push("/");
    }
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
      
            if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
            if (callback) callback();
            };
            document.body.appendChild(script);
            }
      
        if (isScriptExist && callback) callback();
        }
              // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/login.js";
    document.body.appendChild(minscript);

    

    const handleOnClick = e => {
        e.preventDefault();
            setLoading(true);
            history.push("/client-order-all");
            
            // if(e.target.classList.contains('Inputerrors')){
            //     setLoading(false);
            // }else{
            //     window.grecaptcha.ready(() => {
            //     window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            //         submitData(token);
            //     });
            //     });
            
            // }
        
        }

        const submitData = token => {
            
            
            
            // call a backend API to verify reCAPTCHA response
            fetch('http://mvp-alb-552307351.ap-south-1.elb.amazonaws.com/session/api/login', {
              method: 'POST',
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                "email": email,
                "password": password,
                "captcha": token
              })
            }).then(res => res.json()).then(res => {
              setLoading(false);
              if(res.error){
                  setMessage(res.error.error_description);
              }else 
              if(res.data.userData.access_token){
                let userInformation = res.data.userInformation[0];
                
                dispatch(setLoggedIn({name:userInformation.fullName, email:userInformation.email, role:userInformation.role_name, token: res.data.userData.access_token, id_token: res.data.userData.id_token, user_id: userInformation.id} ))
                setMessage(res.message);
                window.location.reload(false);
              }
               
              
            });
          }
          

    return <div>

        <Helmet>
            <title>Login</title>
            <meta name="" />
        </Helmet>

        <Navbar ></Navbar>
        
        <section className='login-section snippet-body'>
            <div className="container">
                <div className="card">
                    <div className="form">

                    <div className="left-side">
                       
                        
                       <img width='100%' src={publicUrl+"assets/Text-Mercato-Sign-up.png"} alt={ imagealt } />
   
                       <div className={"testimonials-two__carousel  owl-carousel thm__owl-carousel owl-theme"} data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 1, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 1 }, &quot;1200&quot;: { &quot;items&quot;: 1 } }}">
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                       <img src={publicUrl+"assets/logos/urban.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                       <h3>Shikha  </h3>
                       <span>Urban Ladder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Great Team Support"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
   
   
   
   
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/frontfold.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                               <h3>Nandan S Kedlaya  </h3>
                       <span>Founder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"High Quality Content"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
                       
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/nilkamal.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                           <h3>Siddharth Pamoor </h3>
                       <span>Sr.SEO Executive</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Highly professional"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
                               
                               
   
                           </div>
                           
                           
   
                           
                    </div>
                    
                        <div className="right-side darg-bg ">
                            <div className="main active">
                                
                                <div className="text mb-4 mt-0">
                                    <h3 className='color-blue'>Welcome Back </h3>
                                    <Link onClick={() => {window.location.href="/client-register"} } className='simple-link border-left-grey sub-heading-link' to="/client-register"><span className='text-black'>New to Text Mercato? </span> Sign-up</Link> 
                                </div>
                                <div className="input-text">
                                <div className="input-div">
                                        <label>E-mail Address</label>
                                        <input type="email" onChange={(e) => setEmail(e.target.value)} data-message="Enter a valid email" name='email' id='email' required require />
                                        {/* <span>E-mail Address</span> */}
                                    </div>
                                </div>
                                <div className="input-text">
                                
                                    <div className="input-div">
                                        <label>Password</label>
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} data-message="Enter a valid password" name='password' required  />
                                  <a className='showpassword' href='javascript:void(0)'><i className='fa fa-eye'></i></a>
                                        {/* <span>Password</span> */}
                                    </div>
                                </div>

                                <div className="buttons ">

                                    { message !== null ? ( <p className='text-danger'> { message } </p> ) : '' }
                                    <button onClick={handleOnClick} disabled={loading} className="submit_button thm-btn">{loading ? 'Verifying...' : 'Sign In'}</button>
                                  
                                    <Link className='simple-link inline-links' onClick={() => {window.location.href="/password-reset-request"} } to="/password-reset-request">Forgot password?</Link>
                                    <Link className='simple-link inline-links' onClick={() => {window.location.href="/login-with-otp"} } to="/login-with-otp">Sign-in with OTP</Link>

                                    <button className="back_button d-none">Back</button>
                                    <button className="next_button d-none">Next</button>

                                </div>
                            </div>
                            <div className="main">
                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                </svg>
                                
                                <div className="text congrats">
                                    <h2>Congratulations!</h2>
                                    <p>Thanks your information have been submitted successfully.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
}

export default LoginPage

