import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';

import Calltoaction from '../section-components/cta';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestimonialCommon from '../section-components/testimonial-common';
import CsutomerCarousal from '../CsutomerCarousal';

const text_content  = () => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div className="homepage">
		<Helmet>
            <title>On-Demand Content Writing Services to Meet Your Business Needs</title>
            <meta name="description" content="A professional content writing service can create compelling, high-quality content. Choose TextMercato for any content service like ebooks, blogs, etc." />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3>Largest Network<span> of Content Experts</span></h3>
                    <p>Our team of expert writers can craft engaging content for your business needs. Over 10,000 certified writers with various backgrounds, skills, and article genres are at your service. </p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <img width="85%" src={publicUrl+"assets/text-content.svg"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>


		<CsutomerCarousal></CsutomerCarousal>





        <section className="service-three white-bg pt-0  go-top">
		  <div className="container">
		    <div className="block-title-two text-center mb-0">
				
		      <h3>Select A Content Writing Service to Begin With	</h3>   
		    </div>{/* /.block-title-two text-center */}
			

			<div className="row mt-5 services-listing image-as-icon">
				
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/website-blog.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						<h3>Website Blogs & Articles</h3>
						<p>Publish interesting blogs and articles that offer your audience insightful guidance with more than 1500+ subject matter experts. Exceptional blogging service offers natural and high-quality clicks to your website. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/website-content.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Website Content</h3>
						<p>Onboard TextMercato as your website content provider. The expert team has experience in researching and writing high-quality content. Your website content will stand out from the competition with our pool of 10000+ verified writers. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/product-descriptions.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Product Descriptions</h3>
						<p>Create SEO-friendly product descriptions. The best SEO guidelines are followed when writing SEO product descriptions.
Testimonials & Reviews: Whether you need to fine-tune testimonials or write authentic reviews, TextMercato has you covered with industry experts. One platform with endless possibilities.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/buying-Selling-Guide.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Newsletters</h3>
						<p>Establish a strong communication channel with TextMercato. Make your brand newsletters interactive and information rich, and take your client connect to the next level.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/copywriting.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Copywriting</h3>
						<p>The top copywriters in our network produce material that connects with readers, and more than 1000 companies entrust us with their content. Take advantage of TextMercato expert writers for your copywriting needs. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/ebooks.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Ebooks</h3>
						<p>Create ebooks and playbooks to offer deeper insights to your target audience. It could be one huge playbook or a series of ebooks that can be published over a period of time.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}


				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/whitepaper.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Whitepapers</h3>
						<p>TextMercato puts forth all the effort required to create solid, industry-leading whitepapers as a seasoned white paper writing service provider. Discover our professional white paper writing services, created for companies doing business in India and beyond.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/text-content/social-media-content.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Social Media Content</h3>
						<p>Establish your social media presence with content your audience adores. Our social media content services add life to your brand identity with content that resonates across all of your marketplaces. Increase brand awareness, generate leads, and close sales with our social media experts.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				


				<div className="col-lg-12 col-md-12 text-center mt-5">
					<Link to="/get-started" className="thm-btn banner-two__btn ">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
				</div>


				</div>{/* /.row */}

		  </div>{/* /.container */}
		</section>



		<section className="funfact-one">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-mug-hot" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">1</span>{/* /.counter */} Mn+</h3>
		          <p>Content pieces delivered</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-heart" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">60</span>{/* /.counter */}+</h3>
		          <p>Content types</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-award" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">2500</span>{/* /.counter */}+
		          </h3>
		          <p>Brands we’ve worked with</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-user-alt" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">100000</span>{/* /.counter */}+</h3>
		          <p>Strong creator network</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




		<section id='digital-content' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Why TextMercato Is the Ultimate Choice </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'></p>

					<p className='mt-3 lineheight-normal'>TextMercato's content writers understand that the information on your website may make or break a sale for you. Our writers have solid research abilities and a firm grasp of language for any type of content writing. Choose TextMercato for your business to get quality content writing with compelling material.</p>
					<br></br>
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Create a close relationship with your audience</h3></div></div>

						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>100% original content</h3></div></div>
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Generate expected traffic</h3></div></div>
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Make a successful online brand identity	</h3></div></div>
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Share accurate information	</h3></div></div>


					</div>{/* /.about-four__content */}
                    
				<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/services/text-content/why.svg"} className="about-four__moc  " alt="Why TextMercato Is the Ultimate Choice" />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<TestimonialCommon></TestimonialCommon>



		<section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    
        




          


        <section className="faq-two">
        <div className="container">
          <div className="row">
            
           
            
            
            <div className="col-lg-5">
              <div className="faq-two__content">
                <div className="block-title-two text-left">
                  
                  <h3>FAQs</h3>
                </div>{/* /.block-title-two */}

                <ul className='faq-questions-list'>
                  <li><a href='#faq-1'>What do I get if I choose writing services for my corporate websites?</a></li>
                  <li><a href='#faq-2'>Why choose TextMercato as my blog writing service partner?</a></li>
                  <li><a href='#faq-3'>Does TextMercato offer a free trial?</a></li>
                  
                </ul>

                


                
              </div>{/* /.faq-two__content */}
            </div>{/* /.col-lg-6 */}


            <div className="col-lg-7 d-flex justify-content-center">
              
            <div className="inner">
                <div id='faq-1' className='faq-answer-block active'>
                  <h2>What do I get if I choose writing services for my corporate websites?</h2>
                  <p> We provide you with well-researched and carefully-crafted website content and a well-written piece that is SEO-friendly and meets your expectations.
</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                  
                </div>
                <div id='faq-2' className='faq-answer-block '>
                  <h2>Why choose TextMercato as my blog writing service partner?</h2>
                  <p>Our team of skilled blog writers can easily create and manage blogs. Expertly crafted, high-quality information that is current, fresh, innovative, and well-researched.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-3' className='faq-answer-block '>
                  <h2>Does TextMercato offer a free trial?</h2>
                  <p>You can download the existing samples from our sample repository or get in touch with our representative to share your expectations. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
               
                
            </div>{/* /.inner */}
                
            
            </div>{/* /.col-lg-6 */}




            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>
        <Calltoaction />
        <Footer />
    </div>
}

export default text_content 

