import React, { useState } from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';

import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect } from 'react';

const OrderAll = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    const getType = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const history = useHistory();
    const [type, setType] = useState(getType);
    const [loading, setLoading] = useState(false);
    const [master, setMaster] = useState(null);
    const id_token = localStorage.getItem('id_token');
    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");

    

   

    
    
    const handleOnClick = e => {
    e.preventDefault();
        setLoading(true);
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{
            localStorage.setItem('type', type);
            history.push("/client-order-enter-project-name");
        }
    }

    const MasterData = token => {
        fetch('http://devapi.textmercato.com/order/api/get-masterData', {
            method: 'GET',
            headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer '+id_token
            }
        }).then(res => res.json()).then(res => {            
            if(res.data){
                setMaster(res.data[0]);
                localStorage.setItem('masterData', JSON.stringify(res.data[0]))
            }
        });
    }

    useEffect(() => {

        const logincssUrl =   publicUrl + "assets/css/login.css";
        const loginstyle = document.createElement("link");
        loginstyle.href = logincssUrl;
        loginstyle.rel = "stylesheet";
        loginstyle.async = true;
        document.head.appendChild(loginstyle);

        
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/post-project.js";
        document.body.appendChild(minscript);


        let getMaster = localStorage.getItem('masterData');
        if(getMaster){
            setMaster(JSON.parse(getMaster));
            
        }else{
            MasterData(token);
        }
    }, []);

    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                     
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                        <div className="main active no-shadow">
                        
                            <div className="text text-center mb-4 mt-0">
                                <h3 className='color-blue'>What Do You Want To Create Today? </h3>
                                <p className='sub-heading-link text-center'>Choose from Text (blogs, articles, SEO content, Website Copy etc), Graphics (add, Add, Add, Add) <br></br>to Translations (In over 50 languages) </p>
                            </div>

                            <div className='row mt-3'>
                          

                              
                           

                                 
                                    <label for="text" className={ type === 'text' ? 'col-lg-4 col-md-6 content-type active ' : 'col-lg-4 col-md-6 content-type' }>
                                        <input id="text" onChange={(e) => setType(e.target.value)} data-message="Please select atleast one option" type='radio'  required require name="type" value="text" />
                                        <div className="service-four__single">
                                            <i className="fal fa-file-lines" />
                                            <h3><span>Text Content</span></h3>
                                            <p>Over 10K approved writers are ready to share their expertise on your requirements. Be it Blogs, Articles, SEO, Social Media Posts, Emailers,  Website Copy, or any other content.</p>
                                        </div>
                                    </label>
                                
                                    <label  for="translation" className={ type === 'translation' ? 'col-lg-4 col-md-6 content-type active ' : 'col-lg-4 col-md-6 content-type' }>
                                    <input id='translation' onChange={(e) => setType(e.target.value)}  data-message="Please select atleast one option" type='radio' required require name="type" value="translation" />
                                    <div className="service-four__single">
                                        <i className="fal fa-language" />
                                        <h3><span  >Translation </span></h3>
                                        <p>A majority of readers are more comfortable consuming content in their regional language. Our translation services in more than 50+ languages helps you connect better with your target audience.</p>
                                    </div>
                                </label>

                                <label for="graphics" className={ type === 'graphics' ? 'col-lg-4 col-md-6 content-type active ' : 'col-lg-4 col-md-6 content-type' }>
                                    <input id="graphics" onChange={(e) => setType(e.target.value)}  data-message="Please select atleast one option" type='radio' name="type" required require value="graphics" />
                                    <div className="service-four__single">
                                        <i className="fal fa-bezier-curve" />
                                        <h3><span >Graphics</span></h3>
                                        <p>A good graphic can explain simple or complex ideas in an easy-to-understand, recallable way. Use infographics, illustrations, images, and more to communicate with your customers.</p>
                                    </div>
                                </label> 

                            </div>



                            <div className="buttons button_space">
                                <button className="submit_button thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Register'}</button>
                                {/* <Link  to="/client-order-enter-project-name" className="nextBtn thm-btn mt-1">Continue</Link> */}
                            </div>
                             
                            
                         
                           
                        </div>
                    

  
 
                 
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="title" required require />
                                    <span>Enter Title</span>
                                </div>
                                
                            </div>


                            <div className="input-text" data-custom="word_count_custom_modal" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Enter Word Count</h5>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>700</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>1500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2000</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div  className="input-text  customBox">
                                <div id='word_count_custom_modal' className="input-div hidden">
                                    <input type="tel" name="custom_count"  />
                                    <span>Enter Word Count </span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="keywords" required require />
                                    <span>Please enter your keywords or say "Add Keywords" if you want us to </span>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Enter Keyword density, internal linking requirement or others </span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="target_audience" required require />
                                    <span>Tell us about your readers </span>
                                    
                                </div>
                            </div>

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>

    </div>
}

export default OrderAll

