import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';

import Calltoaction from '../section-components/cta';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestimonialCommon from '../section-components/testimonial-common';
import CsutomerCarousal from '../CsutomerCarousal';

const graphics  = () => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div className="homepage">
		<Helmet>
            <title>A one-stop halt for specially curated graphic design services</title>
            <meta name="description" content="TextMercato has all the prerequisites to provide unlimited graphic design services. Start your journey with TextMercato for the best graphic design services agency." />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3><span>Let the </span>Experts Visualize <span>the Art For You</span></h3>
                    <p>The graphic content requirement for every website is different. The expert team of TextMercato can quickly understand the possibilities and implement artistic changes. Let the experts assist you with professional design services for infographics, ebook designs, images, and more to communicate visually with your target audience.</p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <img width="85%" src={publicUrl+"assets/services/graphics/banner.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>


		<CsutomerCarousal></CsutomerCarousal>






        <section className="service-three white-bg pt-0  go-top">
		  <div className="container">
		    <div className="block-title-two text-center mb-0">
		      <h3>Graphic Design Services You Can Choose</h3>
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>TextMercato has an in-house team for unlimited graphic design services to keep your business on top.</p>	

			<div className="row mt-5 services-listing image-as-icon">
				
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/Emailer Design-1.svg"} alt="Emailer Design" />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						<h3>Emailer Design</h3>
						<p>Market your brand and product with interesting and original emailer designs. Explore customized graphic designs with TextMercato to grab the attention of your target audience. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/Website _ App Banners-1.svg"} alt="Website & App Banners" />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Website & App Banners</h3>
						<p>Want to promote a new service or product? Just ask TextMercato. Our group of innovative designers will offer the best design service for your business. Get the best possible combination of art and information to make your web or app banners stand out.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/infographics-svgrepo-com 2.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Infographics </h3>
						<p>Let TextMercato enrich your content with fun and informative infographics. Trained graphic designers will create compelling content from scratch to suit your business needs.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/ebook-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Ebook Design</h3>
						<p>TextMercato’s capable team of graphic designers can simplify and organize complex data in visually appealing Ebook format. Our skillful designs and attention to detail will definitely help you to reach your target audience.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/logo-design (1) 1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Logo Design</h3>
						<p>The logo is the face of your business. It should exhibit the purpose and objectives of your business. TextMercato designers will perfect your logo or create a new one from scratch, to establish an eye-catching identity for your brand. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/gifs-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>GIFs</h3>
						<p>Leave your footprint in the internet’s vast open space using short and crisp GIFs. The witty graphic content curated by TextMercato can be a powerful marketing tool for you.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}


				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/presentation Design-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Presentation Design</h3>
						<p>Worried about your presentation for an important meet? We’ve got you covered. Our team will optimize your content and create an impressive presentation to help you achieve your goals.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/Poster-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Posters</h3>
						<p>Be it promotional content, events, sales, or any other type of poster. TextMercato will create the perfect poster for you that can captivate the eyes of your audience.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				


				<div className="col-lg-12 col-md-12 text-center mt-5">
					<Link to="/get-started" className="thm-btn banner-two__btn ">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
				</div>


				</div>{/* /.row */}

		  </div>{/* /.container */}
		</section>



		<section className="funfact-one">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-mug-hot" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">1</span>{/* /.counter */} Mn+</h3>
		          <p>Content pieces delivered</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-heart" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">60</span>{/* /.counter */}+</h3>
		          <p>Content types</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-award" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">2500</span>{/* /.counter */}+
		          </h3>
		          <p>Brands we’ve worked with</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-user-alt" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">100000</span>{/* /.counter */}+</h3>
		          <p>Strong creator network</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




		<section id='digital-content' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3><span>Expert Assistance</span> on Graphic Design Projects </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'> </p>

					<p className='mt-3 lineheight-normal'>The content journey for every business is unique. TextMercato is one of the best graphic design services agency. Choose TextMercato for quick and efficient delivery of graphic designs as per your requirements. </p>
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Unique Top-of-the-Line Designs</h3><p>We make it easy for you to build your brand and communicate with custom-made designs </p></div></div>

						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Assured Quality Content </h3><p>If the content is not as per your requirements, we take the responsibility to revise it even if it’s for the N<sup>th</sup> time.  </p></div></div>
						<div class="about-two__box"><div class="about-two__box-icon"><i class="far fa-check"></i></div><div class="about-two__box-content"><h3>Stay Updated at All Times</h3><p>Check the progress of your project by logging in to your dashboard. Furthermore, a dedicated Account Manager will always assist you in meeting any new requirements. </p></div></div>
						


					</div>{/* /.about-four__content */}
                    
				<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/services/graphics/expert-assistance.png"} className="about-four__moc  " alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<TestimonialCommon></TestimonialCommon>



        <section className="faq-two">
        <div className="container">
          <div className="row">
            
           
            
            
            <div className="col-lg-5">
              <div className="faq-two__content">
                <div className="block-title-two text-left">
                  
                  <h3>FAQs</h3>
                </div>{/* /.block-title-two */}

                <ul className='faq-questions-list'>
                  <li><a href='#faq-1'>Why should I choose TextMercato graphic design service?</a></li>
                  <li><a href='#faq-2'>Can I see some samples before committing to TextMercato?</a></li>
                  <li><a href='#faq-3'>Does TextMercato have the required workforce to take up large projects?</a></li>
				  <li><a href='#faq-4'>What are the best graphic design service companies?</a></li>
                  
                </ul>

                


                
              </div>{/* /.faq-two__content */}
            </div>{/* /.col-lg-6 */}


            <div className="col-lg-7 d-flex justify-content-center">
              
            <div className="inner">
                <div id='faq-1' className='faq-answer-block active'>
                  <h2>Why should I choose TextMercato graphic design service?</h2>
                  <p> Graphic design is not only about beautifying a webpage. It optimizes your chance to get your content across a larger mass efficiently with visual cues. TextMercato offers the best graphic design services and can create impactful designs for your business.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                  
                </div>
                <div id='faq-2' className='faq-answer-block '>
                  <h2>Can I see some samples before committing to TextMercato?</h2>
                  <p>Yes, we are just an email away. When you choose TextMercato as your graphic design partner, you will get all the assistance necessary for a smooth transaction.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-3' className='faq-answer-block '>
                  <h2>Does TextMercato have the required workforce to take up large projects?</h2>
                  <p>TextMercato has a large team of competent designers - inhouse and freelancers, to execute large projects within a reasonable deadline. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
				<div id='faq-4' className='faq-answer-block '>
                  <h2>What are the best graphic design service companies?</h2>
                  <p>There might be a bunch of graphic design providers online, but with TextMercato, you get a start-to-end content solution for your business. When your content is created in sync with your graphic, there will be no gaps in communication. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
               
                
            </div>{/* /.inner */}
                
            
            </div>{/* /.col-lg-6 */}




            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>
        <Calltoaction />
        <Footer />
    </div>
}

export default graphics 

