import React, { Component } from 'react';

class TestimonialCommon extends Component {
        componentDidMount() {

      const $ = window.$;
      

        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        

    return  <section className={"testimonials-two white-bg "}>
	<div className="container">
	  <div className="block-title-two text-center">
		<p>our testimonials</p>
		<h3>What Our Client's  Say About Us</h3>
	  </div>{/* /.block-title-two */}
	  <div className="testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme" data-options="{&quot;loop&quot;: true, &quot;autoHeight&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 2, &quot;smartSpeed&quot;: 1000, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 2500, &quot;nav&quot;: true, &quot;dots&quot;: true,  &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 2 }, &quot;1200&quot;: { &quot;items&quot;: 2 } }}">
	  
		 
	  <div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/writerzen.png"} alt="writerzen" />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Dieu Tran</h3>
				<span>SEO Specialist, <a href='https://writerzen.net/' target='_blank'>WriterZen</a></span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>We were looking for a content agency that could provide long-form articles in a short period, and TextMercato delivered. Besides offering comprehensive content, they've also supported us in researching keywords and topics for our blog section. We really appreciate this collaboration. Thanks to them for always being very responsive, supportive, and open to feedback; and we hope to carry our partnership further. If you have the same demand as us, TextMercato is our recommendation. </p>
			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}
		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/entomo.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Om Prakash</h3>
				<span>Director of Marketing, entomo</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>“entomo has been associated with TextMercato for the requirement of niche content on <a href="https://entomo.co/" target='_blank'>employee performance management</a> and talent development. The team has been supportive in working closely with various internal stakeholders, incorporating their feedback and iterations, and delivering high quality content pieces with substantial depth.”</p>
			</div>{/* /.testimonials-two__content */}
			
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}
		
		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/oliveboard.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Nikhil Dudhawat</h3>
				<span><a href="https://www.oliveboard.in/" target='_blank'>Olive Board</a></span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>TextMercato has helped us with 1500+ blogs/web pages content (&gt;15,00,000 words) to expand our content depth for 100+ Govt exams (State-level as well as National level). The team  had set up a hassle free system from planning to delivery of high quality content within deadlines. Ours being a competitive exams domain, we get a lot of adhoc exam notifications. As a result, there would be a lot of priority content requests which the team delivered on time (sometimes within 24 hrs) without compromising on quality.</p>
			</div>{/* /.testimonials-two__content */}
			
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}
		
		
		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/emoha.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Shubham Kumar Pandey</h3>
				<span><a href="https://emoha.com/" target='_blank'>Emoha</a></span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>“Having worked with TextMercato for more than a year now, I can say I am very pleased with their work. Content writers are extremely professional with an amazing ability to pay attention to details. Their ability to transform a complex content draft into an organised, concise, and easy-to-understand piece of information has played an important part in making our marketing a success. I would highly recommend TextMercato if you are seeking a professional content writers who can create truly engaging content for you.”</p>
			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}


		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/woovly.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Jeet Bhanushali</h3>
				<span>Head of Growth</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>We worked with TextMercato to create content for our e-commerce collections page at Woovly. The amount of domain knowledge about the beauty space that our POC had was phenomenal. The inputs we received from her were very helpful. The content team from TextMercato were phenomenal with their editorial support.</p>

			  <p>Feedback: Due to the nature of their business model (different writers picking up the work every time), it was a challenge to get a specific person completely well-versed with the type of content we needed. Even here, the team made sure that things were resolved at the editing stage and content was close to what we expected.</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}


		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/nilkamal.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Siddharth Pamoor </h3>
				<span>Sr.SEO Executive</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>Excellent! The SEO content writing at TextMercato is of exceptional quality. Their ability to research and develop the content keeping in mind the latest SEO tricks and tactics is commendable. Thank you for your professional attention to each detail. You people have been really supportive throughout the process and promptly followed up concerning every matter.</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}



		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/frontfold.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Nandan S Kedlaya  </h3>
				<span>Founder</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>Having been in the digital marketing space for over 8 years now, TextMercato has been our goto choice for quality content. Their content has helped us boost our SEO efforts by multifolds. The writers are well trained, diligent, and have a keen understanding of the topics given to them which makes their services truly plug and play. The teams are built for scale and is a major factor for FrontFold to continue engagement year on year. Kudos to the entire team for making this possible. You are the reason for a lot of brands' success stories!</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}


		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/staragile.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Balkrishan Chauhan  </h3>
				<span>Sr. Digital Marketing Consultant</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>"TextMercato delivers exceptional content quality. They are quick and efficient, and make our work effortless, as their content requires minimal corrections. TextMercato turned out to be great! They have never failed to live up to our expectations and have become our favourite team to work with."</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}


	   


		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/myforexeye.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Ritesh Victor  </h3>
				<span>Country Head, Myforexeye</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>“I just wanted to share a quick note and let you know that the TextMercato guys did really a good job. I'm glad that Myforexeye decided to work with TextMercato.</p>

			  <p>I would like to thank the Customer Success Manager for the fantastic work on rewriting the content for our blogs. The CSM was brilliant and took time to understand our subject and the content of our business.</p>

			  <p>I appreciate the timeliness of her service, professional ethics, and the confidence that came with her knowledge and ability to write engaging content.”</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}



		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/urban.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Shikha  </h3>
				<span>Urban Ladder</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>TextMercato grasped our brands’ tone of voice quickly and accurately. As a result, we did not feel like the work was outsourced. We were working with an extension of our own team.</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}


		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/goodlyfeproject.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Dushyant Verma</h3>
				<span><a href="https://goodlyfeproject.in/" target='_blank'>Good Lyfe Project</a></span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>Versatile Content Provider : I highly recommend TextMercato as a provider of quality content.
				  <br></br>
				  As a start-up, we always faced the challenge of generating content. Either we would create content ourselves (necessary but time-consuming) or work with freelancers (below quality). We also tried getting a person on our payroll, but over some time, we would get the same quality of content and we were unable to produce anything new. TextMercato solved our problem by giving us a panel of content experts. They not only generated quality content but also provided topics in advance. The content team has closely worked with us on the Good Lyfe Project. They are an extension of our team and have produced high quality content.If you are riding on the same ship as we were,  you should definitely consider TextMercato.</p>
			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}

		<div className="item">
		  <div className="testimonials-two__single">
			<div className="testimonials-two__top">
			  <div className="testimonials-two__image">
				<img src={publicUrl+"assets/logos/theluxurycloset.png"} alt={ imagealt } />
			  </div>{/* /.testimonials-two__image */}
			  <div className="testimonials-two__top-content">
				<h3>Swati  </h3>
				<span>The Luxury Closet</span>
			  </div>{/* /.testimonials-two__top-content */}
			</div>{/* /.testimonials-two__top */}
			<div className="testimonials-two__content">
			  <p>TextMercato is not only a content partner which helped is make processes clean and cost efficient but they were notivated to bring technological solutions for typeical e-commerce problems of cost and scale from the word go. Efficient and professional, its a pleasure to be associated with them.</p>

			</div>{/* /.testimonials-two__content */}
		   
		  </div>{/* /.testimonials-two__single */}
		</div>{/* /.item */}
		 
		
		 
		 
	  </div>{/* /.testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme */}
	</div>{/* /.container */}
  </section>

        }
}

export default TestimonialCommon


