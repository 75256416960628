import React from 'react';
import Navbar from './global-components/navbar-v2';
import HubspotForm from 'react-hubspot-form'
import Brand from './section-components/brand-two';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import TestimonialV2 from './section-components/testimonial-v3';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const Freelance = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);


    return <div>

        <Helmet>
            <title>1000+ Businesses Trust TextMercato for their Content Needs. Connect Now!
</title>
            <meta name="10000 plus content writers, global clientele, digital marketing, and more. Our strategists and experts will manage it successfully." />
        </Helmet>

        <Navbar />
        
        
		<section className="banner-two pb-0  go-top banner-inner ">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content ">
                    <h1><span>All your content needs are handled</span> efficiently and transparently</h1>
                    <p>TextMercato is one of the leading content companies catering to the growing need for quality content in the Indian & international market.  Content is the key that adds value to the users, engaging and communicating about your brand.</p>
                    
					<div className="about-four__content small-icons two-columns mt-3">
		            
						<Link to="/services#text-content" data-target="#text-content" className="about-two__box"><div className="about-two__box-icon"><i className="fal fa-file-lines"></i></div><div className="about-two__box-content"><h3>Text</h3><p>Over 10K approved writers are ready to share their expertise on your requirements. Be it Blogs Articles, SEO, Posts, Emailers,  Website Copy, or any other content. </p></div></Link>

						<Link to="/services#graphics" data-target="#graphics" className="about-two__box"><div className="about-two__box-icon"><i className="fal fa-bezier-curve"></i></div><div className="about-two__box-content"><h3>Graphics</h3><p>A good graphic can explain simple or complex ideas in an easy-to-understand, recallable way. Use infographics,  illustrations, images, and more to communicate with your customers.</p></div></Link>
						<Link to="/services#transcriptions" data-target="#transcriptions" className="about-two__box"><div className="about-two__box-icon"><i className="far fa-language"></i></div><div className="about-two__box-content"><h3>Translations</h3><p>Take advantage of our translation services in more than 50 recognized languages to connect better with your target audience. </p></div></Link>
						<Link to="/services#translations" data-target="#translations" className="about-two__box"><div className="about-two__box-icon"><i className="far fa-file-audio"></i></div><div className="about-two__box-content"><h3>Transcriptions</h3><p>Video to Text, Audio to Text!<br></br> Let us know what your transcription needs are, and we can contribute positively. </p></div></Link>



					</div>{/* /.about-four__content */}


                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

                <div className="col-md-6 d-flex">
			        <div className="my-auto husbpost-form">

					<h3>Thank you</h3>
                    <p>Our expert will reach out to you shortly</p>

			        </div>{/* /.my-auto */}
			      </div>{/* /.col-lg-7 */}


            </div>
            </div>
        </section>

		<Brand></Brand>
        <TestimonialV2 CustomClass="add-last-ct2 grey-bg"></TestimonialV2>
        <Calltoaction />
        <Footer />
    </div>
}

export default Freelance

