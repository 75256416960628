import React from 'react';
import Navbar from './global-components/navbar-v2';
import Testimonials from './section-components/testimonial-v5';
import HubspotForm from 'react-hubspot-form'
import { Link } from 'react-router-dom';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import Helmet from 'react-helmet';

const Freelance = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'


    return <div>

        <Helmet>
            <title>Love to create inspiring content ? Join Our Community. </title>
            <meta name="Work at your convenience and time on the domains you prefer. Share your expertise and knowledge on subjects you excel at and build a great career." />
        </Helmet>

        <Navbar />
        
        
		<section className="banner-two pb-0 pt-0 go-top banner-inner">
      <div className="container pt-0">
            <div className="row">
                <div className="col-xl-6 pt-5 my-auto">
                <div className="banner-two__content pt-5 ">
                    <h1><span>Love to create</span> inspiring content ? <span>You are at the right place</span></h1>
                    <p>Work at your convenience and time on the domains you prefer. Share your expertise and knowledge on subjects you excel at and build a great career. </p>
                    <Link  to="/freelance" data-target="#join-community" className="thm-btn banner-two__btn btn-big">Join Now <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6 '>
                <img width="100%" className=" "   src={publicUrl+"assets/freelance.png"} alt="Banner" />
            </div>
            </div>
      </div>
    </section>


        <section id='join-community' className="contact-one grey-bg mb-5 mt-5 add-last-ct2">
			  <div className="container">


			        <div className='row'>
                <div className='col-md-8 offset-2'>
                <div className="block-title-two text-center mb-0">
				
                    <h3>Join Our Freelancer Community</h3>   
                  </div>{/* /.block-title-two text-center */}
                  <p className='mt-3 text-center'>Start your freelancing journey with TextMercato. Join our community of 10k+ freelance content creators, translators, transcription experts, video wizards, and more.</p>
                </div>
              </div>
             

			   
			    <div className="row mt-5">



					
			      
			      <div className="col-md-6 offset-3 my-auto d-flex">

				 

				  <div className="my-auto husbpost-form white-bg">

						<HubspotForm
						portalId='22122965'
						formId='e09548ea-0d2f-473b-8f72-563177f67c7b'
						onSubmit={() => console.log('Submit!')}
						onReady={(form) => console.log('Form ready!')}
						loading={<div>Loading...</div>}
						/>

						</div>{/* /.my-auto */}
			      </div>{/* /.col-lg-7 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>



        <Testimonials></Testimonials>
        <Calltoaction ></Calltoaction>
        <Footer />
    </div>
}

export default Freelance

