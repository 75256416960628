import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import Store from './store';

const Loader = (Component) => (props) =>
  (
    <React.Suspense fallback={ ( <></> )}>
      <Component {...props} />
    </React.Suspense>
  );

const HomeV2  = React.lazy(() => import('./components/home-v2'));
const digital_content_platform  = React.lazy(() => import('./components/digital-content-platform'));
const freelance  = React.lazy(() => import('./components/freelance'));
const contentTypes  = React.lazy(() => import('./components/contentTypes'));
const BlogGrid  = React.lazy(() => import('./components/blog-grid'));
const Tags  = React.lazy(() => import('./components/blog-components/Tags'));
const Category  = React.lazy(() => import('./components/blog-components/category'));
const Search  = React.lazy(() => import('./components/blog-components/search'));
const BlogDetails  = React.lazy(() => import('./components/blog-details'));
const Platform  = React.lazy(() => import('./components/platform'));
const get_started  = React.lazy(() => import('./components/get-started'));
const resources  = React.lazy(() => import('./components/resources'));
const Samples  = React.lazy(() => import('./components/samples'));
const DownloadSamples  = React.lazy(() => import('./components/download-samples'));
const cmsPages  = React.lazy(() => import('./components/cmsPages'));
const faqs  = React.lazy(() => import('./components/faq'));
const faqsDetails  = React.lazy(() => import('./components/faq-details'));
const text_content  = React.lazy(() => import('./components/services/text-content'));
const graphics  = React.lazy(() => import('./components/services/graphics'));
const translation  = React.lazy(() => import('./components/services/translation'));
const Startup  = React.lazy(() => import('./components/companies/startup'));
const Login  = React.lazy(() => import('./components/login'));
const LoginWithOtp  = React.lazy(() => import('./components/login_with_otp'));
const forgotPassword  = React.lazy(() => import('./components/forgot-password'));
const passwordResetPage  = React.lazy(() => import('./components/password-reset-page'));
const Register  = React.lazy(() => import('./components/register'));
const writerRegister  = React.lazy(() => import('./components/writer-register'));
const ClientOrderAll  = React.lazy(() => import('./components/client-order-flow/all'));
const ClientOrderProjectName  = React.lazy(() => import('./components/client-order-flow/client-order-enter-project-name'));


const ClientOrderRequirements  = React.lazy(() => import('./components/client-order-flow/client-order-content-requirements'));
const ClientOrderRequirementsgraphics  = React.lazy(() => import('./components/client-order-flow/client-order-graphics-requirements'));
const ClientOrderRequirementstranslation  = React.lazy(() => import('./components/client-order-flow/client-order-translation-requirements'));


const ClientOrderProjectDetails  = React.lazy(() => import('./components/client-order-flow/client-order-content-project-details'));

const ClientOrderProjectDetailsgraphics  = React.lazy(() => import('./components/client-order-flow/client-order-graphics-project-details'));
const clientordergraphicsdosdonts  = React.lazy(() => import('./components/client-order-flow/client-order-graphics-dos-donts'));

const ClientOrderProjectDetailstranslation  = React.lazy(() => import('./components/client-order-flow/client-order-translation-project-details'));
const clientOrderTranslationOrderreview  = React.lazy(() => import('./components/client-order-flow/client-order-translation-orderreview'));



const ClientOrderImages  = React.lazy(() => import('./components/client-order-flow/client-order-images'));
const ClientOrderReviews  = React.lazy(() => import('./components/client-order-flow/client-order-review'));
const clientOrderGraphicsOrderreview  = React.lazy(() => import('./components/client-order-flow/client-order-graphics-orderreview'));


const clientProfile  = React.lazy(() => import('./components/client-profile'));
const clientProfileCompanyBilling  = React.lazy(() => import('./components/client-profile-company-billing'));




const ClientOrderPayments  = React.lazy(() => import('./components/client-order-flow/client-order-payments'));
const ClientOrderSucessful  = React.lazy(() => import('./components/client-order-flow/client-order-text-payments-sucessful'));
const PostProjectTranslation  = React.lazy(() => import('./components/post-projects-translation'));
const PostProjectGraphics  = React.lazy(() => import('./components/post-projects-graphics'));
const uploadBulk  = React.lazy(() => import('./components/upload-bulk'));
const Dashboard  = React.lazy(() => import('./components/dashboard'));
const OrderDetail  = React.lazy(() => import('./components/OrderDetail'));
const Projects  = React.lazy(() => import('./components/projects'));
const Glossary  = React.lazy(() => import('./components/glossary'));
const websiteBlog  = React.lazy(() => import('./components/website-blog'));
const productDescriptions  = React.lazy(() => import('./components/product-descriptions'));
const whitePapper  = React.lazy(() => import('./components/white-papper'));
const Ebook  = React.lazy(() => import('./components/ebook'));
const Copywriting  = React.lazy(() => import('./components/copywriting'));
const SEOArticles  = React.lazy(() => import('./components/seo-articles'));
const BrandIdentity  = React.lazy(() => import('./components/BrandIdentity'));
const About  = React.lazy(() => import('./components/About'));
const Contact  = React.lazy(() => import('./components/contact'));
const Common  = React.lazy(() => import('./components/industries/Common'));
const Finance  = React.lazy(() => import('./components/industries/finance'));
const get_started_thankyou  = React.lazy(() => import('./components/get-started-thankyou'));


class ClientRoute extends Component {
    render() {
        return(
            <Provider store={Store}>
               
	             
	                {/* <Switch> */}
	                   
						<Route exact path="/" render={Loader(HomeV2)} />
						<Route exact path="/product" render={Loader(digital_content_platform)} />
						<Route exact path="/brand-identity" render={Loader(BrandIdentity)} />
						<Route exact path="/about-us" render={Loader(About)} />
						<Route exact path="/contact" render={Loader(Contact)} />


						<Route exact path="/client-profile" render={Loader(clientProfile)} />
						<Route exact path="/client-profile-company-billing" render={Loader(clientProfileCompanyBilling)} />

						
						
						<Route exact path="/dashboard" render={Loader(Dashboard)} />
						<Route exact path="/order-detail/:id" render={Loader(OrderDetail)} />
						<Route exact path="/projects" render={Loader(Projects)} />

						<Route exact path="/glossary" render={Loader(Glossary)} />
						<Route exact path="/faqs" render={Loader(faqs)} />
						<Route exact path="/faqs/page/:page" render={Loader(faqs)} />
						<Route exact path="/faq/:slug" render={Loader(faqsDetails)} />
						
						<Route exact path="/search/:term" render={Loader(Search)} />

						<Route exact path="/blog/tag/:slug" render={Loader(Tags)} />
						<Route exact path="/blog/tag/:slug/page/:page" render={Loader(Tags)} />


						<Route exact path="/category/:slug" render={Loader(Category)} />
						<Route exact path="/category/:slug/:page" render={Loader(Category)} />
						<Route exact path="/blog" render={Loader(BlogGrid)} />
						<Route exact path="/blog/page/:page" render={Loader(BlogGrid)} />
						<Route exact path="/blog/:slug" render={Loader(BlogDetails)} />

						<Route exact path="/content-writing/website-blog" render={Loader(websiteBlog)} />
						<Route exact path="/content-writing/product-descriptions" render={Loader(productDescriptions)} />
						<Route exact path="/content-writing/whitepapper" render={Loader(whitePapper)} />
						<Route exact path="/content-writing/ebooks" render={Loader(Ebook)} />
						<Route exact path="/content-writing/copywriting" render={Loader(Copywriting)} />
						<Route exact path="/content-writing/seo-articles" render={Loader(SEOArticles)} />

						<Route exact path="/industries" render={Loader(Common)} />
						<Route exact path="/industry/finance" render={Loader(Finance)} />

						<Route exact path="/services" render={Loader(contentTypes)} />
						<Route exact path="/services/text-content" render={Loader(text_content)} />
						<Route exact path="/services/graphics" render={Loader(graphics)} />
						<Route exact path="/services/translations" render={Loader(translation)} />

						<Route exact path="/companies/startup" render={Loader(Startup)} />

						<Route exact path="/platform" render={Loader(Platform)} />
						<Route exact path="/freelance" render={Loader(freelance)} />
						<Route exact path="/get-started" render={Loader(get_started)} />
						<Route exact path="/thank-you" render={Loader(get_started_thankyou)} />
						<Route exact path="/resources" render={Loader(resources)} />
						
						<Route exact path="/samples" render={Loader(Samples)} />
						<Route exact path="/downloadsampales/:pdf?" render={Loader(DownloadSamples)} />


						<Route exact  path="/login" render={Loader(Login)} />
						<Route exact  path="/login-with-otp" render={Loader(LoginWithOtp)} />
						<Route exact  path="/password-reset-request" render={Loader(forgotPassword)} />
						<Route exact  path="/password-reset-page" render={Loader(passwordResetPage)} />
						
						<Route exact  path="/client-register" render={Loader(Register)} />
						<Route exact  path="/creator-register" render={Loader(writerRegister)} />
						<Route exact  path="/post-project-translation" render={Loader(PostProjectTranslation)} />
						
						<Route exact  path="/post-project-graphics" render={Loader(PostProjectGraphics)} />
						<Route exact  path="/bulk-upload" render={Loader(uploadBulk)} />
						<Route exact  path="/privacy-policy" render={Loader(cmsPages)} />
						<Route exact  path="/cookie-policy" render={Loader(cmsPages)} />
						<Route exact  path="/terms-of-use" render={Loader(cmsPages)} />


						<Route exact  path="/client-order-all" render={Loader(ClientOrderAll)} />

						<Route exact  path="/client-order-enter-project-name" render={Loader(ClientOrderProjectName)} />
						<Route exact  path="/client-order-content-requirements" render={Loader(ClientOrderRequirements)} />

						<Route exact  path="/client-order-graphics-requirements" render={Loader(ClientOrderRequirementsgraphics)} />
						<Route exact  path="/client-order-translation-requirements" render={Loader(ClientOrderRequirementstranslation)} />


						<Route exact  path="/client-order-content-project-details" render={Loader(ClientOrderProjectDetails)} />
						<Route exact  path="/client-order-graphics-project-details" render={Loader(ClientOrderProjectDetailsgraphics)} />
						<Route exact  path="/client-order-graphics-dos-donts" render={Loader(clientordergraphicsdosdonts)} />

						
						<Route exact  path="/client-order-translation-project-details" render={Loader(ClientOrderProjectDetailstranslation)} />
						<Route exact  path="/client-order-translation-orderreview" render={Loader(clientOrderTranslationOrderreview)} />
						

						<Route exact  path="/client-order-images" render={Loader(ClientOrderImages)} />


						<Route exact  path="/client-order-review" render={Loader(ClientOrderReviews)} />
						<Route exact  path="/client-order-graphics-orderreview" render={Loader(clientOrderGraphicsOrderreview)} />

						
						<Route exact  path="/client-order-payments" render={Loader(ClientOrderPayments)} />
						<Route exact  path="/client-order-text-payments-sucessful" render={Loader(ClientOrderSucessful)} />
						
						

	                {/* </Switch> */}
	            
               
                </Provider>
        )
    }
}

export default ClientRoute;
