import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Diversify Your Website Using TextMercato eBook writing services</title>
            <meta name="TextMercato has subject matter experts who ensure a high-quality eBook writing service. Our team also includes creative designers who offer eBook design services." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1><span>The Ultimate Hub for </span> Interesting eBook Write-ups <span> and Designs  </span></h1>
                  <p>Our in-house group of writers and editors make long-format eBook write-ups look easy. Our eBook writers have the right skills to write in-depth content made simple to understand for your readers. Choose TextMercato for exquisite eBook designs and interesting eBook content.</p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/ebooks.png"} alt="Banner" />
                  </div>


            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Custom-eBook-Writing-Services.png"} className="about-four__moc  " alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Custom eBook Writing Services For Your Brand</h3>
		          </div>{/* /.block-title-two text-left */}
		          
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>SEO-optimized eBooks   </h3><p>You don’t have to worry about a drop in search engine rankings when you choose TextMercato eBook writing services. The best eBook writers maintain a healthy quantity content for each write-up.</p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Writers for All Categories   </h3><p>We have subject matter experts who can handle any given topic. Give us the details and our writers will do in-depth research for the eBook. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Standardized Content Quality   </h3><p>The well-researched eBooks written by expert writers are unique and plagiarism-free. When you choose TextMercato eBook services, you choose perfection.  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Designs That Make Your Website Unique   </h3><p>Every brand wants unique designs in their eBook content. Or in-house designers strive to make your eBook design interesting and distinct. Pick TextMercato eBook design services now to get unique eBooks.</p></div></div>
                        
                        <div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Combination of Writers and Designers    </h3><p>An eBook needs quality content to support the design. Our expert designers and writers work side-by-side to provide an eBook enriched with an exquisite design and intelligent write-up.</p></div></div>

            

					</div>{/* /.about-four__content */}
                    
                    
                  {/* <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link> */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>Why are eBooks necessary?</a></li>
                    <li><a href='#faq-2'>Will I get plagiarism-free content?</a></li>
                    <li><a href='#faq-3'>Can I make revisions to the delivered content?	</a></li>
                    <li><a href='#faq-4'>Why should I choose TextMercato blog post writing service?</a></li>
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>Why are eBooks necessary?</h2>
                    <p> ebooks are paperback books in a virtual format. A brand can use eBooks to deliver in-depth information to its audience about products or services. Furthermore, eBooks can build trust between you and your followers and bring in more business.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Will TextMercato ensure plagiarism-free content?</h2>
                    <p>TextMercato’s eBook writers and editors are trained to create unique content. We use industry-accepted plagiarism software to check each piece of content before delivery.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                    <h2>Can I make revisions to the delivered content?	</h2>
                    <p>TextMercato account managers are quick to resolve any issues with the content. If the delivered content differs from the brief, our team will promptly address the problem.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>

                    <div id='faq-4' className='faq-answer-block '>
                    <h2>Why should I choose TextMercato eBook writing service?</h2>
                    <p>TextMercato offers high-quality eBook writing service and eBook design services. TextMercato’s expert eBook writers are adept in custom eBook writing.  </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

