import React from 'react';
import Navbar from './global-components/navbar-v2';
import Faqpage from './section-components/faq-v3';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import { Link } from 'react-router-dom';

const Faq = () => {
    return <div>
        <Navbar />
        
        <section className="page-header" >
				  <div className="container">
				    <h2>FAQs </h2>
				    <ul className="list-unstyled thm-breadcrumb">
				      <li><Link to="/">Home</Link></li>
                       
				      <li><span>Faqs</span></li>
				    </ul>{/* /.list-unstyled thm-breadcrumb */}
				  </div>{/* /.container */}
			</section>
        <Faqpage />
        
        <Calltoaction ></Calltoaction>
        <Footer />
    </div>
}

export default Faq

