import React, { Component } from 'react';
import parse from 'html-react-parser';

class funfactTestimonials extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <section className="funfact-one">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-suitcase" />
		          </div>{/* /.funfact-one__icon */}
		          <h3>1M+</h3>
		          <p>Content Pieces Delivered</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6 col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-user-tie" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">1000</span>{/* /.counter */}+</h3>
		          <p>Delighted Clients</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6  col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-globe" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">100</span>{/* /.counter */}+
		          </h3>
		          <p>Global Clientele</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-lg-3 col-6  col-md-6">
		        <div className="funfact-one__single">
		          <div className="funfact-one__icon">
		            <i className="far fa-users" />
		          </div>{/* /.funfact-one__icon */}
		          <h3><span className="counter">200</span>{/* /.counter */}+</h3>
		          <p>Team Members</p>
		        </div>{/* /.funfact-one__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default funfactTestimonials