import React, { useEffect, useState  } from 'react';
import axios from 'axios';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import { useLocation } from "react-router-dom";
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import Helmet from 'react-helmet';

const PrivacyPolicy = () => {

    const [error, setError] = useState(null);
	const [page, setPage] = useState(null);
    const location = useLocation();
    let slug = location.pathname.replace("/", "");
    
    
	useEffect(() => {
		axios
		.get('https://www.textmercato.com/cms/api/pages?filters[slug][$eq]='+slug+'')
		.then(response => {
			if(response.data.data[0].attributes){
				setPage(response.data.data[0].attributes);
			}
		})
		.catch((error) => setError(error));
	}, []);
    
    let publicUrl = process.env.PUBLIC_URL+'/'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    
    return <div>
         <Helmet>
            <title>{ page ? page.Title  : "TextMercato" }</title>
            <meta name="" />
        </Helmet>
        <Navbar />
        <PageHeader headertitle={ page ? page.Title  : "" }  />
        <section className="service-two service-two__service-page service-two__about-page  go-top">
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                
                </div>
                <div className="col-lg-12">
                <div className="service-two__block">
                    {
                        page ? ( <div dangerouslySetInnerHTML={{ __html: page.Body }} /> ) : ( <div className="loader content_preloader">Loading...</div> )
                    }                   
                   
                </div>
                </div>
            </div>
            </div>
        </section>
        <Calltoaction></Calltoaction>
        <Footer />
    </div>
}

export default PrivacyPolicy

