import React, { useState } from 'react';
import SidebarLeft from './global-components/dashboard/sidebar-left';
import Nav from './global-components/dashboard/nav';
import Footer from './global-components/dashboard/footer';


import DataTable from 'react-data-table-component';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import { useMemo } from 'react';



const OrderDetail = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
 

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/dashboard.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/dashboard.js";
    document.body.appendChild(minscript);

    
 

    return <div className=''>
        <div className="min-height-300 bg-primary position-absolute w-100"></div>
        <Helmet>
            <title>Order Detail</title>
            <meta name="" />
        </Helmet>
        
        <SidebarLeft menu="dashboard"></SidebarLeft>

        <main className="main-content position-relative border-radius-lg ">

        <Nav></Nav>

    <div className="container-fluid py-4">

      <div className="row mt-4">
        <div className="col-lg-12 mb-lg-0 mb-4">
          <div className="card ">
            <div className="card-header pb-0 p-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-2">Order Detail</h5>
              </div>
            </div>
            
            <div className="row">
    <div className="col-lg-8">

      <div className="card mb-4">
        <div className="card-body">
          <div className="mb-3 d-flex justify-content-between">
            <div>
              <span className="me-3">22-11-2021</span>
              <span className="me-3">#1612</span>
              <span className="badge rounded-pill bg-warning">Order pending</span>
            </div>
            <div className="d-flex">
              <button className="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text"><i className="bi bi-download"></i> <span className="text">Invoice</span></button>
              <div className="dropdown">
                <button className="btn btn-link p-0 text-muted" type="button" data-bs-toggle="dropdown">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><a className="dropdown-item" href="#"><i className="bi bi-pencil"></i> Edit</a></li>
                  <li><a className="dropdown-item" href="#"><i className="bi bi-printer"></i> Print</a></li>
                </ul>
              </div>
            </div>
          </div>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>
                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <img src="https://via.placeholder.com/280x280/87CEFA/000000" alt="" width="35" className="img-fluid" />
                    </div>
                    <div className="flex-lg-grow-1 ms-3">
                      <h6 className="small mb-0"><a href="#" className="text-reset">Text</a></h6>
                      <span className="small">Description</span>
                    </div>
                  </div>
                </td>
                <td>1</td>
                <td className="text-end">$79.99</td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <img src="https://via.placeholder.com/280x280/FF69B4/000000" alt="" width="35" className="img-fluid" />
                    </div>
                    <div className="flex-lg-grow-1 ms-3">
                      <h6 className="small mb-0"><a href="#" className="text-reset">Translation</a></h6>
                      <span className="small">Description</span>
                    </div>
                  </div>
                </td>
                <td>1</td>
                <td className="text-end">$79.99</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">Subtotal</td>
                <td className="text-end">$159,98</td>
              </tr>
              <tr>
                <td colspan="2">Shipping</td>
                <td className="text-end">$20.00</td>
              </tr>
              <tr>
                <td colspan="2">Discount (Code: NEWYEAR)</td>
                <td className="text-danger text-end">-$10.00</td>
              </tr>
              <tr className="fw-bold">
                <td colspan="2">TOTAL</td>
                <td className="text-end">$169,98</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div> 
      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="h6">Payment Method</h3>
              <p>Online <br />
              Total: $169,98 <span className="badge bg-success rounded-pill">PAID</span></p>
            </div>
            <div className="col-lg-6">
              <h3 className="h6">Billing address</h3>
              <address>
                <strong>John Doe</strong><br />
                1355 Market St, Suite 900<br />
                San Francisco, CA 94103<br />
                <abbr title="Phone">P:</abbr> (123) 456-7890
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4">
      
      <div className="card mb-4">
        <div className="card-body">
           
        <div className="comment-wrapper">
            <div className="panel panel-info">
                <div className="panel-heading">
                    Comment panel
                </div>
                <div className="panel-body">
                    <textarea className="form-control" placeholder="write a comment..." rows="3"></textarea>
                    <br />
                    <button type="button" className="btn btn-primary pull-right">Post</button>
                    <div className="clearfix"></div>
                    <hr />
                    <ul className="media-list">
                        <li className="media">
                            <a href="#" className="pull-left">
                                <img src="https://bootdey.com/img/Content/user_1.jpg" alt="" className="img-circle" />
                            </a>
                            <div className="media-body">
                                <span className="text-muted pull-right">
                                    <small className="text-muted">30 min ago</small>
                                </span>
                                <strong className="text-success"></strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Lorem ipsum dolor sit amet, 
                                </p>
                            </div>
                        </li>
                        <li className="media">
                            <a href="#" className="pull-left">
                                <img src="https://bootdey.com/img/Content/user_2.jpg" alt="" className="img-circle" />
                            </a>
                            <div className="media-body">
                                <span className="text-muted pull-right">
                                    <small className="text-muted">30 min ago</small>
                                </span>
                                <strong className="text-success"></strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Lorem ipsum dolor adipiscing elit.
                                </p>
                            </div>
                        </li>
                        <li className="media">
                            <a href="#" className="pull-left">
                                <img src="/assets/images/loader.png" alt="" className="img-circle" />
                            </a>
                            <div className="media-body">
                                <span className="text-muted pull-right">
                                    <small className="text-muted">30 min ago</small>
                                </span>
                                <strong className="text-success"></strong>
                                <p>
                                    Lorem ipsum dolor  sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        </div>
      </div>
    </div>
  </div>

          </div>
        </div>
    
      </div>
    
        <Footer></Footer>

    </div>
  </main>
       


        
    </div>
}

export default OrderDetail

