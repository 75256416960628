import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BlogPostv2 = () => {

    

        let publicUrl = process.env.REACT_APP_PUBLIC_URL+'/'
        let imagealt = 'image'
		let offset = 0;
		let limit = 3;
		let apiURl = 'https://www.textmercato.com/cms/api/blogs?populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
		const [blogs, setblogs] = useState([]);

		useEffect(async ()  =>  {			
			await axios
			.get(apiURl)
			.then(({ data }) => setblogs(data));		
		}, [])

    return <section className="blog-two blog-two__home-three white-bg  go-top">
			  <div className="container">
			    <div className="blog-two_top">
			      <div className="block-title-two text-left">
			        <h3>All Resources For Your Content Journey</h3>
			      </div>
			      <div className="blog-two__top-btn-block">
			        <Link to="/blog" className="thm-btn blog-two__top-btn">View All <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn blog-two__top-btn */}
			      </div>
			    </div>
			    <div className="row">
			    
				
				{blogs.data ? (
                    
                    blogs.data.map((post, index) => (

				  <div className="col-lg-4">
			        <div className="blog-two__single">
			          <div className="blog-two__image">
						
					  		<Link to={"/blog/"+post.attributes.Slug} className="blog-one__image">
								<img src={ post.attributes.Featured.data !== null ? ("https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url) : '' } alt={ post.attributes.Title } />
							</Link>
			          </div>
			          <div className="blog-two__content mt-0">
			            
			            <h3><Link to={"/blog/"+post.attributes.Slug}>{ post.attributes.Title  }</Link></h3>
						<p>{ post.attributes.Content.substring(0, 150)  }...</p>
			            <Link to={"/blog/"+post.attributes.Slug} className="thm-btn blog-two__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			          </div>
			        </div>
			      </div>
					))) : <div className="loader content_preloader">Loading...</div>
				}

			      
			      
			    </div>
			  </div>
			</section>


}

export default BlogPostv2