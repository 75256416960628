import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogGridView from './blog-components/blog-grid';
import Footer from './global-components/footer-v2';
import { Link } from 'react-router-dom';

const Resources = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

	const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    
    let imagealt = 'image'

    return <div>
        <Navbar />
        <PageHeader headertitle="Resources"  />
        
        
        <section className="blog-grid resources go-top">
			  <div className="container">
			    <div className="row">
		 
 

                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog">Blog</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}

                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">FAQs</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}


                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">Videos</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}


                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">Glossaries</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}



                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog">Ebook</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}

                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">Whitepapper</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}


                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">Podcast</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}


                    <div className="col-lg-3 col-md-6">
                        <div className="blog-one__single">
                        <Link to="/blog" className="blog-one__image">
                            <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt={imagealt} />
                        </Link>{/* /.blog-one__image */}
                        <div className="blog-one__content">
                            <h3><Link to="/blog-details">Graphics</Link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiusmod tempor incididunt ut labore et dolore magna.</p>
                            {/* /.thm-btn */}
                        </div>{/* /.blog-one__content */}
                        </div>{/* /.blog-one__single */}
                    </div>{/* /.col-lg-4 col-md-6 */}


				 





      
			      
			    </div>{/* /.row */}
			    
			  </div>{/* /.container */}
			</section>



        <Footer />
    </div>
}

export default Resources

