import React, { useEffect, useState  } from 'react';

import { readRemoteFile  } from 'react-papaparse';

 

const UploadCsv = () => {
    let csvFile = "sample-bulk.csv";

    const [data, setData] = useState(null);

  
    useEffect(() => {
      readRemoteFile(csvFile,
        {
          complete: (results) => {
            setData(results);
            let count = 0;

             
          },
        }
      );
    }, []);

  
    return (
    	
      <table className="table borderless bulkorderReview">
                <thead>
                    <tr>
                        <td><strong>Title </strong></td>
                        <td><strong>Key Points </strong></td>
                        <td className="text-left"><strong>Keywords</strong></td>
                        <td className="text-center"><strong>No. of words</strong></td>
                        <td className="text-left"><strong>References</strong></td>
                        <td className="text-left"><strong>Level</strong></td>
                        <td className="text-left"><strong>Remarks</strong></td>
                        
                    </tr>
                </thead>
                <tbody>
        {data ? (
                    
                    data.data.map((parsedData, index) => ( 
                      index > 0 ? (
                      <><tr>
                        <td className="col-md-3">
                          <div className="media">
                            <a className="thumbnail pull-left" href="#">  </a>
                            <div className="media-body">
                              <h6 className="media-heading"> { parsedData[1] }</h6>

                            </div>
                          </div>
                        </td>

                        <td className="">{ parsedData[2] }</td>
                        <td className="text-left">{ parsedData[3] }</td>
                        <td className="text-center">{ parsedData[4] }</td>
                        <td className="text-center">{ parsedData[5] }</td>
                        <td className="text-left">{ parsedData[6] }</td>
                        <td className="text-left">{ parsedData[7] }</td>

                      </tr>
                        
                        
                        </>
                   
                    ) : ""
                    ))) : ( <div className="loader">Loading...</div>  )  }

</tbody>
                                            </table> 
      
    );
  };

 

export default UploadCsv