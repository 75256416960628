import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


const FaqV3 = (props) => {

  let publicUrl = process.env.PUBLIC_URL+'/'
  let limit = 24;
	let offset = 1;
	let PaginationPath = "faqs/page/";
	let { page } = useParams();
	
	const location = useLocation();
	if(page === undefined){ page = 1 }

	if(page > 1){ offset = (page - 1) * limit; }

	let total = 0;
  const li = [];
      

      const [faqs, setFaqs] =  useState('');
      const [error, setError] =  useState('');
      const [randomNumber, setRandomNumber] = useState(0)

      let url = 'https://www.textmercato.com/cms/api/faqs?pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
      if(props.limit){

        const sort = ["Question", "createdAt", "Answer"];
        const order = ["DESC", "ASC"]
        const generateRandomNumber = (array) => {
            const randomNumber = Math.floor(Math.random() * array.length);
            return array[randomNumber];
        }

        url = "https://www.textmercato.com/cms/api/faqs?pagination[start]=1&pagination[limit]="+props.limit+"&sort="+generateRandomNumber(sort)+":"+generateRandomNumber(order)+"";
      }
      useEffect(() => {
        axios
        .get(url)
        .then((data) => setFaqs(data))
        .catch((error) => setError(error));
    
      }, []);

      if(faqs.data){ console.log(faqs.data.meta);
        let totalPosts = faqs.data.meta.pagination.total;
        if(totalPosts >= 24){
          total = Math.round(totalPosts / limit);
        }else if(totalPosts > 12 && totalPosts < 24 ){
          total = 2;
        }else{
          total = 1;
        }
        
        for(let i = 1; i <= total; i++){
          li.push(i);
        }
      }


      
      

    return  <section className="faq-one faq-one__faq-page faqListing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-one__block">
                
                  <div className="accrodion-grp faqsLink" data-grp-name="faq-one-accrodion">

                  {typeof faqs === 'object' && Object.keys(faqs).length > 0 ? (
					          faqs.data.data.map((post, index) => (
                    <a href={"/faq/"+post.attributes.Slug} className={ index == 0 ? 'accrodion ' : 'accrodion ' }>
                      <div className="accrodion-title">
                        <h4>{ post.attributes.Question }</h4>
                      </div>
                    </a>
                    ))
                    ) : ( <div className="loader content_preloader">Loading...</div>  )
                  }


                    
                  </div>
                </div>{/* /.faq-one__block */}
                 
              </div>{/* /.col-lg-8 */}
             
                 
             
            </div>{/* /.row */}
          { !props.limit ? (
              <div className="post-pagination mt-5">
              { faqs.data &&  page > 1 ? (
              <a href={publicUrl+PaginationPath+(page - 1)} ><i className="fa fa-angle-left" />{ }</a>
              ) : '' }
              {  li.length > 0 ? (
                
                li.map((parsedData, index) => (						
                  <a className={ parsedData == page ? 'active' : '' } href={publicUrl+PaginationPath+ (parsedData) }>{ parsedData }</a>
                ))
                
              )	: ""
              } 


              {parseInt(page) < total ? (
                <a href={publicUrl+PaginationPath+(parseInt(page) + 1 )} ><i className="fa fa-angle-right" /></a>
              ) : '' }
              </div>
          ) : '' }
            

            
          </div>{/* /.container */}
        </section>


}

export default FaqV3