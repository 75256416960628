import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';

import Sample from './section-components/sample';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const Samples = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>

        <Helmet>
            <title>Samples
</title>
            <meta name="10000 plus content writers, global clientele, digital marketing, and more. Our strategists and experts will manage it successfully." />
        </Helmet>

        <Navbar />
        <section className="banner-two pb-0 go-top banner-inner">
            <div className="container">
            <div className="row">
                <div className="col-xl-5 my-auto">
                <div className="banner-two__content">
				<h1> <span>Ready to Refer </span>Premium Content Samples <span>for Your Industry</span></h1>

                    <p>Check out our curated content sample repository for Blogs, Website content, SEO Articles, Product Descriptions, Translations, and much more. These are the actual copies of content delivered to our clients. You can click and explore premium content samples industry-wise or according to content type based on your business needs.  </p>

                    <p>Choose TextMercato premium content writing services for content that clicks.</p>
                    <a href="#samples" className="thm-btn banner-two__btn btn-big">Download Samples <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-7 my-auto'>
                <img width="85%" className="about-four__moc "   src={publicUrl+"assets/samples.png"} alt="Premium Content Samples" />
            </div>


            </div>
            </div>
        </section>
        <div id='samples'></div>
        <Sample></Sample>
        
		

		
        <Calltoaction />
        <Footer />
    </div>
}

export default Samples

