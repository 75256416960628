import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-style-four';

import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';

import Calltoaction from './section-components/cta';

import { Link } from 'react-router-dom';

const digital_content_platform = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Professional Content Management Services | Text Mercato</title>
            <meta name="10000+ approved content writers make it easy for you to scale your content needs. All you need to do is tell us what you want." />
        </Helmet>

        <Navbar />
        <Banner />
        <section className="service-three white-bg pt-0  go-top">
		  <div className="container">

		  <div className="row ">
		  <div className="col-md-8 offset-2 ">
		    <div className="block-title-two text-center mb-0">
				<p className='mt-5 mb-0'>Why Work With Us?</p>
		      <h3>Industry related experienced content creators on demand

</h3>
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>Creation of content is about connecting and adding value to your users. So, we focus on knowing what your users want (SEO, User Research), understanding their behavior (analytics) and most importantly delivering the right content, at the right time, at the right place.</p>
		</div>
		</div>

		    <div className="row mt-5">
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-pen-clip" />
		          <h3><a className='scroll-to-target' data-target="#digital-content" href='#'  >Digital Content Platform</a></h3>
		          <p>Manage all your content on our all-in-one-platform. Place your orders, track progress, and manage the project with the support of a stellar customer service team.</p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-invoice" />
		          <h3><a   className='scroll-to-target' data-target="#managed-accounts"  href='#'>Managed Accounts</a></h3>
		          <p>Have large needs or need someone to help? We have you covered. Just write to us, and our Account Managers will get back to you.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-folder-tree" />
		          <h3><a  className='scroll-to-target' data-target="#seo-intelligence"  href='#'>SEO Intelligence</a></h3>
		          <p>TextMercato can help you choose the right trends, keywords, topics, and  customized guidelines to meet your content  goals.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}


              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-pen" />
		          <h3><a  className='scroll-to-target' data-target="#ai-copy-writer"  href='#'>AI Copywriter</a></h3>
		          <p>Need something quick, good, and publish ready? Our AI copywriter is at your disposal.</p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-globe" />
		          <h3><a  className='scroll-to-target' data-target="#publishing"  href='#'>Publishing</a></h3>
		          <p>Not only can we help create content, but also help you publish. (Coming soon - WordPress Integration)</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-chart-line" />
		          <h3><a  className='scroll-to-target' data-target="#analytics"  href='#'>Analytics</a></h3>
		          <p>Our ability to share data, discuss the outcomes, and improvise for better results makes us an bankable partner.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}

		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



        <section id='digital-content' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Digital Content Platform </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>When managing projects (small or large) across different types of content, manual process can slow down your entire content creation journey and compromise your goals. </p>

					<p className='mt-3 lineheight-normal'>At TextMercato, we give you access to our Digital Content Platform. It enables you to scale, control, and track all your projects on  a single dashboard. It further allows you to communicate with us for varied requirements and genres. More importantly, an integrated platform with an end-to-end solution, management of the projects, share ratings,  and even make your payments to give you a seamless experience.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Order Online</h3><p>Choose from a variety of projects and get going in less than 5 minutes </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Dashboard View</h3><p>Track your order in real-time and provide feedback </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Approval Management</h3><p>Provide feedback and keep control 100 percent </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Single Invoice</h3><p>Multiple projects can be managed and paid together </p></div></div>


					</div>{/* /.about-four__content */}
                    
				<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/Digital-Content-Platform.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<section id='managed-accounts' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Managed-Accounts.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto ">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Managed Accounts  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>Large projects need a Single Point of Contact (SPOC) or an Account Manager so that you have someone to get things delivered on-time, as per your brief.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, our ‘Managed Accounts’ section gives you access to a SPOC / Account Manager who is responsible for an end-to-end execution of your project. Complete management of the delivery quality and timelines lies with us, so  you can focus on your core business.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Your Own SPOC</h3><p>A Single Point of Contact for all your content requirements  </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Frequent Updates</h3><p>Constant communication regarding your project progress </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Project Management</h3><p>End-to-end delivery management by the SPOC </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Hassle-free experience</h3><p>We manage your content while you focus on your core business goals </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
					<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='seo-intelligence' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>SEO Intelligence  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>Excelling at anything is not just about a seamless execution, but also supported by a well-researched and tailored strategy.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, our SEO team will conduct an in-depth audit between your current situation and your desired goals. This will help understand the gaps, and devise a step-by-step course of action with defined timelines.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Your Goals</h3><p>What are your priorities in the next three, six, nine months, or end of year?	 </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Audit </h3><p>Knowing where you are today helps us understand how to chalk the future path ahead.</p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Project Management</h3><p>End-to-end delivery management by the SPOC. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Proposal</h3><p>This is a document containing all the metrics driven action items. </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
					<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/SEO-Intelligence.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='ai-copy-writer' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/AI-Copy-Writer.png"} className="about-four__moc image-shadow" alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto  ">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>AI Copywriter  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>Do you need content quickly and at scale? Then, our AI writer maybe the solution you are looking for. </p>

					<p className='mt-3 lineheight-normal'>At TextMercato, our proprietary ‘Language Generation Technology’ can help address your content needs efficiently.  This helps you scale on numbers, while prioritizing your SEO needs and requirements.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Scale Quickly</h3><p>Do you have 1000’s of descriptions to be delivered quickly? We are ready! </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Quality Management</h3><p>A layer of quality management ensures 100 percent adherence to brief and brand guidelines.</p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Super-efficient</h3><p>10X faster, 5X cost-efficient. Your needs can be handled quickly and efficiently. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>SEO and User Optimized</h3><p>Not only about efficiency but also value-add to the users is addressed.</p></div></div>


					</div>{/* /.about-four__content */}
                    
					<p className="mt-3 lhn" >The content is SEO driven to add value to user experience and bring up the right search</p>
                    
					<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='publishing' className="about-four   go-top">
		  <div className="container">
		    <div className="row">

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Publishing  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>The key to success campaigns is not only about producing content, but putting it up at the right time to reach the right audience on the right platform.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, be it third party websites or your own webpage, social media handles or guest posts, our dedicated ‘Publishing Team’ can push the content quickly, so you are able to meet your customer leads, engagement, or other goals. </p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Keep Focus</h3><p>You keep focus on your core achievables while we get you ready to scale. </p></div></div>
							<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Project Management</h3><p>End-to-end project management responsibility lies with TextMercato. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>One-stop-solution</h3><p>One place for all your content requirements. A winning combination is about doing it all efficiently and meeting your expected goals. </p></div></div>
						
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Integration</h3><p>Publish directly on your WordPress site<br></br> (Coming Soon!)  </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
					<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		    </div>{/* /.col-lg-6 */}


			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Publishing.png"} className="about-four__moc image-shadow " alt={ imagealt } />
			</div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



	  


		<section id='analytics' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto  ">
				<img src={publicUrl+"assets/analytics.png"} className="about-four__moc image-shadow" alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto ">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
				  <h3>Analytics</h3>   
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>In God we Trust - All Others Bring Data</p>

					<p className='mt-3 lineheight-normal'>Quantitative analysis (supported by qualitative) is at the heart of our decision making. At TextMercato, we do two things efficiently</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Based on market data audit and industry benchmarking, we can suggest how to move the needle</h3></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content"><h3>Based on our experience, we can suggest what strategies work best and how to get results quickly</h3></div></div>
						


					</div>{/* /.about-four__content */}
                    
                    
					<Link to="/get-started" className="thm-btn cta-one__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default digital_content_platform

