import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut } from '../../libs/auth/authSlice';
import { useHistory } from 'react-router-dom';


const NavbarV2 = () => {

	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleOnClick = e => {
        e.preventDefault();
            // setLoading(true);
			console.log("Logout");
            dispatch(logOut())
			window.location.reload(false);
        }
     
        let publicUrl = process.env.REACT_APP_PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
           <header className="site-header-two site-header-two__ go-top  go-top">
			  <nav className="main-nav__two stricky">
			    <div className="container">
			      <div className="main-nav__logo-box">
			        <Link to="/">
			          <img width="200" src={publicUrl+"assets/images/logo-2-1.png"} alt={ imgattr }/>
			        </Link>
			      </div>{/* /.main-nav__logo-box */}
			      <div className="main-nav__main-navigation">
			        <ul className=" main-nav__navigation-box">
			          <li >
			            <Link to="/">Home</Link>
			          </li>
					  <li className="dropdown">
			            <Link to="/product">Product</Link>
			            <ul>
			              <li><Link to="/product#digital-content" data-target="#digital-content">Digital Content Platform</Link></li>
			              <li><Link to="/product#managed-accounts" data-target="#managed-accounts">Managed Accounts</Link></li>
			              <li><Link to="/product#seo-intelligence" data-target="#seo-intelligence">SEO Intelligence</Link></li>
						  <li><Link to="/product#ai-copy-writer" data-target="#ai-copy-writer">AI Copy Writer</Link></li>
			              <li><Link to="/product#publishing" data-target="#publishing">Publishing</Link></li>
			              <li><Link to="/product#analytics" data-target="#analytics">Analytics</Link></li>
			            </ul>
			          </li>
					  
					  <li className="dropdown">
					  	<Link to="/platform">Platform</Link>
			            <ul>
			              <li><Link to="/platform#strategy" data-target="#strategy">Strategy</Link></li>
			              <li><Link to="/platform#delivery" data-target="#delivery">Delivery</Link></li>
			              <li><Link to="/platform#performance" data-target="#performance">Performance</Link></li>
			            </ul>{/* /.sub-menu */}
			          </li>
					  <li className="dropdown">
			            <Link to="/services">Services</Link>
			            <ul>
			              <li><Link to="/services/text-content"  >Text Content</Link></li>
						  <li><Link to="/services/graphics" >Graphics</Link></li>
						  <li><Link to="/services/translations" >Translations</Link></li>
			              <li><Link to="/services#video"  data-target="#video">Video</Link></li>
						  <li><Link to="/services#audio" data-target="#audio">Audio</Link></li>
			              <li><Link to="/services#transcriptions" data-target="#transcriptions">Transcriptions</Link></li>
			              
			            </ul>{/* /.sub-menu */}
			          </li>
					
					  <li className="dropdown">
			            <a href="#">Resources</a>
			            <ul>
						  <li><Link to="/samples" >Content Samples</Link></li>
			              <li><Link to="/blog" >Blog</Link></li>
						  <li><Link to="/glossary">Glossary</Link></li>
						  <li> <Link to="/faqs">FAQs</Link> </li>
			            </ul>
			          </li> 
					  

					  {/* <li className="dropdown">
			            <a href="#">Resources</a>
			            <ul>
			              <li><Link to="/">Blogs</Link></li>
			              <li><Link to="/">Success Stories</Link></li>
			              <li><Link to="/">Glossaries</Link></li>
						  <li> <Link to="/">Q&A</Link> </li>
			            </ul>
			          </li>   */}
			         
					  <li>
			            <Link onClick={() => {window.location.href="/freelance"} } to="/freelance">Freelance</Link>
			          </li>
					  
			        </ul>
			      </div>{/* /.main-nav__main-navigation */}

				  <div className="main-nav__right">
			        <Link onClick={() => {window.location.href="/get-started"} } to="/get-started" className="thm-btn">Get Started <i className="fa fa-angle-right" /></Link>
					
					{
						(user.token !== null) ? <Link className='signin-page' onClick={ (e) => { handleOnClick(e) } } >Sign out</Link> : <Link className='signin-page' to="/login">Sign in</Link>
					}
			        
			          
			        {/* /.thm-btn */}
			      </div>{/* /.main-nav__right */}
			      
				 
				  <div className="main-nav__right">
			        <a href="#" className="side-menu__toggler"><span /></a>
			      </div>
				
			    </div>{/* /.container */}
			  </nav>{/* /.main-nav__one */}
			</header>



        )
    
}


export default NavbarV2