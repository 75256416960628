import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';

import Helmet from 'react-helmet';

const passwordResetPage = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/login.js";
    document.body.appendChild(minscript);



    return <div>

        <Helmet>
            <title>Forgot Password</title>
            <meta name="" />
        </Helmet>

        <Navbar ></Navbar>
        
        <section className='login-section snippet-body'>
            <div className="container">
                <div className="card">
                    <div className="form">


                        <div className="left-side">
                       
                        
                       <img width='100%' src={publicUrl+"assets/Text-Mercato-Sign-up.png"} alt={ imagealt } />
   
                       <div className={"testimonials-two__carousel  owl-carousel thm__owl-carousel owl-theme"} data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 1, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 1 }, &quot;1200&quot;: { &quot;items&quot;: 1 } }}">
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                       <img src={publicUrl+"assets/logos/urban.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                       <h3>Shikha  </h3>
                       <span>Urban Ladder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Great Team Support"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
   
   
   
   
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/frontfold.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                               <h3>Nandan S Kedlaya  </h3>
                       <span>Founder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"High Quality Content"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
   
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/nilkamal.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                           <h3>Siddharth Pamoor </h3>
                       <span>Sr.SEO Executive</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Highly professional"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
                               
                               
   
                           </div>
                           
                           
   
                           
                    </div>
                    
                        <div className="right-side darg-bg ">
                            <div className="main active">
                                
                                <div className="text mb-4 mt-0 ">
                                <h3 className='color-blue'>Password Reset Screen</h3>
                                
                                   
                                </div>
                                <div className="input-text">
                                <div className="input-div">
                                        <input type="hidden" name='email' data-message="Enter a valid email" id='email'  />
                                        
                                    </div>
                                </div>
                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Enter New Password</label>
                                        <input type="password" data-message="Enter a valid password" name='password' required  />
                                  <a className='showpassword' href='javascript:void(0)'><i className='fa fa-eye'></i></a>
                                    </div>
                                </div>

                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Reconfirm New Password</label>
                                        <input type="password" data-message="Enter a valid password" name='confirm_password' required  />
                                  <a className='showpassword' href='javascript:void(0)'><i className='fa fa-eye'></i></a>
                                    </div>
                                </div>

                                <div className="buttons ">
                                
                                    <button className="submit_button thm-btn">Submit</button>
                                    
     
                                </div>
                            </div>
                            <div className="main">
                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                </svg>
                                
                                <div className="text congrats">
                                    <h2>Congratulations!</h2>
                                    <p>Thanks your information have been submitted successfully.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
}

export default passwordResetPage

