import React from 'react';
import Navbar from './global-components/navbar-v2';

import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';

import Calltoaction from './section-components/cta';

import { Link } from 'react-router-dom';

const services  = () => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div id='home' className="homepage">

		<Helmet>
            <title>Strategy, Seamless Delivery, and a Stellar Project Management | Text Mercato</title>
            <meta name="Strategize your content calendar with TextMercato. Publish-ready content customized to your needs with inputs from content experts." />
        </Helmet>

        <Navbar />
        <section className="banner-two pb-0 go-top banner-inner">
            <div className="container">
            <div className="row">
                <div className="col-xl-5 my-auto">
                <div className="banner-two__content">
				<h1><span>How about a well-researched</span> strategy, seamless delivery, and a stellar project  management?</h1>
                   
                    <p>At TextMercato, we believe success is not just about creating an amazing piece of content. Its about content strategy, timely delivery, and the project management behind it. </p>
                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-7 my-auto'>
                <img width="85%" className="about-four__moc "   src={publicUrl+"assets/platform-1.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>


      

        <section className="funfact-one funfact-one__home-two pt-0 funfact-one__about-page steps-to-scale">
			  <div className="container">


			<div className='row'>
				<div className='col-md-8 offset-2'>
					<div className="block-title-two text-center mb-5">
						
						<h3>How Can we Help Contribute?</h3>   
					</div>{/* /.block-title-two text-center */}
					<p className='mt-3 text-center'>TextMercato is managed by a team of professionals with varied skillsets. Be it strategy, content delivery, or managing projects, we have got you covered. The holistic approach helps in better execution.</p>
					</div> 
			</div> 
            


			    <div className="row high-gutter mt-5 mb-5">
			      <div className="col-lg-4 col-md-6">
			        <a data-target="#strategy" href='#'  className="funfact-one__single scroll-to-target">
			          <div className="funfact-one__icon">
			            <i className="far fa-chess" />
			          </div>{/* /.funfact-one__icon */}
			          <h3>Strategy</h3>
			          <p>Understand and create content strategy</p>
			        </a>{/* /.funfact-one__single */}
			      </div>{/* /.col-lg-3 col-md-6 */}
			      <div className="col-lg-4 col-md-6">
				  <a data-target="#delivery" href='#' className="funfact-one__single scroll-to-target">
			          <div className="funfact-one__icon">
			            <i className="fal fa-hourglass" />
			          </div>{/* /.funfact-one__icon */}
			          <h3>Delivery</h3>
			          <p>Deliver publish ready content</p>
			        </a>{/* /.funfact-one__single */}
			      </div>{/* /.col-lg-3 col-md-6 */}
			      <div className="col-lg-4 col-md-6">
				  <a data-target="#performance" href='#' className="funfact-one__single scroll-to-target">
			          <div className="funfact-one__icon">
			            <i className="far fa-gauge-high" />
			          </div>{/* /.funfact-one__icon */}
			          <h3>Performance</h3>
			          <p>Continuous monitoring and improvement</p>
			        </a>{/* /.funfact-one__single */}
			      </div>{/* /.col-lg-3 col-md-6 */}
			      
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>


		<section id='strategy' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Strategy </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>“Without goals, and plans to reach them, you are like a ship that has set sail with no destination.”<br></br> — Fitzhugh Dodson</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, we always stress on where you are today and where you want to be in the given timeline. The current status is a perfect platform to understand the future direction, scale, and strategize.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Alignment</h3><p>Get your strategy and goals under one umbrella </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Metrics driven</h3><p>Is it about engagement scores, traffic, or leads? Let’s measure! </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Customized to your goals</h3><p>Choose the right channel for the right reason, there is no one-size-fits-all solution. </p></div></div>
						
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Expertise</h3><p>Your dedicated digital strategist is someone who understands your business. </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/Strategy.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-6 my-auto">
				<img src={publicUrl+"assets/Delivery.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Delivery  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>“Your efficiency lies in the seamless execution”, and this is what we do powered by strong processes, tech assistance, and human judgement. </p>

					<p className='mt-3 lineheight-normal'>At TextMercato, we have Account Managers, Category Managers, and Content Experts who are responsible to manage on-time delivery,  quality expectations, and the adherence to the brief. The platform is aided with ‘Tech Check’ and ‘Quality Management’ tools to ensure the desired outcome.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>A robust workflow</h3><p>The entire process of production is managed and tracked till delivered and approved. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Picking the best</h3><p>With over 10,000+ writers, we choose the once that best suits your requirements for quality and scale. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Quality management</h3><p>Workflow adhered, tech assisted, and edited to suite your requirements. The best of all worlds!  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Review management</h3><p>Review and approve, share feedback for any changes. The flexibility to manage is yours. </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='performance' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Performance and Distribution<br></br> (coming soon!)   </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>The proof of the pudding is in eating it! All your strategy works only if you have been achieving the goals you set for yourself. </p>

					<p className='mt-3 lineheight-normal'>At TextMercato, there is a plan, there is a delivery, and post that a review on how close you are to the goals you set. This process is of continuous improvement and keeps going till your ROI (Return on Investment), CAC (Customer Acquisition Costs), CPL (Cost per Lead), are achieved.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Data based reporting</h3><p>Are we meeting the targets you set for us? Are your priorities addressed using clean and clear metrics? </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Distribution support</h3><p>How best can we get you into the ecosystem of Ads, Bloggers etc,. because time is finite. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Review discussion</h3><p>How far are you from the goals? What steps need to be taken to pace it up?   </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Re-strategize</h3><p>A continuous process, hold what is good and change what needs to improve. </p></div></div>


					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/Performance.png"} className="about-four__moc  image-shadow" alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>
        
        


          





<section className="brand-one brand-one__home-two  ">
			<div className="container pt-5">
				<div className="block-title-two text-center pt-5">
				<h3 className="font-30">1000+ Businesses Trust TextMercato for Their Content Needs</h3>
				</div>{/* /.block-title-two */}
				<div className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel" data-options="{&quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 5000, &quot;items&quot;: 6, &quot;margin&quot;: 50, &quot;smartSpeed&quot;: 700, &quot;nav&quot;: false, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 2, &quot;margin&quot;: 15 },&quot;575&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 },&quot;767&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 }, &quot;991&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 30 }, &quot;1199&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 75 }, &quot;1200&quot;: { &quot;items&quot;: 6 } }}">
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-1.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-2.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-3.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-4.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-5.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-6.png"} alt="" />
				</div>{/* /.item */}
				
				</div>{/* /.brand-one__carousel owl-carousel owl-theme thm__owl-carousel */}
			</div>{/* /.container */}
		</section>




        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default services 

