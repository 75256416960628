import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';


import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const PostProject = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'


    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                        
                    
                        
                        
                      
                        <div className="main active">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            
                            <div className="text congrats">
                                <h3 className='color-blue'>Payment Successful</h3>
                                <p> Your order is now confirmed </p>
                                <Link className='thm-btn inline' to='/'>Dashboard</Link>
                                <Link className='thm-btn' to='/'>Order More</Link>
                                <Link className='thm-btn' to='/'>Download Invoice</Link>
                            </div>
                        </div>
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="title" required require />
                                    <span>Enter Title</span>
                                </div>
                                
                            </div>


                            <div className="input-text" data-custom="word_count_custom_modal" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Enter Word Count</h5>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>700</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>1500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2000</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div  className="input-text  customBox">
                                <div id='word_count_custom_modal' className="input-div hidden">
                                    <input type="tel" name="custom_count"  />
                                    <span>Enter Word Count </span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="keywords" required require />
                                    <span>Please enter your keywords or say "Add Keywords" if you want us to </span>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Enter Keyword density, internal linking requirement or others </span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="target_audience" required require />
                                    <span>Tell us about your readers </span>
                                    
                                </div>
                            </div>

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>

    </div>
}

export default PostProject

