import React from 'react';
import Navbar from './global-components/navbar-v2';

import Team from './section-components/team-v2';
import Footer from './global-components/footer-v2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Calltoaction from './section-components/cta';

const AboutUs = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
        <Helmet>
            <title>About Us | Text Mercato</title>
            <meta name="About TextMercato" />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3><span>Abouut </span>TextMercato <span></span></h3>
                    <p>The graphic content requirement for every website is different. The expert team of TextMercato can quickly understand the possibilities and implement artistic changes. Let the experts assist you with professional design services for infographics, ebook designs, images, and more to communicate visually with your target audience.</p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <object>
                    <embed src="hero.svg" />
                </object>
            </div>


            </div>
            </div>
        </section> 
      


        <Team></Team>


        <section className="about-three about-three__about-page  go-top">
			  <div className="container">
              <div className="block-title-two text-center">
		      
		      <h3>Investors </h3>
		    </div>{/* /.block-title */}
			    <div className="row image-grid">
			      
                
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-1.png"}  />
                    </div>{/* /.item */}
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-2.png"}  />
                    </div>{/* /.item */}
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-3.png"}  />
                    </div>{/* /.item */}
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-4.png"}  />
                    </div>{/* /.item */}
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-5.png"} />
                    </div>{/* /.item */}
                    <div className="item col-2">
                        <img src={publicUrl+"assets/images/brand/brand-1-6.png"}  />
                    </div>
                    
                    

             
            


			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

      
        <Calltoaction></Calltoaction>
        <Footer />
    </div>
}

export default AboutUs

