import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV3 extends Component {
	 componentDidMount() {
	 	let publicUrl = process.env.PUBLIC_URL+'/'
        const cssUrl =   publicUrl + "assets/css/color-2.css";
        this.addStyle(cssUrl);

        const $ = window.$;
        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;

        document.head.appendChild(style);


    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-two  go-top">
			  {/* <img src={publicUrl+"assets/images/shapes/banner-shape-1-1.png"} alt="Banner" className="banner-two__moc" /> */}
			 
			  <div className="container banner-three-container" >
			    <div className="row">
			      <div className="col-xl-12">
			        <div className="banner-two__content text-center">
			          <h3>Discover New Idea To  Build <br /> Content <em> Online</em></h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do  eiusmod tempor incididunt <br />
			            ut labore et dolore magna</p>

                  <Link to="/" className="thm-btn banner-two__btn">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
			          
			        </div>{/* /.banner-two__content */}
			      </div>{/* /.col-xl-7 */}
			    </div>{/* /.row */}


          <div className='service-three no-bg'>
          <div className="row mt-5">
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-star" />
		          <h3><Link to="/service-details">Strategy</Link></h3>
		          <p>Lorem ipsum dolor sit amet consectetur adipisicing eli sed eiusmod tempor incidid</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-umbrella" />
		          <h3><Link to="/service-details">Delivery</Link></h3>
		          <p>Lorem ipsum dolor sit amet consectetur adipisicing eli sed eiusmod tempor incidid</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-laptop-code" />
		          <h3><Link to="/service-details">Performance</Link></h3>
		          <p>Lorem ipsum dolor sit amet consectetur adipisicing eli sed eiusmod tempor incidid</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
		    </div>{/* /.row */}
        </div>

			  </div>{/* /.container */}
			</section>

        }
}

export default BannerV3