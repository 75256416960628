import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV10 extends Component {

    render() {

    let publicUrl = process.env.REACT_APP_PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-two service-two__service-page  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5">
              <div className="block-title-two text-left">
		            <p>Our Services</p>
		            <h3>Who is it for?</h3>
		          </div>{/* /.block-title-two */}
		        <img width="160%"  src={publicUrl+"assets/images/homepage/1-Copy.png"}  />
		      </div>{/* /.col-lg-5 */}
		      <div className="col-lg-7">
		        <div className="service-two__box-wrap">
		          <div className="row">
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url('+publicUrl+'assets/images/blog/blog-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-building" />
		                  <h3><Link to="/get-started">Enterprises </Link></h3>
		                  <p>To create high-quality content at scale, so you can meet your customer acquisition, engagement, and retention goals.</p>
		                  <Link to="/get-started" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url('+publicUrl+'assets/images/blog/blog-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-bullhorn" />
		                  <h3><Link to="/get-started">Digital Marketers / Strategists </Link></h3>
		                  <p>Your customers may have varied content needs, and you need professional teams to create it for you.</p>
		                  <Link to="/get-started" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url('+publicUrl+'assets/images/blog/blog-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-users" />
		                  <h3><Link to="/get-started">Founders </Link></h3>
		                  <p>Whether you are a Startup  or an established Enterprise, a Soloprenuer or MSME, a Unicorn or a Multinational,  your content needs come in various shapes and sizes. Get it all from us while keeping your budgets in control and target audience happier.</p>
		                  <Link to="/get-started" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image" style={{backgroundImage: 'url('+publicUrl+'assets/images/blog/blog-1-1.jpg)'}} />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-building-columns" />
		                  <h3><Link to="/get-started">Agencies </Link></h3>
		                  <p>Think of us as your ‘Digital Content Creation’ partners while you focus on your core needs like strategy and execution.</p>
		                  <Link to="/get-started" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		          </div>{/* /.row */}
		        </div>{/* /.service-two__box-wrap */}
		      </div>{/* /.col-lg-7 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default ServiceV10