import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import PageHeader from './page-header';

const BlogGrid = () => {


	let publicUrl = process.env.PUBLIC_URL+'/'
	let limit = 12;
	let offset = 1;
	//let page = 1;
	let PaginationPath = "blog/page/";
	let { slug, page } = useParams();
	
	const location = useLocation();
	if(page === undefined){ page = 1 }

	
	// var path = window.location.pathname;
	// var pageNumber = parseInt(path.split('/').pop());

	// if(Number.isInteger(pageNumber)){  page = pageNumber; }

	if(page > 1){ offset = (page - 1) * limit; }

	let total = 0;
	const li = [];
	const [error, setError] = useState(null);
	const [blogs, setblogs] = useState([]);
	const [Featured, setFeatured] = useState(null);
	
	let apiURl = 'https://www.textmercato.com/cms/api/blogs?populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
	
	if(location.pathname.startsWith('/category/')){
		PaginationPath = "category/"+slug+"/";
		offset = 0;
		if(page > 1){ offset = (page - 1) * limit; }
		apiURl = 'https://www.textmercato.com/cms/api/blogs?filters[categories][slug][$eq]='+slug+'&populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
	}
	
	useEffect(() => {
		axios
		.get(apiURl)
		.then(({ data }) => setblogs(data))
		.catch((error) => setError(error));

		if(page == 1){ 
			
		
			if(!location.pathname.startsWith('/category/')){
				axios
				.get('https://www.textmercato.com/cms/api/blogs/?populate=Featured,categories&pagination[start]=0&pagination[limit]=1&&sort=id:DESC')
				.then(({ data }) => setFeatured(data.data[0]))
				.catch((error) => setError(error))
			}
		}
	}, [])

	
	if (error) {
		// Print errors if any
		return <div>An error occured: {error.message}</div>;
	}
	
	if(blogs.meta){
		let totalPosts = blogs.meta.pagination.total;
		if(totalPosts >= 24){
			total = Math.round(totalPosts / limit);
		}else if(totalPosts > 12 && totalPosts < 24 ){
			total = 2;
		}else{
			total = 1;
		}
		
		for(let i = 1; i <= total; i++){
			li.push(i);
		}
	}

    
	
	const category = (categories) => {		
		let CatTitle = [{'title' : '', 'slug' : ''} ]
		if(categories.data){
			categories.data.map((cat, index) => (
				CatTitle['title'] = cat.attributes.Title,
				CatTitle['slug'] = cat.attributes.Slug
			));
		}
		return CatTitle;
	}
	
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric"}
		return new Date(dateString).toLocaleDateString(undefined, options)
	}
	
    let imagealt = 'image'

    return (
		<div>
		<PageHeader inner={ location.pathname.startsWith('/category/') ? 'blog' : '' } isCategory={ slug ? (slug.replace('-', ' ').charAt(0).toUpperCase() + slug.replace('-', ' ').substr(1).toLowerCase()) : '' } headertitle="Blog"  />
      <section className="blog-grid  go-top">
			  <div className="container">
			    <div className="row">
				
				
			{page === 1 && Featured ? ( 

				<><div className="col-lg-6 col-md-6 featured_post">
							<div className="blog-one__single">
								<div className="blog-one__image">
								<img src={ Featured.attributes.Featured.data !== null ? ("https://www.textmercato.com/cms" + Featured.attributes.Featured.data.attributes.url) : '' } alt={ Featured.attributes.Title } />	
									
								</div>

							</div>
						</div><div className="col-lg-6 col-md-6  latest-blog-content featured_post">
								<div className="blog-one__single">

									<div className="blog-one__content">
										<h6><Link to={"/category/"+category(Featured.attributes.categories)['slug']}>{category(Featured.attributes.categories)['title']}</Link> <span>{ formatDate(Featured.attributes.createdAt)  } </span></h6>
										<h3><Link to={"/blog/"+Featured.attributes.Slug}>{Featured.attributes.Title}</Link></h3>
										<p>{Featured.attributes.Content}</p>
										{/* <div className="blog-one__author">
											<img src={publicUrl + "assets/images/blog/blog-author-1-1.jpg"} alt={imagealt} />
											<p>Chandu Reddy</p>
										</div> */}


									</div>
								</div>
							</div></>
			 ) : '' }
			
					
			      
				{blogs.data ? (
                    
                    blogs.data.map((post, index) => (
						
						
						<div className="col-lg-3 col-md-6">
							<div className="blog-one__single">
							<Link to={"/blog/"+post.attributes.Slug} className="blog-one__image">
								<img src={ post.attributes.Featured.data !== null ? ("https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url) : '' } alt={ post.attributes.Title } />	
								
							</Link>
							<div className="blog-one__content">
							<h6><Link to={"/category/"+category(post.attributes.categories)['slug']}>{category(post.attributes.categories)['title']}</Link> <span>{ formatDate(post.attributes.createdAt)  } </span></h6>
								<h3><Link to={"/blog/"+post.attributes.Slug}>{ post.attributes.Title  }</Link></h3>
								
								<p>{ post.attributes.Content.substring(0, 150)  }...</p>
								{/* <div className="blog-one__author">
									<img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg" }alt={imagealt} />
									<p className='authorName'></p>
								</div> */}
								
								
							</div>
							</div>
						</div>

					 
                   
                    
                    ))) : ( <div className="loader content_preloader">Loading...</div>  )  }

			      

 


      
			      
			    </div>{/* /.row */}
			    
				<div className="post-pagination">
					{ blogs.data &&  page > 1 ? (
					<a href={publicUrl+PaginationPath+(parseInt(page) - 1)} ><i className="fa fa-angle-left" />{ }</a>
					) : '' }
					{  li.length > 0 ? (
						
						li.map((parsedData, index) => (						
							<a className={ parsedData == page ? 'active' : '' } href={publicUrl+PaginationPath+ (parsedData) }>{ parsedData }</a>
						))
						
					)	: ""
					} 

					
					{page < total ? (
					<a href={publicUrl+PaginationPath+(parseInt(page) + 1)} ><i className="fa fa-angle-right" /></a>
					) : '' }
			    </div>

			  </div>{/* /.container */}
			</section>
			</div>
    )
}

export default BlogGrid;
