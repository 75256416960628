import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import PageHeader from './page-header';
import NavbarV2 from '../global-components/navbar-v2';
import Footer_v2 from '../global-components/footer-v2';
import Calltoaction from '../section-components/cta';

const Category = () => {



	let publicUrl = process.env.PUBLIC_URL+'/'
	let limit = 8;
	let offset = 0; 
	let { slug, term } = useParams();

	const li = [];
	const [error, setError] = useState(null);
	const [blogs, setblogs] = useState([]);
    const [faqs, setFaqs] = useState(null);

    let searchTerms = term;
    let BlogQuery = "";
    let FaqQuery = "";
    let operator  = '$and';
    if(term){
        searchTerms = term.split(" ");
        for(var i =0; i < searchTerms.length; i++){
            if(i > 1){ operator = "$or"; }
            BlogQuery = BlogQuery + "filters["+operator+"]["+i+"][title][$contains]="+searchTerms[i]+"&";
            FaqQuery = FaqQuery + "filters["+operator+"]["+i+"][question][$contains]="+searchTerms[i]+"&";
        }
    }
    
	let apiURl = 'https://www.textmercato.com/cms/api/blogs?'+BlogQuery+'populate=Featured,categories&pagination[start]='+offset+'&pagination[limit]='+limit+'&sort=createdAt:DESC';
	
	console.log(apiURl);
	useEffect(() => {
		axios
		.get(apiURl)
		.then(({ data }) => setblogs(data))
		.catch((error) => setError(error));

        axios
		.get("https://www.textmercato.com/cms/api/faqs?"+FaqQuery+"populate=category&pagination[start]=1&pagination[limit]="+limit+"&sort=createdAt:DESC")
		.then(({ data }) => setFaqs(data))
		.catch((error) => setError(error));

 


	}, [])

	if (error) {
		// Print errors if any
		return <div>An error occured: {error.message}</div>;
	}
	
 
	const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    
	
	const category = (categories) => {		
		let CatTitle = [{'title' : '', 'slug' : ''} ]
		if(categories.data){
			categories.data.map((cat, index) => (
				CatTitle['title'] = cat.attributes.Title,
				CatTitle['slug'] = cat.attributes.Slug
			));
		}
		return CatTitle;
	}
	
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric"}
		return new Date(dateString).toLocaleDateString(undefined, options)
	}
	
    let imagealt = 'image'
    console.log(faqs);

    return (
		<><div>
            <NavbarV2></NavbarV2>

            <section className="page-header" >
				  <div className="container">
				    <h2>Search</h2>
				    <ul className="list-unstyled thm-breadcrumb">
				      <li><Link to="/">Home</Link></li>
                      <li><Link >Search</Link></li>
				      <li><span>{term ? (term.replace('-', ' ').charAt(0).toUpperCase() + term.replace('-', ' ').substr(1).toLowerCase()) : ''}</span></li>
				    </ul>{/* /.list-unstyled thm-breadcrumb */}
				  </div>{/* /.container */}
			</section>


          
            <section className="blog-grid  go-top pt-5 pb-5">
                <div className="container">
                <div class="block-title-two text-center "><h3>Blogs </h3></div>
                    
                    <div className="row">


                      



                        {blogs.data ? (

                            blogs.data.map((post, index) => (


                                <div className="col-lg-3 col-md-6">
                                    <div className="blog-one__single">
                                        <Link to={"/blog/" + post.attributes.Slug} className="blog-one__image">
                                            <img src={"https://www.textmercato.com/cms" + post.attributes.Featured.data.attributes.url} alt={imagealt} />
                                        </Link>
                                        <div className="blog-one__content">
                                            <h6><Link to={"/category/" + category(post.attributes.categories)['slug']}>{category(post.attributes.categories)['title']}</Link> <span>{formatDate(post.attributes.createdAt)} </span></h6>
                                            <h3><Link to={"/blog/" + post.attributes.Slug}>{post.attributes.Title}</Link></h3>

                                            <p>{post.attributes.Content.substring(0, 150)}...</p>
                                            {/* <div className="blog-one__author">
                    <img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg" }alt={imagealt} />
                    <p className='authorName'></p>
                </div> */}


                                        </div>
                                    </div>
                                </div>








                            ))) : (<div className="loader content_preloader">Loading...</div>)}



                            <div className="col-lg-12 col-md-12 text-center mt-0 mb-5"><a class="thm-btn banner-two__btn " href="/blog">View all blogs <i class="fa fa-angle-double-right"></i></a></div>




                    </div>{/* /.row */}

                    
                 
                </div>{/* /.container */}
            </section>
           
            {faqs !== null && typeof faqs === 'object' && Object.keys(faqs).length > 0 && faqs.data.length > 0 ? (
                <section className="faq-one  faqListing pt-0 ">
                    <div className="container">
                    <hr className='mb-5 pb-3'></hr>
                    <div class="block-title-two text-center "><h3>FAQs </h3></div>
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="faq-one__block">
                            
                            <div className="accrodion-grp faqsLink" data-grp-name="faq-one-accrodion">
                            {faqs ? (
                                        faqs.data.map((post, index) => (
                                <a href={"/faq/"+post.attributes.Slug} className={ index == 0 ? 'accrodion ' : 'accrodion ' }>
                                <div className="accrodion-title">
                                    <h4>{ post.attributes.Question }</h4>
                                </div>
                                </a>
                                ))
                                ) : ( <div className="loader content_preloader">Loading...</div>  )
                            }


                                
                            </div>
                            </div>
                            
                        </div>


                        <div className="col-lg-12 col-md-12 text-center mt-5 mb-0"><a class="thm-btn banner-two__btn " href="/faqs">View all FAQs <i class="fa fa-angle-double-right"></i></a></div>
                        
                            
                        
                        </div>
                    </div>
                </section>
            ) : ""
        }


        
        </div>
        <Calltoaction />
        <Footer_v2></Footer_v2>
        </>
    )
}

export default Category;
