import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v2';

import Footer from './global-components/footer-v2';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';


// vCZwSnhhVR7R6ik
const Register = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    const SITE_KEY = "6LfkXOcjAAAAAJTNYBo558b4hYcpGadOEDjiIWTx";
    const SECRECT_KEY = "6LfkXOcjAAAAALxF5YR-H53ME8i-1Mtz8jgXvhvy";
  // https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react
    

    const [errors, setErrors] = useState(null);
    const [isActive, setActive] = useState('active');
    const [thankyou, setThankyou] = useState(null);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/login.js";
    document.body.appendChild(minscript);

    useEffect(() => {
      const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
    
          if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
          if (callback) callback();
          };
          document.body.appendChild(script);
          }
    
      if (isScriptExist && callback) callback();
      }
            // load the script by passing the URL
      loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
      });
      }, []);

      
    

      const handleOnClick = e => {
        e.preventDefault();
            setLoading(true);

            if(e.target.classList.contains('Inputerrors')){
                setLoading(false);
            }else{
                window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                    submitData(token);
                    });
                });
            
            }
        }
        
        const submitData = token => {
          // call a backend API to verify reCAPTCHA response
          fetch('http://mvp-alb-552307351.ap-south-1.elb.amazonaws.com/session/api/client-register', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              "fullName": name,
              "email": email,
              "phoneNumber": phone,
              "password": password,
              "g-recaptcha-response": token
            })
          }).then(res => res.json()).then(res => {
            setLoading(false);
            if(res.message){
                setActive('active');
                setMessage(res.message);
            }
            if(res.data._id){
                setActive(null);
                setThankyou('active')
            }
            console.log(res.message);
            setResponse(res);
          });
        }

    return <div>

        <Helmet>
            <title>Register</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body'>
        <div className="container">
            <div className="card">
                <div className="form">
                    <div className="left-side">
                    
                    
                    <img width='100%' src={publicUrl+"assets/Text-Mercato-Sign-up.png"} alt={ imagealt } />

                    <div className={"testimonials-two__carousel  owl-carousel thm__owl-carousel owl-theme"} data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 1, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 1 }, &quot;1200&quot;: { &quot;items&quot;: 1 } }}">
			      
                    <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
			              <img src={publicUrl+"assets/logos/urban.png"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
			              <h3>Shikha  </h3>
			              <span>Urban Ladder</span>
			            </div>{/* /.testimonials-two__top-content */}
                        <div className='testimonialsRatring'>
                            <div className='testimonialStars'>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                            </div>
                            <div className='testimonialComment'>"Great Team Support"</div>
                        </div>
			          </div>{/* /.testimonials-two__top */}
			         
			         
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
                  
                  
                  
			       
			      
			      <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
                            <img src={publicUrl+"assets/logos/frontfold.png"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
                            <h3>Nandan S Kedlaya  </h3>
				            <span>Founder</span>
			            </div>{/* /.testimonials-two__top-content */}
                        <div className='testimonialsRatring'>
                            <div className='testimonialStars'>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                            </div>
                            <div className='testimonialComment'>"High Quality Content"</div>
                        </div>
			          </div>{/* /.testimonials-two__top */}
			         
			          
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}
			         

				  <div className="item">
			        <div className="testimonials-two__single">
			          <div className="testimonials-two__top">
			            <div className="testimonials-two__image">
                            <img src={publicUrl+"assets/logos/nilkamal.png"} alt={ imagealt } />
			            </div>{/* /.testimonials-two__image */}
			            <div className="testimonials-two__top-content">
                        <h3>Siddharth Pamoor </h3>
				        <span>Sr.SEO Executive</span>
			            </div>{/* /.testimonials-two__top-content */}
                        <div className='testimonialsRatring'>
                            <div className='testimonialStars'>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                                <i className='fa fa-star color-blue'></i>
                            </div>
                            <div className='testimonialComment'>"Highly professional"</div>
                        </div>
			          </div>{/* /.testimonials-two__top */}
			         
			         
			        </div>{/* /.testimonials-two__single */}
			      </div>{/* /.item */}




			    </div>{/* /.testimonials-two__carousel owl-carousel thm__owl-carousel owl-theme */}
                        
                     
  
                        
                        

                        
                    </div>
                    <div className="right-side">
                        <div id='signupForm' className={"main " + isActive }>
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'>Sign-up for Free </h3>
                                <Link onClick={() => {window.location.href="/login"} } className='simple-link border-left-grey sub-heading-link' to="/login"><span className='text-black'>Already have an account? </span> Sign in</Link>  
                                {/* <p>Ordering content online made easy.</p> */}
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <label>Enter Full Name</label> 
                                    <input type="text" onChange={(e) => setName(e.target.value)} data-message="Enter full name / atleast two letters" name='name' required />
                                    {/* <span>Enter Full Name </span> */}
                                </div>
                            </div> 


                            <div className="input-text">
                                <div className="input-div">
                                    <label>Enter Email Address</label> 
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} data-message="Enter a valid email " name='email' id='email' required  />
                                    {/* <span>Enter Email Address</span> */}
                                </div>
                                
                            </div>

                            <div className="input-text">
                                
                                <div className="input-div">
                                    <label>Enter Phone Number</label> 
                                    <input type="tel" onChange={(e) => setPhone(e.target.value)} data-message="Enter a valid phone number, + allowed. " name='phone' required  />
                                    {/* <span>Enter Phone Number</span> */}
                                </div>
                            </div>

                             <div className="input-text">
                                <div className="input-div">
                                    <label>Enter Password</label> 
                                    <input type="password" onChange={(e) => setPassword(e.target.value)} data-message="Enter a valid password" name='password' required  />
                                    <a className='showpassword' href='javascript:void(0)'><i className='fa fa-eye'></i></a>
                                    {/* <span>Enter Password</span> */}
                                </div>
                               
                            </div> 

                                
                             

                            <div className="input-text terms-condition-checkbox">
                                <div className="input-div form-group">
                                    <input id='agree' type="checkbox" data-message="Agree to the terms " name='terms' value="1" required require />
                                    <label for="agree">Agree to  <a href='#'>Terms of Use </a></label>
                                </div>
                            </div>
                             
                            <div className="buttons ">

                            { message !== null ? ( <p className='text-danger'> { message } </p> ) : '' }
                               
                                <button className="submit_button thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Register'}</button>
                                
                                
                                <Link onClick={() => {window.location.href="/creator-register"} } className='simple-link border-left-grey' to="/creator-register"><span className='text-grey'> To register as a creator </span> click here </Link>  

                                {/* <button onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button> */}
                                
                            </div>
                        </div>
                     
                        
                        
                        <div className={"main " + thankyou }>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            
                            <div className="text congrats">
                                <h2>Thank you!</h2>
                                <p>{ message  } </p>
                            </div>
                        </div>
                        
                    
                    

                    

                    </div>
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
    </div>
}

export default Register

