import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV4 extends Component {
	 componentDidMount() {
	 	let publicUrl = process.env.PUBLIC_URL+'/'
        const cssUrl =   publicUrl + "assets/css/color-2.css";
        this.addStyle(cssUrl);

        const $ = window.$;
        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;

        document.head.appendChild(style);


    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-two  go-top banner-inner">
			  <div className="container">
			    <div className="row">
			      <div className="col-xl-6 my-auto">
			        <div className="banner-two__content">
			          <h1><span>Professional</span> Content Providing Services <span>for Your Business</span></h1>
			          <p>Over 10,000 vetted writers are ready to fulfil your content requirements, across industries and varied types of content.</p>
			          <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
			        </div>{/* /.banner-two__content */}
			      </div>{/* /.col-xl-7 */}

                <div className='col-xl-6'>
                    <img width="85%" src={publicUrl+"assets/content-hub-1.png"} alt="Banner" />
                </div>


			    </div>
			  </div>
			</section>

        }
}

export default BannerV4