import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useState } from 'react';

const Login = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpSent, setotpSent] = useState(false);
    const [verified, setVerified] = useState(false);
    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/login.js";
    document.body.appendChild(minscript);

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    const handleOnClick = e => {
        e.preventDefault();
        setLoading(true);
        // history.push("/client-order-all");            
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{
            setLoading(false);
            setotpSent(true)
        }
    }

    const VerifyOtp = e => {
        e.preventDefault();
        setLoading(true);            
        // history.push("/client-order-all");            
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{
            setLoading(false);
            setVerified(true);            
        }
    }

    const initialValue = '';
    const [phone, setPhone] = useState(initialValue);
    const handleChange = (event) => {
        const value = event.target.value;
        setPhone(value);
      };

    return <div>

        <Helmet>
            <title>Login</title>
            <meta name="" />
        </Helmet>

        <Navbar ></Navbar>
        <span className="step-number ">1</span>
        <section className='login-section snippet-body'>
            <div className="container">
                <div className="card">
                    <div className="form">



                    <div className="left-side">
                       
                        
                       <img width='100%' src={publicUrl+"assets/Text-Mercato-Sign-up.png"} alt={ imagealt } />
   
                       <div className={"testimonials-two__carousel  owl-carousel thm__owl-carousel owl-theme"} data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 30, &quot;stagePadding&quot;: 0, &quot;items&quot;: 1, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: true, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 0 }, &quot;1199&quot;: { &quot;items&quot;: 1 }, &quot;1200&quot;: { &quot;items&quot;: 1 } }}">
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                       <img src={publicUrl+"assets/logos/urban.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                       <h3>Shikha  </h3>
                       <span>Urban Ladder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Great Team Support"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
   
   
   
   
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/frontfold.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                               <h3>Nandan S Kedlaya  </h3>
                       <span>Founder</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"High Quality Content"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
   
   
                       <div className="item">
                       <div className="testimonials-two__single">
                       <div className="testimonials-two__top">
                       <div className="testimonials-two__image">
                               <img src={publicUrl+"assets/logos/nilkamal.png"} alt={ imagealt } />
                       </div>{/* /.testimonials-two__image */}
                       <div className="testimonials-two__top-content">
                           <h3>Siddharth Pamoor </h3>
                       <span>Sr.SEO Executive</span>
                       </div>{/* /.testimonials-two__top-content */}
                           <div className='testimonialsRatring'>
                               <div className='testimonialStars'>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                                   <i className='fa fa-star color-blue'></i>
                               </div>
                               <div className='testimonialComment'>"Highly professional"</div>
                           </div>
                       </div>{/* /.testimonials-two__top */}
   
   
                       </div>{/* /.testimonials-two__single */}
                       </div>{/* /.item */}
                               
                               
   
                           </div>
                           
                           
   
                           
                    </div>
                    
                        <div className="right-side darg-bg ">
                            <div className={ otpSent === false && verified === false ? 'main active' : 'main' }>
                                
                                <div className="text mb-4 mt-0">
                                    <h3 className='color-blue'>Welcome Back </h3>
                                    <Link onClick={() => {window.location.href="/client-register"} } className='simple-link border-left-grey sub-heading-link' to="/client-register"><span className='text-black'>New to Text Mercato? </span> Sign-up</Link> 
                                </div>
                                <div className="input-text">
                                <div className="input-div">
                                        <label>Mobile Number</label>
                                        <input type="tel" onChange={handleChange} data-message="Enter a valid phone number, + allowed. " name='phone' id='email' required require />
                                        {/* <span>E-mail Address</span> */}
                                    </div>
                                </div>
                                

                                <div className="buttons ">
                                    <button onClick={handleOnClick} disabled={loading} className="submit_button thm-btn">{loading ? 'Sending otp...' : 'Submit'}</button>
                                   
                                    {/* <button className="submit_button thm-btn">Log-in</button> */}
                                  
                                    {/* <Link className='simple-link inline-links' to="/forgot-password">Forgot password?</Link> */}
                                    <Link onClick={() => {window.location.href="/login"} }  className='simple-link inline-links' to="/login">Login with email?</Link>

                                    <button className="back_button d-none">Back</button>
                                    

                                </div>
                            </div>
                            
                            

                            <div className={otpSent === true && verified === false ? 'main active' : ' main' }>
                                
                                <div className="text mb-4 mt-0">
                                    <h3 className='color-blue'>Welcome Back </h3>
                                    <Link onClick={() => {window.location.href="/client-register"} } className='simple-link border-left-grey sub-heading-link' to="/client-register"><span className='text-black'>New to Text Mercato? </span> Sign-up</Link> 
                                </div>
                                <div className="input-text">
                                <div className="input-div">
                                        <label>Enter OTP</label>
                                        <input type="text" data-message="Enter a valid password." name='otp' required require />
                                        {/* <span>E-mail Address</span> */}
                                    </div>
                                </div>
                                <div className="otp_info mt-3">
                                    OTP has been sent to <b className='mobileNumber'>{ phone }</b>
                                </div>

                                <div className="buttons ">

                                
                                <button onClick={VerifyOtp} disabled={loading} className="submit_button thm-btn">{loading ? 'Verifying...' : 'Sign In'}</button>
                                
                                  
                                    {/* <Link className='simple-link inline-links' to="/forgot-password">Forgot password?</Link> */}
                                    <Link className='simple-link inline-links' to="/login">Sign-In with email?</Link>

                                    <button className="back_button d-none">Back</button>
                                    

                                </div>
                            </div>


                            { verified === true ? 
                                    (
                                        <div className="main active">
                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                            </svg>
                                            
                                            <div className="text congrats">
                                                <h2>Logged-In</h2>
                                                <p></p>
                                            </div>
                                        </div>
                                    ) :
                                    ('')
                                }



                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
}

export default Login

