import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import ClientRoute from './clientRoute';
import ServerRoute from './serverRoute';

const exportedAPI = typeof window !== 'undefined' ? ClientRoute : ServerRoute

export default class Client extends exportedAPI {  }
// export class Server extends ServerRoute {  }

if (typeof window !== 'undefined') {
	ReactDOM.hydrate(<Router><Client></Client></Router>, document.getElementById('mainApp'));
}
