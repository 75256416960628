import React from 'react';
import Navbar from './global-components/navbar-v2';

import BlogDetails from './blog-components/blog-details';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';

const BlogList = () => {
    return <div>
        <Navbar />
        <BlogDetails />
        <Calltoaction></Calltoaction>
        <Footer />
    </div>
}

export default BlogList

