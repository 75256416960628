import React, { Component } from 'react';


class BrandTwo extends Component {
    
    render() {
      
        // if (typeof(window) == 'undefined') global.window = new Object();
        let publicUrl = process.env.REACT_APP_PUBLIC_URL+'/';
        let CustomClass = this.props.CustomClass ? this.props.CustomClass : '';
        

    return <section className={"brand-one brand-one__home-two " + CustomClass }>
      <div className="container">
        <div className="block-title-two text-center">
          <h3 className="font-30">1000+ Businesses Trust TextMercato for Their Content Needs</h3>
        </div>{/* /.block-title-two */}
        <div className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel" data-options="{&quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 5000, &quot;items&quot;: 6, &quot;margin&quot;: 50, &quot;smartSpeed&quot;: 700, &quot;nav&quot;: false, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 2, &quot;margin&quot;: 15 },&quot;575&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 },&quot;767&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 }, &quot;991&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 30 }, &quot;1199&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 75 }, &quot;1200&quot;: { &quot;items&quot;: 6 } }}">
          <div className="item">
            <img src={publicUrl+"assets/images/brand/brand-1-1.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/logos/khatabook.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/logos/unadacemy.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/logos/Make-My-Trip.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/brand-1-5.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/brand-1-6.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/brand-1-4.png"} alt="" />
          </div>{/* /.item */}

          <div className="item">
            <img src={publicUrl+"assets/images/brand/knowledgehut.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/myforexeye.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/nilkamal.png"} alt="" />
          </div>{/* /.item */}

          <div className="item">
            <img src={publicUrl+"assets/images/brand/oliveboard.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/staragile.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/woovly.png"} alt="" />
          </div>{/* /.item */}
          <div className="item">
            <img src={publicUrl+"assets/images/brand/writerzen.png"} alt="" />
          </div>{/* /.item */}

          
          
          
        </div>{/* /.brand-one__carousel owl-carousel owl-theme thm__owl-carousel */}
      </div>{/* /.container */}
    </section>


        }
}

export default BrandTwo