import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HubspotForm from 'react-hubspot-form'

class Contact extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
		
		
		<section className="banner-two pb-0  go-top banner-inner ">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content ">
                    <h1><span>Get In </span> Touch</h1>
                    <p >TextMercato is one of the leading content companies catering to the growing need for quality content in the Indian & international market.  Content is the key that adds value to the users, engaging and communicating about your brand.</p>
                    
					<ul className="list-unstyled contact-two__info-list">
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-map-marked" />
		                  Address :
		                </div>{/* /.contact-two__info-list-title */}
		                <p>New 19/1, 4th Cross Rd, 1st Block, Jayanagar, <br></br>Bengaluru, Karnataka 560011</p>
		              </li>
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-phone" />
		                  Call Us :
		                </div>{/* /.contact-two__info-list-title */}
		                <p><a href="tel:09886192920">09886192920</a></p>
		              </li>
		              <li>
		                <div className="contact-two__info-list-title">
		                  <i className="far fa-envelope" />
		                  Email Us :
		                </div>{/* /.contact-two__info-list-title */}
		                <p><a href="mailto:support@textmercato.com">support@textmercato.com</a></p>
		              </li>
		            </ul>{/* /.contact-two__info-list */}


                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

                <div className="col-md-5 offset-1 d-flex">
			        <div className="my-auto husbpost-form">

					<HubspotForm
						portalId='22122965'
						formId='e0b6b573-6887-42ed-93ec-53b8e5a17a19'
						onSubmit={() => console.log('Submit!')}
						onReady={(form) => console.log('Form ready!')}
						loading={<div>Loading...</div>}
						/>

			        </div>{/* /.my-auto */}
			      </div>{/* /.col-lg-7 */}


            </div>
            </div>
        </section>

		<iframe style={{ height:700 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.792609479431!2d77.5878602!3d12.94315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e5ba167a9b7445f!2sText%20Mercato%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1673409432025!5m2!1sen!2sin" className="google-map__contact" allowFullScreen />
		  
		  
		</div>

        }
}

export default Contact