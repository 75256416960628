import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import Store from './store';


import HomeV2  from './components/home-v2';
import digital_content_platform  from './components/digital-content-platform';

import freelance from './components/freelance';
import contentTypes from './components/contentTypes';
import BlogGrid from './components/blog-grid';

import Tags from './components/blog-components/Tags';
import Category from './components/blog-components/category';
import Search from './components/blog-components/search';

import BlogDetails from './components/blog-details';
import Platform from './components/platform';
import get_started from './components/get-started';
import resources from './components/resources';
import Samples from './components/samples';
import DownloadSamples from './components/download-samples';

import cmsPages from './components/cmsPages';
import faqs from './components/faq';
import faqsDetails from './components/faq-details';

import text_content from './components/services/text-content';
import graphics from './components/services/graphics';
import translation from './components/services/translation';


import Startup from './components/companies/startup';



import Login from './components/login';
import LoginWithOtp from './components/login_with_otp';
import forgotPassword from './components/forgot-password';
import passwordResetPage from './components/password-reset-page';
import Register from './components/register';
import writerRegister from './components/writer-register';


import ClientOrderAll from './components/client-order-flow/all';
import ClientOrderProjectName from './components/client-order-flow/client-order-enter-project-name';
import ClientOrderRequirements from './components/client-order-flow/client-order-content-requirements';
import ClientOrderProjectDetails from './components/client-order-flow/client-order-content-project-details';
import ClientOrderImages from './components/client-order-flow/client-order-images';
import ClientOrderReviews from './components/client-order-flow/client-order-review';
import ClientOrderPayments from './components/client-order-flow/client-order-payments';
import ClientOrderSucessful from './components/client-order-flow/client-order-text-payments-sucessful';



import PostProjectTranslation from './components/post-projects-translation';
import PostProjectGraphics from './components/post-projects-graphics';
import uploadBulk from './components/upload-bulk';

import Dashboard from './components/dashboard';
import OrderDetail from './components/OrderDetail';
import Projects from './components/projects';


import Glossary from './components/glossary';
import websiteBlog from './components/website-blog';
import productDescriptions from './components/product-descriptions';
import whitePapper from './components/white-papper';
import Ebook from './components/ebook';
import Copywriting from './components/copywriting';
import SEOArticles from './components/seo-articles';

import BrandIdentity from './components/BrandIdentity';
import About from './components/About';
import Contact from './components/contact';

import Common from './components/industries/Common';
import Finance from './components/industries/finance';

import get_started_thankyou from './components/get-started-thankyou';
import { Switch } from 'react-router-dom';


class ServerRoute extends Component {
    render() {
        return(
            <Provider store={Store}>
	          
	                <Switch>
	                   
						<Route exact path="/" component={HomeV2} />
						<Route exact path="product" component={digital_content_platform} />
						<Route exact path="brand-identity" component={BrandIdentity} />
						<Route exact path="about-us" component={About} />
						<Route exact path="contact" component={Contact} />

						<Route exact path="dashboard" component={Dashboard} />
						<Route exact path="order-detail/:id" component={OrderDetail} />
						<Route exact path="projects" component={Projects} />

						<Route exact path="glossary" component={Glossary} />
						<Route exact path="faqs" component={faqs} />
						<Route exact path="faqs/page/:page" component={faqs} />
						<Route exact path="faq/:slug" component={faqsDetails} />

						<Route exact path="search/:term" component={Search} />

						<Route exact path="blog/tag/:slug" component={Tags} />
						<Route exact path="blog/tag/:slug/page/:page" component={Tags} />

						<Route exact path="category/:slug" component={Category} />
						<Route exact path="category/:slug/:page" component={Category} />
						<Route exact path="blog" component={BlogGrid} />
						<Route exact path="blog/page/:page" component={BlogGrid} />
						<Route exact path="blog/:slug" component={BlogDetails} />

						<Route exact path="content-writing/website-blog" component={websiteBlog} />
						<Route exact path="content-writing/product-descriptions" component={productDescriptions} />
						<Route exact path="content-writing/whitepapper" component={whitePapper} />
						<Route exact path="content-writing/ebooks" component={Ebook} />
						<Route exact path="content-writing/copywriting" component={Copywriting} />
						<Route exact path="content-writing/seo-articles" component={SEOArticles} />

						<Route exact path="industries" component={Common} />
						<Route exact path="industry/finance" component={Finance} />

						<Route exact path="services" component={contentTypes} />
						<Route exact path="services/text-content" component={text_content} />
						<Route exact path="services/graphics" component={graphics} />
						<Route exact path="services/translations" component={translation} />

						<Route exact path="companies/startup" component={Startup} />

						<Route exact path="platform" component={Platform} />
						<Route exact path="freelance" component={freelance} />
						<Route exact path="get-started" component={get_started} />
						<Route exact path="thank-you" component={get_started_thankyou} />
						<Route exact path="resources" component={resources} />

						<Route exact path="samples" component={Samples} />
						<Route exact path="downloadsampales/:pdf?" component={DownloadSamples} />


						<Route exact  path="login" component={Login} />
						<Route exact  path="login-with-otp" component={LoginWithOtp} />
						<Route exact  path="password-reset-request" component={forgotPassword} />
						<Route exact  path="password-reset-page" component={passwordResetPage} />

						<Route exact  path="client-register" component={Register} />
						<Route exact  path="creator-register" component={writerRegister} />
						<Route exact  path="post-project-translation" component={PostProjectTranslation} />

						<Route exact  path="post-project-graphics" component={PostProjectGraphics} />
						<Route exact  path="bulk-upload" component={uploadBulk} />
						<Route exact  path="privacy-policy" component={cmsPages} />
						<Route exact  path="cookie-policy" component={cmsPages} />
						<Route exact  path="terms-of-use" component={cmsPages} />


						<Route exact  path="client-order-all" component={ClientOrderAll} />
						<Route exact  path="client-order-enter-project-name" component={ClientOrderProjectName} />
						<Route exact  path="client-order-content-requirements" component={ClientOrderRequirements} />
						<Route exact  path="client-order-content-project-details" component={ClientOrderProjectDetails} />
						<Route exact  path="client-order-images" component={ClientOrderImages} />
						<Route exact  path="client-order-review" component={ClientOrderReviews} />
						<Route exact  path="client-order-payments" component={ClientOrderPayments} />
						<Route exact  path="client-order-text-payments-sucessful" component={ClientOrderSucessful} />
						
						

	              </Switch> 
	         
                </Provider>
        )
    }
}

export default ServerRoute;
