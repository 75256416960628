import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-style-four';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const productDescriptions = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Top-notch Product Description Writing Services For Your Business</title>
            <meta name="Gain the right traction with TextMercato product description writing services. Our specialist product content writers can craft and deliver the descriptions you envision." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1><span>Professional </span>On-demand Product Description<span> Writing Services </span></h1>
                  <p>TextMercato helps business owners harness the full power of compelling copies.  Our team of competent professionals consistently delivers quality product descriptions.</p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/product-descriptions.svg"} alt="Banner" />
                  </div>


            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section className="service-three white-bg   go-top">
		  <div className="container">

		  <div className="row ">
		  <div className="col-md-10 offset-1 ">
		    <div className="block-title-two text-center mb-0">
				 
		      <h3>Partner With TextMercato for Product Description Writing Services</h3>
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>TextMercato product description writing services go beyond the basics. Our content ninjas work diligently with the big picture in mind. TextMercato aligns it with your digital marketing strategy to make the most of on-demand descriptive content.</p>
		</div>
		</div>

		    <div className="row mt-5">
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-pen-clip" />
		          <h3><a className='scroll-to-target' data-target="#digital-content" href='#'  >Brand Voice</a></h3>
		          <p>Our team of experienced product writers can grasp the tone quickly and ensure uniformity across deliveries. When well-crafted, they can work as a signature for your brand.  </p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-invoice" />
		          <h3><a   className='scroll-to-target' data-target="#managed-accounts"  href='#'>Niche Descriptions</a></h3>
		          <p>Our specialist teams can deliver domain-specific content. With such curated product content, businesses can connect with the right audience. Aligned with a well-crafted strategy, they also help stay ahead of the competition.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-folder-tree" />
		          <h3><a  className='scroll-to-target' data-target="#seo-intelligence"  href='#'>Unique Content</a></h3>
		          <p>Unique product descriptions can keep you on friendly terms with search engines like Google. So, we go the extra mile with a plagiarism checker to ensure this with every delivery-however, small or large.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}


              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-pen" />
		          <h3><a  className='scroll-to-target' data-target="#ai-copy-writer"  href='#'>SEO optimized</a></h3>
		          <p>Descriptions woven around search keywords can get you in the good books of search engines. TextMercato is your dependable ally for improving brand presence and online visibility with a competent SEO keyword strategy. .</p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-globe" />
		          <h3><a  className='scroll-to-target' data-target="#publishing"  href='#'>Editor Reviews</a></h3>
		          <p>Every description passes through proficient editors. This added layer ensures strict adherence to content guidelines. As a business owner, you can heave a sigh of relief and be assured that you get quality product descriptions as desired. </p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-chart-line" />
		          <h3><a  className='scroll-to-target' data-target="#analytics"  href='#'>Tech Assistance</a></h3>
		          <p>Bundled with tech leverage, TextMercato can ensure any number of stellar product descriptions for your business offerings.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}

		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>





        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>Why include product descriptions in content strategy?</a></li>
                    <li><a href='#faq-2'>Why opt for product description services?</a></li>
                    <li><a href='#faq-3'>Why should you choose TextMercato’s product description writing service?</a></li>
                    <li><a href='#faq-4'>Can TextMercato offer writing services for small business?</a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>Why include product descriptions in content strategy?</h2>
                    <p> Descriptions can work strategically for businesses. They can help market offerings and ensure conversions. The devil is in the details. TextMercato provides unique product descriptions on demand, which will be an advantage to your marketing plan.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Why opt for product description services?</h2>
                    <p>Product writers are experts in creating compelling copies. Getting attractive descriptions can help you navigate around common loopholes.  Choose TextMercato product writing services and prepare to strike the right chord with your intended audience.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                    <h2>Why should you choose TextMercato’s product description writing service?</h2>
                    <p>Dealing with thousands of product listings can be overwhelming. However, TextMercato writers make it look easy. When you choose TextMercato, you get quality product descriptions with shorter turn-around times and faster deliveries. We ensure that you always have fresh publishable content for your business. </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>

                    <div id='faq-4' className='faq-answer-block '>
                    <h2>Can TextMercato offer writing services for small business?</h2>
                    <p>Yes, of course! TextMercato opens its array of reliable content services to businesses of any scale and across industries.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default productDescriptions

