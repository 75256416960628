import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'user',
    initialState: { value: {name:'', email:'', role:'', token: '', user_id: '', id_token:'' } },
    reducers: {
        setLoggedIn: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('email', state.value.email);
            localStorage.setItem('token', state.value.token);
            localStorage.setItem('name', state.value.email);
            localStorage.setItem('role', state.value.role);
            localStorage.setItem('id_token', state.value.id_token);
            localStorage.setItem('user_id', state.value.user_id);
        },
        logOut: (state, action) => {            
            localStorage.clear();
            state.value = {name:'', email:'', role:'', token: '', user_id: '', id_token:'' } 
        },
        isLoggedIn: (state, action) => {
            var token = localStorage.getItem('id_token');
            if(token){
                return true
            }
            return false;
        },
    }
})


export const { setLoggedIn, logOut, isLoggedIn } = authSlice.actions;

export default authSlice.reducer;
