import React, { Component } from 'react';
import Navbar from './global-components/navbar-v2';
import Testimonials from './section-components/testimonial-v5';
import HubspotForm from 'react-hubspot-form'
import { Link } from 'react-router-dom';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import Helmet from 'react-helmet';

class DownloadSamples  extends Component {

     publicUrl = process.env.PUBLIC_URL+'/'
    
    componentDidMount() {
		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/theme.js";
        document.body.appendChild(minscript);

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);

		

		
	}


    render() {
        
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pdf = params.get('pdf_link');
        
        return (
     <div>
            <section className="  go-top">
                <div className="container">
                    <div className="block-title-two text-center mb-0">
                    
                    <h3>Content Samples crafted by TextMercato</h3>
                        
                    <div className='row '>
                        <div className='col-md-12 hubSpotFormWrapper'>
                            <HubspotForm
                                portalId='22122965'
                                formId='a6b2a877-6482-4db4-9bd2-581ac499d298'
                                onSubmit={() => window.$(".hubSpotFormWrapper").append('<a class="thm-btn blog-two__btn " target="_blank" href="'+decodeURIComponent(pdf)+'">Download PDF</a>') }
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                                />
                        </div>

                        </div>
                    </div>
                </div>
            </section>
             
    </div>
        )
    }
}

export default DownloadSamples

