import React from 'react';
import SidebarLeft from './global-components/dashboard/sidebar-left';
import Nav from './global-components/dashboard/nav';
import Footer from './global-components/dashboard/footer';
import DataTable from 'react-data-table-component';
import Helmet from 'react-helmet';

const projects = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/dashboard.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/dashboard.js";
    document.body.appendChild(minscript);

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.id,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Type',
            selector: row => row.type,
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Created on',
            selector: row => row.created_at,
        },
        {
            name: 'Comments',
            selector: row => row.comments,
        },
    ];
    
    const data = [
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },

        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
 
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },

        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
        {
            id: 1,
            name: 'Name of the project',
            type: 'Text',
            status: 'Hold',
            created_at: '12th Nov, 2022',
            comments: 'Lorem Ipsum is simply dummy text '
        },
    ]


    return <div className=''>
        <div className="min-height-300 bg-primary position-absolute w-100"></div>
        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>
        
        <SidebarLeft menu="projects"></SidebarLeft>

        <main className="main-content position-relative border-radius-lg ">

        <Nav></Nav>

    <div className="container-fluid py-4">
      
    
      <div className="row mt-4">
        <div className="col-lg-12 mb-lg-0 mb-4">
          <div className="card ">
            <div className="card-header pb-0 p-3">
              <div className="d-flex justify-content-between">
                <h2 className="mb-2">All Projects</h2>
              </div>
            </div>
            <div className="table-responsive">

            <DataTable
                columns={columns}
                data={data}
                
                pagination
            />

             
            </div>
          </div>
        </div>
    
      </div>
    
        <Footer></Footer>

    </div>
  </main>
       


        
    </div>
}

export default projects

