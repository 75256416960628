import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const ServiceV2 = (props) => {


	let title = "Our Services";
	if(props.title){ title = props.title; }

    return	<section className="service-three  go-top">
		  <div className="container">
		    <div className="block-title-two text-center mb-0">
				<p className='mt-5 mb-0'>{ props.title ? "" : "What we do" }</p>
		      <h3>{ title }</h3>   
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>The recipe for your success not only lies in the creation of top-quality content but also in a stellar content strategy. A copy customized to your goals and a strong distribution to reach the right audience is your gateway to success and this is why we have created the perfect content solution for you.</p>
			

		    <div className="row mt-5 services-block">
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-chess" />
		          <h3><Link to="/platform#strategy" data-target="#strategy">Strategy</Link></h3>
		          <p>Prior to any digital activity, it is important to understand the business, goals, audience, and their preferences</p>
					<ul className="list-style-check mt-3">
						<li>Understand and analyze your needs </li>
						<li>Audit (current status and competition)	 </li>
						<li>A digital roadmap on how, what, where, and when </li>
						<li>Define success metrics </li>
					</ul>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-hourglass" />
		          <h3><Link to="/platform#delivery" data-target="#delivery">Delivery</Link></h3>
		          <p>Choosing the right resources for the right job at the right time with the right brief. This is what we do best</p>
				  <ul className="list-style-check mt-3">
						<li>Choose the best-suited resources </li>
						<li>100 percent original and plagiarism-free content guaranteed </li>
						<li>Unlimited revisions if delivery does not match the agreed brief	 </li>
						<li>Stay updated in real-time via your log-in dashboard </li>
					</ul>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-gauge-high" />
		          <h3><Link to="/platform#performance" data-target="#performance">Performance</Link></h3>
		          <p>One of the most expected trait across any business is continuous improvement across all parameters</p>
				  <ul className="list-style-check mt-3">
						<li>Dedicated Marketer (should you choose this service) </li>
						<li>Monthly reviews and feedback </li>
						<li>Quarterly goal setting (revisit) </li>
						<li>Improvise (all the time!) </li>
					</ul>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


}

export default ServiceV2