import React from 'react';
import Navbar from './global-components/navbar-v2';
import About from './section-components/about';

import AboutV6 from './section-components/about-v6';
import Footer from './global-components/footer-v2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Calltoaction from './section-components/cta';

const BrandIdentity = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
        <Helmet>
            <title>About Us | Text Mercato</title>
            <meta name="About TextMercato" />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3><span>Let the </span>Experts Visualize <span>the Art For You</span></h3>
                    <p>The graphic content requirement for every website is different. The expert team of TextMercato can quickly understand the possibilities and implement artistic changes. Let the experts assist you with professional design services for infographics, ebook designs, images, and more to communicate visually with your target audience.</p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <img width="85%" src={publicUrl+"assets/services/graphics/banner.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section> 
        <section className="service-two service-two__service-page service-two__about-page  go-top">
          <div className="container">
            <div className="row">
            <div className='col-xl-5 text-center'>
                      <img style={{ top:0 }} width="100%" src={publicUrl+"assets/images/logo.svg"} alt="logo" />
                  </div>

              <div className="col-xl-7 my-auto">
                <div className="block-title-two text-left ">
                  <h3><span>Why the name  </span> TextMercato  <span>   </span></h3>
                 
                </div>{/* /.banner-two__content */}
                <p>Our in-house group of writers and editors make long-format eBook write-ups look easy. Our eBook writers have the right skills to write in-depth content made simple to understand for your readers. Choose TextMercato for exquisite eBook designs and interesting eBook content.</p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
              </div>{/* /.col-xl-7 */}

                  


            </div>
          </div>
        </section>

        <section className="about-three about-three__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      
			      
            <div className="col-lg-6">
			        <div className="about-three__content">
			          <div className="block-title-two text-left">
			            <h3>Vission</h3>
			          </div>{/* /.block-title-two */}
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tem
			            por incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
			          <ul className="list-unstyled video-one__list">
			            <li>
			              <i className="far fa-check" />
			              Smashing Book 6 Is Here New Frontiers In Web Design
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Introduction To Animation And The iMessage App Store With Shruggie
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Get Your Mobile Site Ready For The 2018 Holiday ways goods
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Making Distributed Product Teams Work More Efficiently other
			            </li>
			          </ul>{/* /.list-unstyled video-one__list */}
			          
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}


            <div className="col-xl-6">
			        <div className="about-three__content">
			          <div className="block-title-two text-left">
			           
			          <h3>Mission </h3>
			          </div>{/* /.block-title-two */}
			          <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolo
			            remque laudantium totam rem aperiam eaque ipsa quae abillo inventore veritatis et quasi
			            architecto beatae vitae dicta sunt explicabo. </p>
			          <h4 className='text-grey'>Started With Us</h4>
			          <p>Domnis iste natus error sit voluptatem accusantium mque laudantium totam see
			            rem aperiam eaque ipsa quae abillo inventore veritatis et quasi</p>
			        
			          {/* /.thm-btn about-three__btn */}
			        </div>{/* /.about-three__content */}
			      </div>{/* /.col-lg-6 */}
            


			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

      
        <Calltoaction></Calltoaction>
        <Footer />
    </div>
}

export default BrandIdentity

