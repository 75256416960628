import React from 'react';
import Navbar from './global-components/navbar-v2';
import UploadCsv from './global-components/uploadCsv';
import Footer from './global-components/footer-v2';



import Helmet from 'react-helmet';

const bulkUpload = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    <div className="left-side">
                       
                        
                        <div className="steps-content">
                            <h3>Step <span className="step-number">1</span></h3>
                            <p className="step-number-content active">Download the sample template file.</p>
                            <p className="step-number-content d-none">Upload the filled CSV file downloaded in step 1.</p>
                          
                            <p className="step-number-content d-none">Dos / Don’t's </p>                            
                            <p className="step-number-content d-none">Choose the experience and skill level of your creators</p>
                            <p className="step-number-content d-none">Your writing is tailored based on your goals and audiences.</p>
                            
                            <ul className="progress-bar">
                                <li className="active">Download the template</li>
                               
                                <li>Upload the CSV file</li>
                                <li>Review Project Details</li>
                               
                                <li>Dos / Don’t's </li>                                
                                <li>Choose Your Creator </li>
                                <li>Review Order </li>
                            </ul>
                        </div>
                        
                        

                        
                    </div>
                    <div className="right-side post-content">
                        <form>    
                         
                        <div className="main active">
                            
                            <div className="text ">
                                <h2>Download the template</h2>
                                
                            </div>

                           
         

                            


                            <div className="buttons button_space mt-5">
                                
                                <a href="sample-bulk.xlsx" className=" thm-btn sample-file-download">Download Sample File</a>
                                <button className="next_button thm-btn thm-btn2">Already Downloaded? Next Step</button>
                            </div>
                        </div>


                        <div className="main">
                            
                            <div className="text">
                                <h2>Upload the CSV file</h2>
                                <p>Upload the filled CSV file downloaded in step 1</p>
                            </div>

                            

                            <div className="input-text">
                                <div className="input-div">
                                <br></br>
                                    <input type="file" className='bulk_csv_file' name="bulk_csv_file" placeholder='Upload source file' required require />
                                    <br></br><br></br>
                                </div>
                            </div>
                         


                       
                           


                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>


                        <div className="main">
                            
                            <div className="text">
                                <h2>Review Project Details</h2>
                                <p></p>
                            </div>

                            

                            <UploadCsv />
                         


                       
                           


                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>


                        <div className="main">
                            
                            <div className="text">
                                <h2>Project Customisation </h2>
                                <p>To get the best outcome, please give us as relevant details as possible</p>
                            </div>

                            <div className="input-text" data-custom="preferred_intent">
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Project Intent </h5>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="Promotional" /> <span>Promotional</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="Informative" /> <span>Informative</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="Sales Driven" /> <span>Sales Driven</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="Educational" /> <span>Educational</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="SEO Ranking" /> <span>SEO Ranking</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='preferred_intent' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div id='preferred_intent' className="input-text hidden">
                                <div className="input-div">
                                    <input type="text" name="keywords"  />
                                    <span>Custom Project Intent </span>
                                </div>
                            </div>

                           

                            <div className="input-text" data-custom="point_of_view">
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Point of View </h5>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="First person" /> <span>First person</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="Second person" /> <span>Second person</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="Third person" /> <span>Third person</span></label>
                                    <label className='sourceofpublish'><input type='radio'  name='perspective' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div id='point_of_view' className="input-text hidden">
                                <div className="input-div">
                                    <input type="text" name="keywords"  />
                                    <span>Custom Point of View </span>
                                </div>
                            </div>


                            <div className="input-text" data-custom="writing_tone_custom" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Preferred Tone </h5>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Humorous/Entertaining" /> <span>Humorous/Entertaining</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Serious" /> <span>Serious</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Formal" /> <span>Formal</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Casual" /> <span>Casual</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Respectful" /> <span>Respectful</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Irreverant" /> <span>Irreverant</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Matter-of-fact" /> <span>Matter-of-fact</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Enthusiastic" /> <span>Enthusiastic</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div id='writing_tone_custom' className="input-text hidden">
                                <div className="input-div">
                                    <input type="text" name="writing_tone_custom"  />
                                    <span>Custom Preferred Tone </span>
                                </div>
                            </div>

                            
                            <div className="input-text terms-condition-checkbox">
                                <div className="input-div form-group">
                                    <input id='is_meta_description' type="checkbox" name='is_meta_description' value="1" required require />
                                    <label for="is_meta_description">Do you need meta description?</label>

                                    
                                        <div className="input-div hidden">
                                            <textarea name="meta_description"  className='' ></textarea>
                                            <span>Meta description </span>
                                        
                                        </div>
                                 
                                   
                                </div>
                            </div>

                            <div className="input-text terms-condition-checkbox">
                                <div className="input-div form-group">
                                    <input id='is_image' type="checkbox" name='is_image' value="1" required require />
                                    <label for="is_image">image required?</label>

                                        <div className="input-div hidden">
                                            <textarea name="image_info"  className='' ></textarea>
                                            <span>Image detail</span>
                                        </div>

                                   
                                </div>
                            </div>

                            

                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>




                        <div className="main">
                            
                        <h2> Dos / Don’t's </h2>
                            
                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="dos"  placeholder='' ></textarea>
                                    <span>Dos</span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="donts"  placeholder=" " ></textarea>
                                    <span>Don’t's</span>
                                </div>
                            </div>

                            
 
 
 

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="comment"  placeholder='' ></textarea>
                                    <span>Any reference blogs? <br></br>i: Enter links of own or third party blogs from the internet</span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                    <div className="input-div">
                                    <strong>Upload reference</strong><br></br><br></br>
                                        <input type="file" name="reference" placeholder='Upload source file'  />
                                    
                                    </div>
                                </div>

                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>


                        





                        <div className="main ">
                        
                            <div className="text">
                                <h2>Choose the experience and skill level of your creators</h2>
                               
                            </div>

                            <div className='row mt-5'>
                    
                                <label for="generic" className="col-lg-4 col-md-6 content-type active skill_level">
                                    <input id="generic" type='radio' checked="" name="skill_level" value="generic" />
                                    <div className="service-four__single">
                                        <i className="fal fa-dollar-sign" />
                                        <h3><span>Generic Writers</span></h3>
                                        <p>Writers with 1-5 years of content writing experience, and are trained on the core writing skills for text. Generic editing included.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                <label for="advanced" className="col-lg-4 col-md-6 content-type skill_level">
                                    <input id='advanced' type='radio' name="skill_level" value="advanced" />
                                    <div className="service-four__single">
                                        <i className="fal fa-dollar-sign" /> <i className="fal fa-dollar-sign" />
                                        <h3><span  >Advanced Writers </span></h3>
                                        <p>Writers with 3-10 years of writing experience who also understand your industry and audience. Advanced editing included.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                <label for="subject_experts" className="col-lg-4 col-md-6 content-type skill_level">
                                    <input id="subject_experts" type='radio' name="skill_level" value="subject_experts" />
                                    <div className="service-four__single">
                                    <i className="fal fa-dollar-sign" /> <i className="fal fa-dollar-sign" /> <i className="fal fa-dollar-sign" />
                                        <h3><span >Subject Matter Experts</span></h3>
                                        <p>Writers who are veterans in writing for your industry niche and understand your audience thoroughly. SME editing included.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                            </div>


                            
                         
                            <div className="buttons button_space ">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Next Step</button>
                            </div>
                        </div>













                        
                        
                        
                        <div className="main">
                           
                            <div className="text">
                                <h2>Review Order</h2>
                                <p>Your writing is tailored based on your goals and audiences.</p>
                            </div>
                           
                            <div className=" orderReview mt-5">
                                <div className="row">
                                    
                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-pull-12 col-sm-pull-12">
                                        <div className="panel panel-default">
                                           
                                            <div className="panel-body">
                                            <table className="table borderless">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Project Name </strong></td>
                                                        <td><strong>Graphics Name </strong></td>
                                                        
                                                        <td className="text-center"><strong>Quantity</strong></td>
                                                        <td className="text-right"><strong>Cost</strong></td>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-3">
                                                            <div className="media">
                                                                <a className="thumbnail pull-left" href="#">  </a>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading"> Product Name</h6>
                                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                       
                                                        <td className="">Name</td>
                                                        <td className="text-center">1</td>
                                                        <td className="text-right"><i class="fa fa-rupee-sign"></i> 32.99</td>
                                                      
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-3">
                                                            <div className="media">
                                                                <a className="thumbnail pull-left" href="#">  </a>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading"> Product Name</h6>
                                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                       
                                                        <td className="">Name</td>
                                                        <td className="text-center">1</td>
                                                        <td className="text-right"><i class="fa fa-rupee-sign"></i> 32.99</td>
                                                      
                                                    </tr>
                                                </tbody>
                                            </table> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-push-12 col-sm-push-12">
                                        <div className="panel panel-default order-review-total">
                                           
                                            <div className="panel-body">
                                                    <div className="col-md-12">
                                                        <strong>Subtotal </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>200.00</span></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <strong>Tax (GST)</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>50.00</span></div>
                                                    </div>
                                                   
                                                    <div className="col-md-12">
                                                        <strong>Order Total</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>250.00</span></div>
                                                       
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>



                            <div className="buttons button_space mt-5">
                                <button className="back_button thm-btn">Back</button>
                                <button className="submit_button thm-btn">Pay</button>
                            </div>
                        </div>
                        <div className="main">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            
                            <div className="text congrats">
                                <h2>Congratulations!</h2>
                                <p>Thanks Mr./Mrs. <span className="shown_name"></span> your information have been submitted successfully for the future reference we will contact you soon.</p>
                            </div>
                        </div>

                        </form>                    
                    

                    

                    </div>
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
    </div>
}

export default bulkUpload

