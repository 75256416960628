import axios from 'axios';
import React, { useEffect, useState  } from 'react';
import Sidebar from './sidebar';
import { Link, useParams } from "react-router-dom";
import Helmet from 'react-helmet';
import readTime from '../../libs/read-time';

const BlogDetails = () => {

	let { slug } = useParams();
	
	const [error, setError] = useState(null);
	const [blog, setblog] = useState(null);

	useEffect(() => {
		axios
		.get('https://www.textmercato.com/cms/api/blogs?filters[slug][$eq]='+slug+'&populate=Featured,categories,tags')
		.then(response => {
			if(response.data.data[0].attributes){
				setblog(response.data.data[0].attributes);
			}
		})
		.catch((error) => setError(error));
	}, []);
	
	let url = blog ? "https://www.textmercato.com/post/"+blog.Slug : "";
	let title = blog ? blog.Title : "TextMercato";
	

	if (error) {
		return <div>An error occured: {error.message}</div>;
	}

	const Stats = blog ? readTime(blog.Body) : '';
	

    return ( <div>
	<Helmet>
            <title>{ title }</title>
            <meta name="" />
    </Helmet>
	 
     <section className="blog-list blog-details pt-5 mt-3">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="blog-one__single">
				<h1 className=' mb-3 text-primary pt-0'>{ blog ? ( blog.Title  ) : '' }</h1>
				<div className="blog-one__top mt-3 mb-5">
		              {/* <div className="blog-one__author">
		                <img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg"} alt={ imagealt } />
		                <p>Hakila K. Dolika</p>
		              </div> */}
		              <div className="blog-one__social">
		                <span>Share Now</span>
		                <b><i className="far fa-share-alt" /></b>
		                <a target='_blank' href={"https://www.facebook.com/sharer/sharer.php?quote="+title+"&u="+url+""}><i className="fab fa-facebook-f" /></a>
		                <a target='_blank' href={"https://twitter.com/intent/tweet?text="+title+"&url="+url+""}><i className="fab fa-twitter" /></a>
		                <a target='_blank' href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+""}><i className="fab fa-linkedin-in" /></a>
					  	<span className='pl-3 pr-3'> | </span>
						<span className='readtime '>{ Stats }</span>
		              </div>{/* /.blog-one__social */}
		            </div>{/* /.blog-one__top */}
		          <div className="blog-one__image">
				
				{ blog !== null ? (
					
				  <img src={ blog.Featured.data ? ("https://www.textmercato.com/cms" + blog.Featured.data.attributes.url) : '' } alt={ blog.Featured.data ? blog.Featured.data.attributes.name : '' } />
				) : "" }
		          </div>
		          <div className="blog-one__content">
		            
		           
		            { blog !== null ? (
						
						<div className='pt-5' dangerouslySetInnerHTML={{ __html: blog.Body }} />
					) : "" }


		          </div>
				{ blog !== null && blog.tags !== null ? (
					<p className="blog-details__tags">
						<i className="far fa-tags" />
						<span>Tags : </span>
						{ blog.tags.data.map((tag, index) => (
							<Link to={"/blog/tag/" + tag.attributes.Slug  }>{ tag.attributes.Name },</Link>
						))
						}
						
					</p>
					) : ''
				}

		          <br />
		        </div>{/* /.blog-one__single */}
		        
				{/* <div className="blog-post__nav">
		          <div className="blog-post__nav-left">
		            <h3><a href="#">Building Pub Sub Service House Using Node And Redis</a></h3>
		          </div>
		          <div className="blog-post__nav-right">
		            <h3><a href="#">Once Upon Time Using Story Stre For Better Engagement</a></h3>
		          </div>
		        </div> */}
		      
		      </div>{/* /.col-lg-8 */}
		      <Sidebar />
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>
		</div>
		
    )
}

export default BlogDetails;
