import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SidebarLeft extends Component {

	componentDidMount() {
		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/theme.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {
			$(window).scrollTop(0);
		});
	}
  

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

    let activemenu = this.props.menu;
    console.log(activemenu);
		return (
			
			<aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
    <div className="sidenav-header">
      <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <Link onClick={() => {window.location.href="/"} } className="navbar-brand m-0" to="/" target="_blank">
        <img  src={publicUrl+"assets/images/logo-2-1.png"} className="navbar-brand-img h-100" alt="T" />
      </Link>
    </div>
    <hr className="horizontal dark mt-0"></hr>
    <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className={ activemenu === 'dashboard' ? ' nav-link active' : 'nav-link ' } to="/dashboard">
            <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-tachometer text-primary text-sm opacity-10"></i>
            </div>
            <span className="nav-link-text ms-1">Dashboard</span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link className={ activemenu === 'projects' ? 'nav-link active' : 'nav-link' } to="/projects">
            <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-list-alt text-primary text-sm opacity-10"></i>
            </div>
            <span className="nav-link-text ms-1">All Projects</span>
          </Link>
        </li> */}
        
      </ul>
    </div>
    <div className="sidenav-footer mx-3 ">
      
      <Link onClick={() => {window.location.href="/post-project"} } to="/post-project" className="btn btn-primary btn-sm mb-0 w-100" type="button">Create New</Link>
    </div>
  </aside>

		)
	}
}


export default SidebarLeft