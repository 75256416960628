import React from 'react';
import Navbar from './global-components/navbar-v2';
import BlogGridView from './blog-components/blog-grid';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';

const BlogGrid = () => {
    return <div>
        <Navbar />
        
        <BlogGridView />
        <Calltoaction />
        <Footer />
    </div>
}

export default BlogGrid

