import React from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from './section-components/brand-two';
import Calltoaction from './section-components/cta';
import PricingV5 from './section-components/service-v2';
import TestimonialV2 from './section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Carefully Curated Blog Post Writing Service For Your Business</title>
            <meta name="TextMercato is equipped with a network of writers and editors to deliver quality blog posts for your business needs. Choose TextMercato blog post writing services to get content that sells." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                  <h1><span>Content </span> Specialists For Engaging <span> and Informative SEO Blog Posts</span></h1>
                  <p>A business has to keep its website updated with new informational blog posts to increase engagement. Explore our expert website blog writing services for well-written content with the best SEO practices. Choose TextMercato and see your website rank higher.</p>
                  <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
              </div>{/* /.col-xl-7 */}

                  <div className='col-xl-6'>
                      <img width="85%" src={publicUrl+"assets/website-blog.png"} alt="Banner" />
                  </div>


            </div>
          </div>
        </section>
        <Brand></Brand>
        <PricingV5 title="How TextMercato Diliver the content and help business to scale"></PricingV5>

        


        <section id='delivery' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-5 my-auto">
				<img src={publicUrl+"assets/Why-Choose-TextMercato-Blog-Post-Writing-Services.png"} className="about-four__moc  " alt={ imagealt } />
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-7  my-auto">
		        <div className="about-four__content pl-70 pr-0">
		          <div className="block-title-two text-left">
		            <h3>Why Choose TextMercato Blog Post Writing Services  </h3>
		          </div>{/* /.block-title-two text-left */}
		          
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Team of Blog Post Experts </h3><p>Our team delivers plagiarism-free content to make your website stand out from the rest. TextMercato writers and editors will share with you well-researched and engaging content. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Multiple Revisions </h3><p>Our work continues after content delivery, and we will revise the content till it's upto your expectations and shared guidelines.  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Improved Ranking Score </h3><p>Need SEO blogs for your website? Choose TextMercato’s blog post writing services. Our writers and editors follow the best SEO practices to help your site rank higher on search engines.  </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>A Line-up of Subject Matter Experts </h3><p>You can trust us for blogs contents for a wide range of industries and categories. Our network of prolific subject matter expert writers can produce well-researched content.</p></div></div>

              <div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Top-notch Quality  </h3><p>TextMercato can give you the quality content you deserve. Furthermore, professionally managed content operations ensure a continuous contact line between you and our account managers. </p></div></div>

					</div>{/* /.about-four__content */}
                    
                    
                  {/* <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link> */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


		<section id='performance' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Types of Blog Post Writing Services </h3>
		          </div>{/* /.block-title-two text-left */}
		         
					<p className='mt-1 mb-3 lineheight-normal'>TextMercato’s competent team of writers can quickly turn a small brief into a magnificent blog. Our writers can comfortably take up any type of write-up, be it a blog post or writing an article. Here are a few types of blog posts we can work on:</p>
                    
                    <div className="about-four__content small-icons  mt-3">
		            
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Press Releases </h3><p>Want to take your brand to the world stage? Make TextMercato your content writing partner. Announce to your clients about tradeshow details, product updates, or other brand-related information. Our specially curated blog posts will help attract new investors and inform stakeholders. </p></div></div>

						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>SEO Blogs </h3><p>SEO blog posts are efficient marketing tactics. We can optimize a new blog post with proper keywords to increase engagement on your website. Besides bringing traffic to your website, thoughtful, intelligent write-ups will give your visitors a reason to stay and explore. </p></div></div>
						<div className="about-two__box"><div className="about-two__box-icon"><i className="far fa-check"></i></div><div className="about-two__box-content">
							<h3>Opinion-driven Blogs </h3><p>Make your website the center of attention on the Internet. TextMercato’s legion of expert writers can provide quality blog posts with an opinion on a trending topic. These opinion-driven editorials can give your website the traffic it deserves. </p></div></div>
						

					</div>{/* /.about-four__content */}
                    
  
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6 my-auto">
		        <img src={publicUrl+"assets/Types-of-Blog-Post-Writing-Services.png"} className="about-four__moc  " alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>




        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>


        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}

                <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

            </div>{/* /.container-fluid */}
        </section>


        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>How do TextMercato writers and editors work?</a></li>
                    <li><a href='#faq-2'>Will I get plagiarism-free content?</a></li>
                    <li><a href='#faq-3'>Why should I choose TextMercato blog post writing service?</a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>How do TextMercato writers and editors work?</h2>
                    <p> Our writers study the brief provided by you and write accordingly. Our editors then polish and proofread the content for your target audience.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Will I get plagiarism-free content?</h2>
                    <p>TextMercato’s skillful writers can write unique content on a variety of topics with proper research. To ensure that each piece of content is plagiarism-free, our editors use verified plagiarism checkers and tools.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                    <h2>Why should I choose TextMercato blog post writing service?</h2>
                    <p>TextMercato writers have the required understanding of topics to give you well-researched and quality blog posts. We follow the best SEO practices without overstuffing keywords. You’re not only choosing TextMercato writing service; you’re choosing the expertise that comes with it. </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

