import React, { Component } from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-style-four';
import Banner2 from './section-components/banner-style-three';
import Brand from './section-components/brand-two';
import Service from './section-components/service-v10';
import PricingV5 from './section-components/service-v2';
import ServiceV8 from './section-components/service-v8';
import Faqpage from './section-components/faq-v5';
import TestimonialV2 from './section-components/testimonial-v3';
import BlogPost from './section-components/blog-post-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';

import Calltoaction from './section-components/cta';

import { Link } from 'react-router-dom';

const contentTypes   = (Component) => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div id='home' className="homepage">

		<Helmet>
            <title>Create Goal-oriented Content from Experts | Text Mercato</title>
            <meta name="Reach your target audience with the right content. Blogs to SEO-rich articles, product descriptions to newsletters, audio content to video content, explore endless content options." />
        </Helmet>

        <Navbar />
        <section className="banner-two  go-top banner-inner">
            <div className="container">
            <div className="row">
                <div className="col-xl-5 my-auto">
                <div className="banner-two__content">
                    <h1><span>Create </span>Goal-oriented Content <span>with Inputs from Experts</span></h1>
                    <p>Content comes in various shapes, sizes, and formats be it text, graphics, languages, or video. Your target audience today expects you to communicate in a variety of ways. TextMercato is here to make that process faster, better, and cost-efficient for you.  </p>
                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-7 my-auto'>
                <img width="85%" className="about-four__moc "   src={publicUrl+"assets/Content-Builder-Dashboard.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>
        



        <section id='text-content' className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Text Content </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>Over 10000 approved writers across various industries, expertise, and content types at your service.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, our experience says the content consumers have a wide range of requirements. Some want to read an informative blog, some prefer a Q&A platform or a discussion board to address their questions, while others prefer an email newsletter update. 
Did you know there are over 100+ text content types? Let’s discuss what your client preferences are. 
</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						 

					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/services/text-content" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}

              <div className="col-lg-7 my-auto funfact-one funfact-one__home-three">
			        <div className="funfact-one__home-three-wrap service-listing-block">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Blog</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Website Content</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>SEO Articles</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Copywriting</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Ebooks</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Whitepapers</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}


                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Social Media Content</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Product Descriptions</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Thought Leadership</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Buying & Selling Guide</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Newsletters</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Emails</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}


		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<section id='graphics' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Graphics Content </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>“A picture is worth a thousand words”<br></br> Cliche = yes! True = yes!!<br></br> Pictures tend to simplify the verbose nature of the textual content.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, we believe that the graphical representation is one of the leading means of communication. Users comprehend information in various forms - text, graphical, images etc,. and graphics are a great way to form a visual memory and ease of understanding.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						 

					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/services/graphics" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}

              <div className="col-lg-7 my-auto funfact-one funfact-one__home-three ">
			        <div className="funfact-one__home-three-wrap service-listing-block">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Emailer Design</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Posters</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			          

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Infographics</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Website & App Banners</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Blog Images</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Logo Design</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}


                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Presentation Design</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Whitepaper Design</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Newsletter Design</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Ebook Design</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Ads / GDN Banners</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>GIFs</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}


		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<section id='translations' className="about-four   go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Translations </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 font-bold lineheight-normal'>It is 53% more likely that people who see content in their native language are more comfortable transacting. Have you explored other languages yet?</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, we believe that in today’s world localizing your content across different regions gets you the edge and the additional users who are comfortable transacting in their preferred language. Explore our network of global translators to convert your content, make better impressions, and enhance engagement with your users.</p>
                    
                    <div className="about-four__content small-icons two-columns mt-3">
		            
						 

					</div>{/* /.about-four__content */}
                    
                    
                  <Link to="/services/translations" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}

              <div className="col-lg-7 my-auto funfact-one funfact-one__home-three pt-5">
			        <div className="funfact-one__home-three-wrap service-listing-block ">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Website Translation</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Blog Translation</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			          

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Subtitling</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Chat Translation</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>App Translations</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Technical Translations</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                         

                        



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}


		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		<section id='video' className="about-four white-bg  go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-7 my-auto funfact-one funfact-one__home-three">
			        <div className="funfact-one__home-three-wrap service-listing-block">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Explainer Videos</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>How-To Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Educational Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Testimonial Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Company Story Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Video Ads</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}


                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Product Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            
			            
                        <div className="col-md-4 my-auto">
			              <div className="funfact-one__single">
			                <h3>Social Videos</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

						<div className="col-md-4 my-auto">
			              <div className="funfact-one__single">
			                <h3>Tutorials</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
 

                        



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}

		      <div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Video Content  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>“54% of consumers want video content from their brands”, Hubspot.<br></br><br></br> So, what are you waiting for? </p>

					<p className='mt-3 lineheight-normal'>At TextMercato, it’s not just about creating video content, but the What, When, Where, and Why questions need to be answered. For example, Boeing is not selling planes via video content, but it creates an identity and understanding of their brands. The key is to understand the reasons, the limitations and the goal of the video content in relation to your brand.</p>
                    
                  
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>



		



		<section id='audio' className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-7 my-auto funfact-one funfact-one__home-three">
			        <div className="funfact-one__home-three-wrap service-listing-block">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Podcasts</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Audiobooks</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Editorial</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Social media audio</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

                         


                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Incorporate audio into written content</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            
			            
                        <div className="col-md-4 my-auto">
			              <div className="funfact-one__single">
			                <h3>Audio catalogues</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
 

                        



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}

		      <div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Audio Content  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>Audio is not a new phenomenon, it’s always been here, historically through radio, voice notes, and other formats. But, how has all this changed now?</p>

					<p className='mt-3 lineheight-normal'>Podcasts, Audiobooks, Audio articles are now covering a variety of areas but are not limited to pep talks, food, wellness, and more. Think of it? Users consume information at their choice and convenience. Audio content is great on the go content. </p>
                    
                  
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>





		<section id='transcriptions' className="about-four white-bg go-top">
		  <div className="container">
		    <div className="row">


			<div className="col-lg-5  my-auto">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Transcriptions  </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p className='font-16 lineheight-normal font-bold'>Do you have lectures, interviews, business meetings, and other formats that need transcribing? Do not worry we have you covered.</p>

					<p className='mt-3 lineheight-normal'>At TextMercato, our team of experts have hands-on experience in delivering transcription projects with a robust quality checking process within the agreed SLA. The ability to design a team with a dedicated Account Manager for your project ensures right delivery at the right time.</p>
                    
                  
                    
                    
                  <Link to="/get-started" className="thm-btn banner-two__btn mt-5">Learn More <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}


		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}

			<div className="col-lg-7 my-auto funfact-one funfact-one__home-three pt-5">
			        <div className="funfact-one__home-three-wrap service-listing-block">
			          <div className="row ">
			            
                        <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3><span className="">Captions</span></h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Subtitles</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}

			            <div className="col-md-4">
			              <div className="funfact-one__single">
			                <h3>Voice Over</h3>
			              </div>{/* /.funfact-one__single */}
			            </div>{/* /.col-md-6 */}
			            
                        
 

                        



			          </div>{/* /.row */}
			        </div>{/* /.funfact-one__home-three-wrap */}
			      </div>{/* /.col-lg-6 */}

		      
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>





		



		

 

 
 

 
 


          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default contentTypes 

