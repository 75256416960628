import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Banner from '../section-components/banner-style-four';
import Footer from '../global-components/footer-v2';
import Helmet from 'react-helmet';
import Brand from '../section-components/brand-two';
import Calltoaction from '../section-components/cta';
import PricingV5 from '../section-components/service-v2';
import TestimonialV2 from '../section-components/testimonial-v3';

import { Link } from 'react-router-dom';

const websiteBlog = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return <div id='home'  className="homepage">

		<Helmet>
            <title>Finance | Text Mercato</title>
            <meta name="" />
        </Helmet>

        <Navbar />
        <Banner />
        <Brand></Brand>
        <PricingV5 title="Industry Specific Content Journey with TextMercato "></PricingV5>

        <section className="service-three white-bg   go-top">
		  <div className="container text-center">

		  <div className="row ">
		  <div className="col-md-8 offset-2 ">
		    <div className="block-title-two text-center mb-0">
				
		      <h3>Heading for for Types of content are useful to this perticular industry</h3>
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>Creation of content is about connecting and adding value to your users. So, we focus on knowing what your users want (SEO, User Research), understanding their behavior (analytics) and most importantly delivering the right content, at the right time, at the right place.</p>
		</div>
		</div>

		    <div className="row mt-5">
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-pen-clip" />
		          <h3><a className='scroll-to-target' data-target="#digital-content" href='#'  >Type 1</a></h3>
		          <p>Manage all your content on our all-in-one-platform. Place your orders, track progress, and manage the project with the support of a stellar customer service team.</p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-invoice" />
		          <h3><a   className='scroll-to-target' data-target="#managed-accounts"  href='#'>Type 2</a></h3>
		          <p>Have large needs or need someone to help? We have you covered. Just write to us, and our Account Managers will get back to you.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-folder-tree" />
		          <h3><a  className='scroll-to-target' data-target="#seo-intelligence"  href='#'>Type 3</a></h3>
		          <p>TextMercato can help you choose the right trends, keywords, topics, and  customized guidelines to meet your content  goals.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}


              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-file-pen" />
		          <h3><a  className='scroll-to-target' data-target="#ai-copy-writer"  href='#'>Type 4</a></h3>
		          <p>Need something quick, good, and publish ready? Our AI copywriter is at your disposal.</p>
					
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-globe" />
		          <h3><a  className='scroll-to-target' data-target="#publishing"  href='#'>Type 5</a></h3>
		          <p>Not only can we help create content, but also help you publish. (Coming soon - WordPress Integration)</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      
              <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-chart-line" />
		          <h3><a  className='scroll-to-target' data-target="#analytics"  href='#'>Type 6</a></h3>
		          <p>Our ability to share data, discuss the outcomes, and improvise for better results makes us an bankable partner.</p>
				  
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}

		      
		    </div>{/* /.row */}

             <a class="thm-btn banner-two__btn btn-big mt-5" href="/samples">Check out Samples <i class="fa fa-angle-double-right"></i></a>

		  </div>{/* /.container */}
		</section>

        <section className="cta-two  go-top" >
		  <div className="container text-center">
		    <h3>Upgrade your content <br />
		      with TextMercato</h3>
		    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
		  </div>{/* /.container */}
		</section>

        <TestimonialV2 onltTestimonial="yes"></TestimonialV2>



        





       



        




        <section className="about-four   go-top">
		  <div className="container">
		    <div className="row">

			<div className="col-lg-12 my-auto mb-5">
			<div className="about-four__content">
		          <div className="block-title-two text-center">
		            <h3>Reffering to numbers achivement of TM </h3>
		          </div>{/* /.block-title-two text-left */}
				  </div>
			</div>{/* /.col-lg-6 */}

		      <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>2500000</h3>
		          <p className='font-16 lineheight-normal font-bold'>Number of words/content writen</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>6x</h3>
		          <p className='font-16 lineheight-normal font-bold'>ROI generated</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}


			  <div className="col-lg-4  my-auto achivements-block mt-5">
		        <div className="about-four__content text-center mt-5">
		           <h3>45%</h3>
		          <p className='font-16 lineheight-normal font-bold'>Avg. audience increased</p>
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}



		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        <section className="faq-two">
            <div className="container">
            <div className="row">
                
            
                
                
                <div className="col-lg-5">
                <div className="faq-two__content">
                    <div className="block-title-two text-left">
                    
                    <h3>FAQs</h3>
                    </div>{/* /.block-title-two */}

                    <ul className='faq-questions-list'>
                    <li><a href='#faq-1'>What do I get if I choose writing services for my corporate websites?</a></li>
                    <li><a href='#faq-2'>Why choose TextMercato as my blog writing service partner?</a></li>
                    <li><a href='#faq-3'>Does TextMercato offer a free trial?</a></li>
                    
                    </ul>

                    


                    
                </div>{/* /.faq-two__content */}
                </div>{/* /.col-lg-6 */}


                <div className="col-lg-7 d-flex justify-content-center">
                
                <div className="inner">
                    <div id='faq-1' className='faq-answer-block active'>
                    <h2>What do I get if I choose writing services for my corporate websites?</h2>
                    <p> We provide you with well-researched and carefully-crafted website content and a well-written piece that is SEO-friendly and meets your expectations.
    </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    
                    </div>
                    <div id='faq-2' className='faq-answer-block '>
                    <h2>Why choose TextMercato as my blog writing service partner?</h2>
                    <p>Our team of skilled blog writers can easily create and manage blogs. Expertly crafted, high-quality information that is current, fresh, innovative, and well-researched.</p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                    <div id='faq-3' className='faq-answer-block '>
                    <h2>Does TextMercato offer a free trial?</h2>
                    <p>You can download the existing samples from our sample repository or get in touch with our representative to share your expectations. </p>
                    <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                    </div>
                
                    
                </div>{/* /.inner */}
                    
                
                </div>{/* /.col-lg-6 */}




                
            </div>{/* /.row */}
            </div>{/* /.container */}
        </section>
        




          


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default websiteBlog

