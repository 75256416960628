import React, { useEffect, useState  } from 'react';
import Navbar from './global-components/navbar-v2';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';
import { readRemoteFile  } from 'react-papaparse';

import Helmet from 'react-helmet';
import Page_header from './global-components/page-header';

const Glosary = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let csvFile = "glosary.csv";

    const [data, setData] = useState(null);
    const [categories, setCategories] = useState(null);
    const [glosaryCategry, setGlosaryCategry] = useState(null);
    // const [SampleDataCount, setuniqueSampleData] = useState(null);

    const uniqueglosaryCategry = [];
    const uniqueCategories = [];
    const getData = [];

    useEffect(() => {
        readRemoteFile(csvFile,
          {
            complete: (results) => {

  
              results.data.map(parsedData => { 
                  if (parsedData[1] !== undefined && uniqueCategories.indexOf(parsedData[2]) === -1) {
                    uniqueCategories.push(parsedData[2])
                    uniqueglosaryCategry.push(parsedData[2])                  
                  }

                  

                  if (parsedData[1] !== undefined ) {
                    getData.push(parsedData)
                  }
                  // SampleDataCountTotal[parsedData[1]] =  SampleDataCountTotal[parsedData[1]] ? SampleDataCountTotal[parsedData[1]] + 1 : 1 
              });
              setGlosaryCategry(uniqueglosaryCategry);
              setData(getData);
  
               
            },
          }
        );
      }, []);


    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    
    return <div>

        <Helmet>
            <title>Glossary</title>
            <meta name="" />
        </Helmet>

        <Navbar />
        <Page_header headertitle="Glossary"  />
        
        <section className="faq-one pt-5 faq-one__faq-page">
        
          <div className="container">
            <div className="row">
            



              

                <div className="faq-one__block">
                  
                  <div className="accrodion-grp row" data-grp-name="faq-one-accrodion">
                  {data ? (
                    
                    data.map((parsedData, index) => (
                        
                            <div className='col-lg-4 mb-4'>
                                <div className="accrodion ">
                                <div className="accrodion-title ">
                                    <h4>{ parsedData[0] }</h4>
                                </div>
                                <div className="accrodion-content collapse">
                                    <div className="inner">
                                        <p>{ parsedData[1] }</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                    )
                    )
                  ) : "Loading"
                    }

                    
                   
                    

                    

                    
                    
                  </div>
                </div>{/* /.faq-one__block */}
           
   

               


 




             
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>
        
		

		
        <Calltoaction />
        <Footer />
    </div>
}

export default Glosary

