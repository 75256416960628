import React, { useState } from 'react';
import SidebarLeft from './global-components/dashboard/sidebar-left';
import Nav from './global-components/dashboard/nav';
import Footer from './global-components/dashboard/footer';


import DataTable from 'react-data-table-component';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';



const Dashboard = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    const FilterComponent = ({ filterText, onFilter, onClear }) => (

      <>
        <div class="input-group search-box pull-right"><span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
        <TextField
    
          id="search"    
          type="text"
          placeholder="Type here..."
          className='form-control'
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
    
        />
        </div>
        
    
      </>
    
    );

    const TextField = styled.input`

	height: 32px;

	width: 200px;

	border-radius: 3px;

	border-top-left-radius: 5px;

	border-bottom-left-radius: 5px;

	border-top-right-radius: 0;

	border-bottom-right-radius: 0;

	border: 1px solid #e5e5e5;

	padding: 0 32px 0 16px;

	&:hover {

		cursor: pointer;

	}

`;
const Button = () => <button className=' ' type="button"></button>;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;




    const cssUrl =   publicUrl + "assets/css/color-2.css";
    const style = document.createElement("link");
    style.href = cssUrl;
    style.rel = "stylesheet";
    style.async = true;
    document.head.appendChild(style);

    const logincssUrl =   publicUrl + "assets/css/dashboard.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/dashboard.js";
    document.body.appendChild(minscript);

    

    const columns = [
      {
          name: 'Order ID',
          sortable: true,
          selector: row => row.id,
      },     
      {
          name: 'Name of the order',
          sortable: true,
          selector: row => row.name,
      },
      {
        name: 'Status',
        sortable: true,
        selector: row => row.status,
    },
    {
          name: 'Date of the order',
          selector: row => row.created_at,
    },
    {
      name: 'Actions',
      button: true,
      left: true,
      cell: row => (
         <Link className='dataTableBtn' to={"/order-detail/"+row.id} rel="noopener noreferrer"> Order Details</Link>
      ),
    },
    {
      name: '',
      button: true,
      cell: row => (
          
          row.status == "Order Completed" ? (
          <><a className='dataTableBtn' href="#" rel="noopener noreferrer"><i className='fa fa-check'></i> Apprved</a></>
          ) : ""
      ),
    },
    {
      name: '',
      button: true,
      cell: row => (
          
          row.status == "Order Completed" ? (
          <><a className='dataTableBtn' href="#" rel="noopener noreferrer"><i className='fa fa-download'></i> Download</a></>
          ) : ""
      ),
    },
  ];
  
  const data = [
      {
          id: 1,
          name: 'Text',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 2,
          name: 'Graphic',
          type: 'Text',
          status: 'Order Completed',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 3,
          name: 'Translation',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 4,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 5,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Pending',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },

      {
          id: 6,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 7,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Completed',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 8,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 9,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order rejected',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },

      {
          id: 10,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 11,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 12,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },

      {
          id: 13,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 14,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
      {
          id: 15,
          name: 'Name of the project',
          type: 'Text',
          status: 'Order Hold',
          created_at: '12th Nov, 2022',
          comments: 'Lorem Ipsum is simply dummy text '
      },
  ]

  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = data.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);


  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};
		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);

	}, [filterText, resetPaginationToggle]);

    return <div className=''>
        <div className="min-height-300 bg-primary position-absolute w-100"></div>
        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>
        
        <SidebarLeft menu="dashboard"></SidebarLeft>

        <main className="main-content position-relative border-radius-lg ">

        <Nav></Nav>

    <div className="container-fluid py-4">

      <div className="row mt-4">
        <div className="col-lg-12 mb-lg-0 mb-4">
          <div className="card ">
            <div className="card-header pb-0 p-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-2">Orders</h5>

                <div className='filterBtns'>
                  <button className='btn btn-sm btn-primary-outline'>Approval Pending</button> &nbsp; &nbsp;
                  <button className='btn btn-sm  btn-primary-outline'>Rework</button> &nbsp; &nbsp;
                  <button className='btn btn-sm  btn-primary-outline'>Completed</button>  &nbsp; &nbsp;
                  <button className='btn btn-sm  btn-primary-outline'>Project Saved (not yet paid) </button>
                </div>

                { subHeaderComponentMemo }
              </div>
            </div>
            <div className="table-responsive">

            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                
                highlightOnHover
		            pointerOnHover
                selectableRows
                
            />

              
            </div>
          </div>
        </div>
    
      </div>
    
        <Footer></Footer>

    </div>
  </main>
       


        
    </div>
}

export default Dashboard

