import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';


import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const PostProject = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'


    const logincssUrl =   publicUrl + "assets/css/login.css";
    const loginstyle = document.createElement("link");
    loginstyle.href = logincssUrl;
    loginstyle.rel = "stylesheet";
    loginstyle.async = true;
    document.head.appendChild(loginstyle);

    
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/post-project.js";
    document.body.appendChild(minscript);



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    <div className="left-side hidden">
                       
                        
                        <div className="steps-content">
                            <h3>Step <span className="step-number">1</span></h3>
                            <p className="step-number-content active">Enter your project information.</p>
                            <p className="step-number-content d-none">Let's get started with the basic details to create your project.</p>
                            <p className="step-number-content d-none">Customize your items with all the details.</p>
                            <p className="step-number-content d-none">Provide all the writing preferences for this project.</p>
                            <p className="step-number-content d-none">Dos / Don’t's </p>                            
                            <p className="step-number-content d-none">Choose the experience and skill level of your creators</p>
                            <p className="step-number-content d-none">Your writing is tailored based on your goals and audiences.</p>
                            
                            <ul className="progress-bar">
                                <li className="active">What do you want to create today?</li>
                                <li>Create a Project</li>
                                <li>Add Project Details</li>
                                <li>Project Preferences</li>
                                <li>Dos / Don’t's </li>                                
                                <li>Choose Your Creator </li>
                                <li>Review Order </li>
                            </ul>
                        </div>
                        
                        

                        
                    </div>
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                        <div className="main active no-shadow">
                        
                            <div className="text text-center mb-4 mt-0">
                                <h3 className='color-blue'>What Do You Want To Create Today? </h3>
                                <p className='sub-heading-link text-center'>Choose from Text (blogs, articles, SEO content, Website Copy etc), Graphics (add, Add, Add, Add) <br></br>to Translations (In over 50 languages) </p>
                            </div>

                            <div className='row mt-3'>
                    
                                <label for="text" className="col-lg-4 col-md-6  content-type ">
                                    <input id="text" data-message="Please select atleast one option" type='radio'  required require name="type" value="text" />
                                    <div className="service-four__single">
                                        <i className="fal fa-file-lines" />
                                        <h3><span>Text Content</span></h3>
                                        <p>Over 10K approved writers are ready to share their expertise on your requirements. Be it Blogs, Articles, SEO, Social Media Posts, Emailers,  Website Copy, or any other content.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                <label  for="translation" className="col-lg-4  col-md-6 content-type">
                                    <input id='translation' data-message="Please select atleast one option" type='radio' required require name="type" value="translation" />
                                    <div className="service-four__single">
                                        <i className="fal fa-language" />
                                        <h3><span  >Translation </span></h3>
                                        <p>A majority of readers are more comfortable consuming content in their regional language. Our translation services in more than 50+ languages helps you connect better with your target audience.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                                <label for="graphics" className="col-lg-4 col-md-6  content-type">
                                    <input id="graphics" data-message="Please select atleast one option" type='radio' name="type" required require value="graphics" />
                                    <div className="service-four__single">
                                        <i className="fal fa-bezier-curve" />
                                        <h3><span >Graphics</span></h3>
                                        <p>A good graphic can explain simple or complex ideas in an easy-to-understand, recallable way. Use infographics, illustrations, images, and more to communicate with your customers.</p>
                                    </div>{/* /.service-four__single */}
                                </label>{/* /.col-lg-3 col-md-6 */}

                            </div>



                            <div className="buttons button_space">
                                <button className="next_button thm-btn mt-4">Continue</button>
                            </div>
                             
                            
                         
                           
                        </div>
                        <div className="main">
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'>Enter Project Name and Industry</h3>
                            </div>
                            <div className="input-text">
                                <div className="input-div">
                                    <label>Enter Project name *</label>
                                    <input type="text" data-message="Enter Project name" name='project_name' required require />
                                </div>
                                
                            </div>
                            
                            <div className="input-text">
                                <div className="input-div">
                                    <label>Choose Industry * </label>
                                    <select name='industry' required require className=''>
                                        <option value=''>Select </option>
                                        <option value="Data and Deep-Technology">Data and Deep-Technology</option>
                                        <option value="IT, Software, Infrastructure and Application Development">IT, Software, Infrastructure and Application Development</option>
                                        <option value="Corporate and Entrepreneuship">Corporate and Entrepreneuship</option>
                                        <option value="Banking, Finance and Insurance (BFSI)">Banking, Finance and Insurance (BFSI)</option>
                                        <option value="Government and Politics">Government and Politics</option>
                                        <option value="Art and Design">Art and Design</option>
                                        <option value="Media & Entertainment">Media & Entertainment</option>
                                        <option value="Lifestyle">Lifestyle</option>
                                        <option value="Real Estate & Construction">Real Estate & Construction</option>
                                    </select>
                                </div>
                            </div>

                           

 

                           


                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Continue</button>
                            </div>
                        </div>


                        <div className="main">
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'>Your Requirements </h3>
                                <p className='sub-heading-link'>Choose a single piece or multiple pieces of content (one per line). </p>

                                {/* <Link className='upload-bulk thm-btn thm-btn2' to="/bulk-upload">Bulk Upload</Link> */}
                            </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            <div className='requirements  '>

                            <div className='requirement_summary '>
                                <table  className="table borderless">
                                <thead>
                                    <tr>
                                        <td className='topic_col'><strong>Title/Topic</strong></td>
                                        <td className='content_type_col'><strong>Content Type  </strong></td>
                                        <td className="category_col"><strong>Creator Category</strong></td>
                                        <td className="words_col"><strong>Words</strong></td>
                                        <td className="cost_col"><strong>Price</strong></td>
                                        <td className="cost_col"><strong>Delivery</strong></td>
                                        <td className='text-right'></td>
                                    </tr>
                                </thead>
                                </table>
                                </div>
                                </div>
                           

                            <div className='requirements aritcle-requirment'>

                            <div className='requirement_summary info'>
                                <table  className="table borderless">
                                
                                        <tbody>
                                            <tr>
                                                <td className="topic_col">
                                                    <div className="media">
                                                        <a className="thumbnail pull-left" href="#">  </a>
                                                        <div className="media-body">
                                                            <strong className="media-heading expand-requirement"><i class="fa fa-angle-down"></i> Article 1</strong>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td className="content_type_col">Article's Name</td>
                                                <td className="category_col">Blogs/Articles </td>
                                                
                                                <td className="words_col">500</td>
                                                <td className="cost_col"><i class="fa fa-rupee-sign"></i> 32.99</td>
                                                <td className="words_col">3-5 days</td>
                                                <td className="text-right reviewOrderAction"><a className='deleteRequirment' href='#'><i class="fa fa-times"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                
                                <div className='requirment_edit'>


                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Title/Topic *</label>
                                        <input placeholder='Example: "10 things to do in London' type="text" className='updateTabTitle' name="title" required require />
                                        {/* <p className='note'>"</p> */}
                                    </div>
                                </div>

                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Content Type * </label>
                                        <select name='industry' data-other="Content_type_other" required require className=''>
                                            <option value=''>Select </option>
                                            <option value="Blogs/Articles ">Blogs/Articles </option>
                                            <option value="SEO Articles">SEO Articles</option>
                                            <option value="Website Content ">Website Content </option>
                                            <option value="Landing Page">Landing Page</option>
                                            <option value="other">Other (Enter Custom)</option>
                                        </select>
                                    </div>
                                </div>
                            
                                <div className="input-text">
                                    <div className="input-div">
                                        <label>Creator Category * </label>
                                        <select name='industry' data-other="Creator_Category_other" required require className=''>
                                            <option value=''>Select </option>
                                            <option value="Blogs/Articles ">Blogs/Articles </option>
                                            <option value="SEO Articles">SEO Articles</option>
                                            <option value="Website Content ">Website Content </option>
                                            <option value="Landing Page">Landing Page</option>
                                            <option value="other">Other (Enter Custom)</option>
                                        </select>
                                    </div>
                                </div>

                                

                                <div className="input-text">
                                <div className="input-div">
                                    <label>Keywords </label>
                                    <input type="text" name="keywords"  />
                                    
                                    </div>
                                </div>


                                <div className="input-text" data-custom="word_count_custom" >
                                    <div className="input-div">
                                        <label>Words * </label>
                                        <select name='words' data-other="custom_count" required require className=''>
                                            <option value=''>Select </option>
                                            <option value="500">500</option>
                                            <option value="700">700</option>
                                            <option value="1500">1500</option>
                                            <option value="2000">2000</option>
                                            <option value="2500">2500</option>
                                            <option value="custom">Custom</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="input-text"  >
                                    <div className="input-div">
                                        <label>Need images  </label>
                                        <select name='no_of_images'  className=''>
                                            <option value=''>Select </option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="input-text"  >
                                <div className="input-div ">
                                    <label>Additional Comments </label>
                                    <textarea name="meta_description"  className='' placeholder='Enter any specific article requirement here, general requirements are captured in the following screens' ></textarea> 
                                    </div>
                                </div>


                            </div>
                            </div>
                            

                            

                            </div>


                            <div className='add-more-requirements mb-5'><i class="fa fa-plus"></i> Add More</div>
                           
                            <div className='col-md-12 summary-total'>
                                <div className="panel panel-default order-review-total">
                                    <h4>Your Order </h4>
                                    <div className="panel-body">
                                            <div className="col-md-12">
                                                <strong>Total  </strong>
                                                <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>200.00</span></div>
                                            </div>
                                            <div className="col-md-12">
                                                <strong>Tax/GST </strong>
                                                <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>50.00</span></div>
                                            </div>
                                            
                                            <div className="col-md-12">
                                                <strong>To Pay </strong>
                                                <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>250.00</span></div>
                                                
                                            </div>
                                            
                                    </div>
                                    
                                </div>
                                <p className='note'>The prices here are for text content, if images are selected it will be updated on the next screens</p>
                                
                            </div>

                            



                        </div>

                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Continue</button>
                            </div>
                            <p className=''>Since multiple content types have been chosen, the team will get in touch with you for updating rest of the content briefs</p>
                        </div>






                        <div className="main">
                            
                            <div className="text mb-4 mt-0">
                                <h3 className='color-blue'> Enter Your Project Details   </h3>
                                <p>To get the best outcome, please give us as relevant details as possible</p>
                            </div>

                            <div className="input-text radio-group" data-custom="goal">
                                <div className="input-div">
                                    <label>Project Goal </label><br></br>
                                    <label className='sourceofpublish'><input type='checkbox' name='goal' value="Promotional/Leads" /> <span>Promotional/Leads</span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='goal' value="SEO/Traffic " /> <span>SEO/Traffic </span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='goal' value="Educational/Informative " /> <span>Educational/Informative </span></label>
                                    <label className='sourceofpublish'><input type='checkbox' name='goal' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>
                            

                            <div id='goal' className="input-text hidden">
                                <div className="input-div">
                                <label>Custom Project Goal </label>
                                    <input type="text" name="goal_custom"  />
                                </div>
                            </div>

                           

                            <div className="input-text radio-group" data-custom="point_of_view">
                                <div className="input-div">
                                    <label>Point of View </label><br></br>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="First person" /> <span>First person</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="Second person" /> <span>Second person</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='perspective' value="Third person" /> <span>Third person</span></label>
                                </div>
                            </div>



                            <div className="input-text radio-group" data-custom="writing_tone_custom" >
                                <div className="input-div">
                                    <label>Tone of voice </label><br></br>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Formal" /> <span>Formal</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Humorous/Entertaining" /> <span>Humorous/Entertaining</span></label>
                                    
                                   
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Informal / Casual" /> <span>Informal / Casual </span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Optimistic " /> <span>Optimistic </span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Friendly " /> <span>Friendly </span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Assertive " /> <span>Assertive </span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Curious" /> <span>Curious</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Worried" /> <span>Worried</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Encouraging " /> <span>Encouraging </span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="Worried" /> <span>Surprised</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='writing_tone' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>

                            <div id='writing_tone_custom' className="input-text hidden">
                                <div className="input-div">
                                <label>Custom Preferred Tone </label>
                                    <input type="text" name="writing_tone_custom"  />
                                    
                                </div>
                            </div>









                            <div className="input-text radio-group" data-custom="structure_preference_custom" >
                                <div className="input-div">
                                    <label>Do you have a structure preference?  </label><br></br>
                                    <label className='sourceofpublish'><input type='radio' name='structure_preference' value="Sub-headings" /> <span>Sub-headings</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='structure_preference' value="Bulleted" /> <span>Bulleted</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='structure_preference' value="custom" /> <span>Other</span></label>
                                </div>
                            </div>

                            <div id='structure_preference_custom' className="input-text hidden">
                                <div className="input-div">
                                <label>Custom Preferred Tone </label>
                                    <input type="text" name="structure_preference_custom"  />
                                    
                                </div>
                            </div>








                            <div className="input-text">
                                <div className="input-div">
                                <label>Dos</label>
                                    <textarea name="dos"  placeholder='' ></textarea>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                <label>Don’t's</label>
                                    <textarea name="donts"  placeholder=" " ></textarea>
                                    
                                </div>
                            </div>


                            <div className="input-text">
                                <div className="input-div">
                                    <label>Any Reference Blogs/Links</label>
                                    <textarea name="reference_link"  placeholder='' ></textarea>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <label>Tell us about your readers? </label>
                                    <textarea name="about_readers"  placeholder='' ></textarea>
                                </div>
                            </div>
                            

                            <div className="input-text">
                                    <div className="input-div">
                                    <label>Any Guidelines</label><br></br>
                                    <input type="file" name="reference" placeholder='Upload source file'  />
                                    
                                    </div>
                                </div>

                            
                            <div className="input-text terms-condition-checkbox">
                                <div className="input-div form-group">
                                    <input id='is_meta_description' type="checkbox" name='is_meta_description' value="1"  />
                                    <label for="is_meta_description">Do you need meta description for all? </label>

                                </div>
                            </div>


                            

                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Continue</button>
                            </div>
                            <p className=''>Since multiple content types have been chosen, the team will get in touch with you for updating rest of the content briefs</p>
                        </div>




                        <div className="main">
                            
                        <div className="text mb-4 mt-0">
                                <h3 className='color-blue'> Images   </h3>
                                
                            </div>

                            
                            <div className='requirements '>
                                <div className='requirement_summary info'>
                                    <table  className="table borderless">
                                    
                                            <tbody>
                                                <tr>
                                                    <td className="topic_col">
                                                        <div className="media">
                                                            <a className="thumbnail pull-left" href="#">  </a>
                                                            <div className="media-body">
                                                                <strong className="media-heading expand-requirement"><i class="fa fa-angle-down"></i> Article 1</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td className="content_type_col">Article's Name</td>
                                                    <td className="category_col">Blogs/Articles </td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='requirment_edit images_edit'>
                                        <div className="input-text radio-group" >
                                            <div className="input-div choose_image_option">
                                                
                                                <label className='sourceofpublish'><input type='radio' name='image[0]' required require value="Your Image" /> <span>Your Image</span></label>
                                                <label className='sourceofpublish'><input type='radio' name='image[0]' required require value="Image from Text Mercato" /> <span> Image from Text Mercato</span></label>
                                            </div>
                                        </div>


                                        <div className='own_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                    <strong>Upload your image *</strong><br></br><br></br>
                                                        <input type="file" name="reference" placeholder='Upload source file'  />
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='textMercato_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Banner (enter size) *</label>
                                                        <input type="text" name="title"  />
                                                    </div>
                                                </div>
                                                <div className="input-text"  >
                                                        <div className="input-div">
                                                            <label>Image Ratio * </label>
                                                            <select name='structure_preference'  data-other="structure_preference_other"  className=''>
                                                                <option value=''>Select </option>
                                                                <option value="Potrait  ">Potrait  </option>
                                                                <option value="Landscape">Landscape</option>
                                                                <option value="Square">Square</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Comments </label>
                                                        <textarea name="title"  />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>







                                <div className='requirements '>
                                <div className='requirement_summary info'>
                                    <table  className="table borderless">
                                    
                                            <tbody>
                                                <tr>
                                                    <td className="topic_col">
                                                        <div className="media">
                                                            <a className="thumbnail pull-left" href="#">  </a>
                                                            <div className="media-body">
                                                                <strong className="media-heading expand-requirement"><i class="fa fa-angle-down"></i> Article 1</strong>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td className="content_type_col">Article's Name</td>
                                                    <td className="category_col">Blogs/Articles </td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>

                                    <div className='requirment_edit images_edit'>
                                        <div className="input-text radio-group" >
                                            <div className="input-div choose_image_option">
                                                
                                                <label className='sourceofpublish'><input type='radio' required require name='image[1]' value="Your Image" /> <span>Your Image</span></label>
                                                <label className='sourceofpublish'><input type='radio' required require name='image[1]' value="Image from Text Mercato" /> <span> Image from Text Mercato</span></label>
                                            </div>
                                        </div>


                                        <div className='own_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                    <strong>Upload your image *</strong><br></br><br></br>
                                                        <input type="file" name="reference" placeholder='Upload source file'  />
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='textMercato_image hidden'>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Banner (enter size) *</label>
                                                        <input type="text" name="title"  />
                                                    </div>
                                                </div>
                                                <div className="input-text"  >
                                                        <div className="input-div">
                                                            <label>Image Ratio * </label>
                                                            <select name='structure_preference' data-other="structure_preference_other"  className=''>
                                                                <option value=''>Select </option>
                                                                <option value="Potrait  ">Potrait  </option>
                                                                <option value="Landscape">Landscape</option>
                                                                <option value="Square">Square</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                <div className="input-text">
                                                    <div className="input-div">
                                                        <label>Comments </label>
                                                        <textarea name="title"  />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                            
 
 
 

                           

                            

                          
                            <div className="buttons button_space">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Continue</button>
                            </div>
                        </div>


                        





                        <div className="main order-review-section">
                        
                            <div className="text">
                                <h3 className='color-blue'>Order Review</h3>
                               
                            </div>

                            
                            
                            <div className=" orderReview mt-2 confirmationPage">
                                <div className="row">
                                    
                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-pull-12 col-sm-pull-12">
                                        <div className="panel panel-default requirements">
                                           
                                            <div className="panel-body requirement_summary ">
                                            <table id='grid-table' className="table borderless grid-table">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Content type </strong></td>
                                                        <td className=''><strong>Title  </strong></td>
                                                        <td className=""><strong>Words</strong></td>
                                                        <td className=''><strong>Need images  </strong></td>
                                                        <td className=""><strong>Cost</strong></td>
                                                        <td className=""><strong>Delivery</strong></td>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="">Blog</td>
                                                        <td className="">London Travel </td>
                                                        <td className="">500</td>
                                                        <td className="">4</td>
                                                        <td className=""><i class="fa fa-rupee-sign"></i> 4,350</td>
                                                        
                                                        <td className="">3-5 days</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td className="">Blog</td>
                                                        <td className="">London Travel </td>
                                                        <td className="">500</td>
                                                        <td className="">4</td>
                                                        <td className=""><i class="fa fa-rupee-sign"></i> 4,350</td>
                                                        
                                                        <td className="">3-5 days</td>
                                                        
                                                    </tr>
                                                    
                                                </tbody>
                                            </table> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-push-12 col-sm-push-12 summary-total">
                                        <div className="panel panel-default order-review-total">
                                           
                                            <div className="panel-body">
                                                    <div className="col-md-12">
                                                        <strong>Total  </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>6,910</span></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <strong>GST @18%</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>1,244</span></div>
                                                    </div>
                                                   
                                                    <div className="col-md-12">
                                                        <strong>To Pay </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>8,154</span></div>
                                                       
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>




                            
                         
                            <div className="buttons button_space ">
                                <button className="back_button thm-btn">Back</button>
                                <button className="next_button thm-btn">Confirm & Pay </button>
                            </div>
                        </div>


                    
                        
                        
                      
                        <div className="main">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            
                            <div className="text congrats">
                                <h3 className='color-blue'>Payment Successful</h3>
                                <p> Your order is now confirmed </p>
                                <Link className='thm-btn inline' to='/'>Dashboard</Link>
                                <Link className='thm-btn' to='/'>Order More</Link>
                                <Link className='thm-btn' to='/'>Download Invoice</Link>
                            </div>
                        </div>
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="title" required require />
                                    <span>Enter Title</span>
                                </div>
                                
                            </div>


                            <div className="input-text" data-custom="word_count_custom_modal" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Enter Word Count</h5>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>700</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>1500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2000</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div  className="input-text  customBox">
                                <div id='word_count_custom_modal' className="input-div hidden">
                                    <input type="tel" name="custom_count"  />
                                    <span>Enter Word Count </span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="keywords" required require />
                                    <span>Please enter your keywords or say "Add Keywords" if you want us to </span>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Enter Keyword density, internal linking requirement or others </span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="target_audience" required require />
                                    <span>Tell us about your readers </span>
                                    
                                </div>
                            </div>

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>

    </div>
}

export default PostProject

