import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-style-two';
import Brand from './section-components/brand-two';
import Service from './section-components/service-v10';
import PricingV5 from './section-components/service-v2';
import ServiceV8 from './section-components/service-v8';
import TestimonialV2 from './section-components/testimonial-v3';
import BlogPost from './section-components/blog-post-v2';
import Footer from './global-components/footer-v2';
import Helmet from 'react-helmet';

import Calltoaction from './section-components/cta';


const Home_V2 = () => {
    
    return <div className="homepage">

        
        <Helmet>
            <title>On-demand Professional Content Creation | Text Mercato</title>
            <meta name="Fulfil all your content needs with TextMercato. Your end-to-end Digital Content Management Partner. Click and explore endless possibilities." />
        </Helmet>



        <Navbar />
        <Banner />
        <Brand />
        <PricingV5 />
        
        <ServiceV8 />
        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
            </section>
        <TestimonialV2 />
       
        <Service />
        <BlogPost />
        


        {/* <Faqpage /> */}
        <Calltoaction />
        <Footer />
    </div>
}

export default Home_V2

