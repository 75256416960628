import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import PricingV5 from '../section-components/service-v2';
import Calltoaction from '../section-components/cta';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestimonialCommon from '../section-components/testimonial-common';

const startup  = () => {



        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        const cssUrl =   publicUrl + "assets/css/color-2.css";
        const style = document.createElement("link");
        style.href = cssUrl;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
       

   
    

    return <div className="homepage">
		<Helmet>
            <title>Startup</title>
            <meta name="description" content="Startup" />
        </Helmet>
        <Navbar />
        <section className="banner-two  go-top banner-inner pb-0">
            <div className="container">
            <div className="row">
                <div className="col-xl-6 my-auto">
                <div className="banner-two__content">
                    <h3><span>Let the </span>Experts Visualize <span><br></br>the Art For You</span></h3>
                    <p>The graphic content requirement for every website is different. The expert team of TextMercato can quickly understand the possibilities and implement artistic changes. Let the experts assist you with professional design services for infographics, ebook designs, images, and more to communicate visually with your target audience.</p>

                    <Link to="/get-started" className="thm-btn banner-two__btn btn-big">Get Started <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                </div>{/* /.banner-two__content */}
                </div>{/* /.col-xl-7 */}

            <div className='col-xl-6'>
                <img width="85%" src={publicUrl+"assets/services/graphics/banner.png"} alt="Banner" />
            </div>


            </div>
            </div>
        </section>


		<section className="brand-one brand-one__home-two">
			<div className="container">
				<div className="block-title-two text-center">
				<h3 className="font-30">2500+ Businesses trust us with their content</h3>
				</div>{/* /.block-title-two */}
				<div className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel" data-options="{&quot;loop&quot;: true, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 5000, &quot;items&quot;: 6, &quot;margin&quot;: 50, &quot;smartSpeed&quot;: 700, &quot;nav&quot;: false, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 2, &quot;margin&quot;: 15 },&quot;575&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 },&quot;767&quot;: { &quot;items&quot;: 3, &quot;margin&quot;: 30 }, &quot;991&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 30 }, &quot;1199&quot;: { &quot;items&quot;: 4, &quot;margin&quot;: 75 }, &quot;1200&quot;: { &quot;items&quot;: 6 } }}">
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-1.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-2.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-3.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-4.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-5.png"} alt="" />
				</div>{/* /.item */}
				<div className="item">
					<img src={publicUrl+"assets/images/brand/brand-1-6.png"} alt="" />
				</div>{/* /.item */}
				
				</div>{/* /.brand-one__carousel owl-carousel owl-theme thm__owl-carousel */}
			</div>{/* /.container */}
		</section>


        <PricingV5 title="how does conent helps for companies to scale 	"></PricingV5>



        <section className="service-three white-bg   go-top">
		  <div className="container">
		    <div className="block-title-two text-center mb-0">
		      <h3>Heading for for Types of content are useful for startups which help them to scale</h3>
		    </div>{/* /.block-title-two text-center */}
			<p className='mt-3 text-center'>TextMercato has an in-house team for unlimited graphic design services to keep your business on top.</p>	

			<div className="row mt-5 services-listing image-as-icon">
				
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/Emailer Design-1.svg"} alt="Emailer Design" />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						<h3>Emailer Design</h3>
						<p>Market your brand and product with interesting and original emailer designs. Explore customized graphic designs with TextMercato to grab the attention of your target audience. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/Website _ App Banners-1.svg"} alt="Website & App Banners" />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Website & App Banners</h3>
						<p>Want to promote a new service or product? Just ask TextMercato. Our group of innovative designers will offer the best design service for your business. Get the best possible combination of art and information to make your web or app banners stand out.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/infographics-svgrepo-com 2.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Infographics </h3>
						<p>Let TextMercato enrich your content with fun and informative infographics. Trained graphic designers will create compelling content from scratch to suit your business needs.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}
				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/ebook-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Ebook Design</h3>
						<p>TextMercato’s capable team of graphic designers can simplify and organize complex data in visually appealing Ebook format. Our skillful designs and attention to detail will definitely help you to reach your target audience.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/logo-design (1) 1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>Logo Design</h3>
						<p>The logo is the face of your business. It should exhibit the purpose and objectives of your business. TextMercato designers will perfect your logo or create a new one from scratch, to establish an eye-catching identity for your brand. </p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}

				<div className="col-lg-4 col-md-6">
					<div className="team-one__single">
					<div className="team-one__image">
						<img src={publicUrl+"assets/services/graphics/gifs-1.svg"} alt={ imagealt } />
					</div>{/* /.team-one__image */}
					<div className="team-one__content">
						
						<h3>GIFs</h3>
						<p>Leave your footprint in the internet’s vast open space using short and crisp GIFs. The witty graphic content curated by TextMercato can be a powerful marketing tool for you.</p>
					</div>{/* /.team-one__content */}
					</div>{/* /.team-one__single */}
				</div>{/* /.col-lg-3 */}


				

				



				</div>{/* /.row */}

		  </div>{/* /.container */}
		</section>


        



		

        <section className="cta-two  go-top" >
          <div className="container text-center">
                <div className='row'>
                    <div className='col-lg-8 text-left my-auto'>
                        <h3>Upgrade your content with TextMercato</h3>
                    </div>
                    <div className='col-lg-4  my-auto'>
                    <Link to="/get-started" className="thm-btn">Know more <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
                    </div>
                </div>
          </div>{/* /.container */}
        </section>





		<TestimonialCommon></TestimonialCommon>

        <section className="cta-nine  go-top">
            <div className="container">

            <div className="block-title-two text-center mb-0">
                            
                            <h3>Four (4) Steps to Get Your Content!	</h3>   
                        </div>{/* /.block-title-two text-center */}
                        <p className='mt-3 text-center'>Widest network of content experts build a deep connection with your users with 100 percent original content</p>

            
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-user-alt" />
                        <h3>Create a free account (In just one minute)	 </h3>
                        <p>Create an account with by filling in some basic information, so your requirements can be registered.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-cloud-download" />
                        <h3> Share your requirements</h3>
                        <p>Share details about your requirement with some additional questions on your audience, SEO expectations, goals, etc., and place an order.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-chart-line" />
                        <h3>Work-in-progress </h3>
                        <p>Your Account Manager will review the order and allocate it to the best-suited writer and editor with relevant domain expertise for your requirement. We can connect and discuss additional information or address clarifications.</p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                <div className="col-lg-3 col-md-6">
                    <div className="cta-nine__single">
                    <div className="cta-nine__icon">
                        <i className="fal fa-gift-card" />
                        <h3>Track and approve</h3>
                        <p>All your orders can be viewed, managed, and approved via a dedicated log-in dashboard. Access anytime for real-time updates.   </p>
                    </div>{/* /.cta-nine__icon */}
                    </div>{/* /.cta-nine__single */}
                </div>{/* /.col-lg-3 col-md-6 */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
            </section>

        <section className="faq-two">
        <div className="container">
          <div className="row">
            
           
            
            
            <div className="col-lg-5">
              <div className="faq-two__content">
                <div className="block-title-two text-left">
                  
                  <h3>FAQs</h3>
                </div>{/* /.block-title-two */}

                <ul className='faq-questions-list'>
                  <li><a href='#faq-1'>Why should I choose TextMercato graphic design service?</a></li>
                  <li><a href='#faq-2'>Can I see some samples before committing to TextMercato?</a></li>
                  <li><a href='#faq-3'>Does TextMercato have the required workforce to take up large projects?</a></li>
				  <li><a href='#faq-4'>What are the best graphic design service companies?</a></li>
                  
                </ul>

                


                
              </div>{/* /.faq-two__content */}
            </div>{/* /.col-lg-6 */}


            <div className="col-lg-7 d-flex justify-content-center">
              
            <div className="inner">
                <div id='faq-1' className='faq-answer-block active'>
                  <h2>Why should I choose TextMercato graphic design service?</h2>
                  <p> Graphic design is not only about beautifying a webpage. It optimizes your chance to get your content across a larger mass efficiently with visual cues. TextMercato offers the best graphic design services and can create impactful designs for your business.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                  
                </div>
                <div id='faq-2' className='faq-answer-block '>
                  <h2>Can I see some samples before committing to TextMercato?</h2>
                  <p>Yes, we are just an email away. When you choose TextMercato as your graphic design partner, you will get all the assistance necessary for a smooth transaction.</p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
                <div id='faq-3' className='faq-answer-block '>
                  <h2>Does TextMercato have the required workforce to take up large projects?</h2>
                  <p>TextMercato has a large team of competent designers - inhouse and freelancers, to execute large projects within a reasonable deadline. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
				<div id='faq-4' className='faq-answer-block '>
                  <h2>What are the best graphic design service companies?</h2>
                  <p>There might be a bunch of graphic design providers online, but with TextMercato, you get a start-to-end content solution for your business. When your content is created in sync with your graphic, there will be no gaps in communication. </p>
                  <Link to="/gets-started" className='thm-btn faq-two__btn'>Know More <i className="fa fa-angle-double-right" /></Link>
                </div>
               
                
            </div>{/* /.inner */}
                
            
            </div>{/* /.col-lg-6 */}




            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>
        <Calltoaction />
        <Footer />
    </div>
}

export default startup 

