import React, { useEffect, useState  } from 'react';

import { readRemoteFile  } from 'react-papaparse';

 

const Sample = () => {

    let csvFile = "samples.csv";

    const [data, setData] = useState(null);
    const [uniqueSampleType, setuniqueSampleType] = useState(null);
    const [uniqueIndustry, setuniqueIndustry] = useState(null);
    // const [SampleDataCount, setuniqueSampleData] = useState(null);

    const uniqueSampleTypeData = [];
    const uniqueIndustryData = [];
    // const SampleDataCountTotal = [];
  
    useEffect(() => {
      readRemoteFile(csvFile,
        {
          complete: (results) => {
            setData(results);

            results.data.map(parsedData => { 
                if (parsedData[1] !== undefined && uniqueSampleTypeData.indexOf(parsedData[1]) === -1) {
                    uniqueSampleTypeData.push(parsedData[1])
                }
                if (parsedData[1] !== undefined  && uniqueIndustryData.indexOf(parsedData[2]) === -1) {
                    uniqueIndustryData.push(parsedData[2])
                }
                // SampleDataCountTotal[parsedData[1]] =  SampleDataCountTotal[parsedData[1]] ? SampleDataCountTotal[parsedData[1]] + 1 : 1 
            });
            setuniqueSampleType(uniqueSampleTypeData);
            setuniqueIndustry(uniqueIndustryData);
            //setuniqueSampleData(SampleDataCountTotal);

             
          },
        }
      );
    }, []);

  
    return (
    	<section className="blog-two  go-top">
        <div className='download-popup'>
              <a className='closes-popup' href='javascript:void(0)'><i className='fa fa-times'></i></a>
              {/* <iframe id='sample-download-frame' src={publicUrl+'downloadsampales'}></iframe> */}
              <iframe id='sample-download-frame' src={'https://textmercato.crestdesign.in/#/downloadsampales'}></iframe>
        </div>
			  <div className="container">
			    <div className="block-title-two text-center">
			      <div className='samples'></div>
			      <h3>Content Samples crafted by TextMercato</h3>
                    
                  <div className='row filter-form'>
                    
                    {uniqueSampleType ? (
                        <div className='col-md-6'>
                            <select id='sampleType' className='form-control samples-filter'>
                                <option value="all">All</option>
                                {
                                    uniqueSampleType.map((parsedData, index) => (
                                            <option value={""+parsedData}>{ parsedData }</option>
                                    ))

                                }
                            </select>
                        </div>
                    ) : ''
                    }

                    {uniqueIndustry ? (
                        <div className='col-md-6'>
                            <select id="industry" className='form-control samples-filter'>
                                <option value="all">All</option>
                                {
                                    uniqueIndustry.map((parsedData, index) => (
                                        <option value={""+parsedData}>{ parsedData }</option>
                                    ))

                                }
                            </select>
                        </div>
                    ) : ''
                    }
                    
                    
                    <div className='col-md-4'></div>
                  </div>

			    </div>{/* /.block-title-two */}
      <div className="">
        <ul className='sample-data-list'>
        {data ? (
                    
                    data.data.map((parsedData, index) => (

                      <li style={{display: "none"}} className='row sample-doc-block' data-type={""+parsedData[1]} data-industry={""+parsedData[2]}>
                        <div className='col-md-9'><a  data-p={""+parsedData[4]} className="download-sample-btn sample-data-title" href='javascript:void(0)'>{ parsedData[1] + ' - Sample ' + (index + 1) }</a></div>

                        <div className='col-md-3 text-right'><a href='javascript:void(0)' data-p={""+parsedData[4]} className="thm-btn blog-two__btn download-sample-btn">Download <i className="fa fa-download" /></a></div>
                      </li>
                   
                    
                    ))) : ( <div className="loader">Loading...</div>  )  }

</ul>
      </div>
      </div>{/* /.container */}

      

			</section>
    );
  };

 

export default Sample