import React from 'react';
import Navbar from '../global-components/navbar-v2';
import Footer from '../global-components/footer-v2';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useEffect } from 'react';
import axios from 'axios';

const PostProject = () => {

    
    let publicUrl = process.env.PUBLIC_URL+'/'

    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const id_token = localStorage.getItem('id_token');
    const project_id = localStorage.getItem('project_id');
    const history = useHistory();
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    
    useEffect(() => {
        const logincssUrl =   publicUrl + "assets/css/login.css";
        const loginstyle = document.createElement("link");
        loginstyle.href = logincssUrl;
        loginstyle.rel = "stylesheet";
        loginstyle.async = true;
        document.head.appendChild(loginstyle);

        
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/post-project.js";
        document.body.appendChild(minscript);
    })


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }


    const handleOnClick = e => {
    e.preventDefault();
        setErrors(null);
        setLoading(true);
        if(e.target.classList.contains('Inputerrors')){
            setLoading(false);
        }else{                            
            displayRazorpay(token);
            // history.push("/client-order-text-payments-sucessful");
        }
    }

    async function displayRazorpay(token) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const result = await axios.post("http://devapi.textmercato.com/order/api/create-order/"+project_id, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then((response) => {
            console.log(response.status);
        })
        .catch((error) => {
            setLoading(false);
            console.log(error.response);
            if(error.response.status){
                setErrors(error.response.statusText)
            }
        })

        if(errors !== null){ return false; }

        const { amount, id: order_id, currency } = result.data;
        const options = {
            key: "rzp_test_r6FiJfddJh76SI", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Soumya Corp.",
            description: "Test Transaction",
            image: 'https://www.textmercato.com/assets/images/logo-2-1.png',
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                const result = await axios.post("http://devapi.textmercato.com/order/api/create-order/success/"+project_id, data);

                alert(result.data.msg);
            },
            prefill: {
                name: "Ashraf",
                email: "ashraf@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Ashraf Corporate Office",
            },
            theme: {
                color: "#113dbc",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }



    return <div>

        <Helmet>
            <title>Post a project</title>
            <meta name="" />
        </Helmet>

        <Navbar className="light"></Navbar>
        
        <section className='login-section snippet-body post-project-section'>
        <div className="container">
            <div className="card">
                <div className="form">
                    
                    <div className="right-side post-content">
                    <div className='registration-form' >    
                      





                        <div className="main active order-review-section">
                        
                            <div className="text">
                                <h3 className='color-blue'>Order Review</h3>
                               
                            </div>

                            
                            
                            <div className=" orderReview mt-2 confirmationPage">
                                <div className="row">
                                    
                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-pull-12 col-sm-pull-12">
                                        <div className="panel panel-default requirements">
                                           
                                            <div className="panel-body requirement_summary ">
                                            <table id='grid-table' className="table borderless grid-table">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Content type </strong></td>
                                                        <td className=''><strong>Title  </strong></td>
                                                        <td className=""><strong>Words</strong></td>
                                                        <td className=''><strong>Need images  </strong></td>
                                                        <td className=""><strong>Cost</strong></td>
                                                        <td className=""><strong>Delivery</strong></td>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="">Blog</td>
                                                        <td className="">London Travel </td>
                                                        <td className="">500</td>
                                                        <td className="">4</td>
                                                        <td className=""><i class="fa fa-rupee-sign"></i> 4,350</td>
                                                        
                                                        <td className="">3-5 days</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td className="">Blog</td>
                                                        <td className="">London Travel </td>
                                                        <td className="">500</td>
                                                        <td className="">4</td>
                                                        <td className=""><i class="fa fa-rupee-sign"></i> 4,350</td>
                                                        
                                                        <td className="">3-5 days</td>
                                                        
                                                    </tr>
                                                    
                                                </tbody>
                                            </table> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 col-md-push-12 col-sm-push-12 summary-total">
                                        <div className="panel panel-default order-review-total">
                                           
                                            <div className="panel-body">
                                                    <div className="col-md-12">
                                                        <strong>Total  </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>6,910</span></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <strong>GST @18%</strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>1,244</span></div>
                                                    </div>
                                                   
                                                    <div className="col-md-12">
                                                        <strong>To Pay </strong>
                                                        <div className="pull-right"><i class="fa fa-rupee-sign"></i><span>8,154</span></div>
                                                       
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            {
                                errors !== null ? (<div className='page_errors'>{ errors }</div>) : ('')
                            }
                            
                            
                         
                            <div className="buttons button_space mt-4">
                                <Link to="/client-order-images" className="backBtn thm-btn">Back</Link>
                                <Link className="nextBtn thm-btn" onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Confirm & Pay'}</Link>
                                
                            </div>
                        </div>


                    
                        
                        
                       
                        
                        </div>                    
                            
                        
                        
                    

                    </div>
                    
                </div>
            </div>
        </div>
        </section>
        
		

		
        <Footer></Footer>
        


        <div class="modal fade" id="addnew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <form action='#' data-type="new" class="modal-content addNewArticle">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="title" required require />
                                    <span>Enter Title</span>
                                </div>
                                
                            </div>


                            <div className="input-text" data-custom="word_count_custom_modal" >
                                <div className="input-div">
                                    <h5 className='form-label-heading'>Enter Word Count</h5>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>700</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>1500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2000</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="500" /> <span>2500</span></label>
                                    <label className='sourceofpublish'><input type='radio' name='word_count' value="custom" /> <span>Custom</span></label>
                                </div>
                            </div>

                            <div  className="input-text  customBox">
                                <div id='word_count_custom_modal' className="input-div hidden">
                                    <input type="tel" name="custom_count"  />
                                    <span>Enter Word Count </span>
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="keywords" required require />
                                    <span>Please enter your keywords or say "Add Keywords" if you want us to </span>
                                    
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <textarea name="instructions" required require placeholder=' ' ></textarea>
                                    <span>Enter Keyword density, internal linking requirement or others </span>
                                   
                                </div>
                            </div>

                            <div className="input-text">
                                <div className="input-div">
                                    <input type="text" name="target_audience" required require />
                                    <span>Tell us about your readers </span>
                                    
                                </div>
                            </div>

                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
                </form>
            </div>
        </div>

    </div>
}

export default PostProject

