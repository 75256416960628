import axios from 'axios';
import React, { useEffect, useState  } from 'react';
import Sidebar from './blog-components/sidebar';
import { Link, useParams } from "react-router-dom";
import Helmet from 'react-helmet';
import Navbar from './global-components/navbar-v2';

import Faqpage from './section-components/faq-v3';
import Footer from './global-components/footer-v2';
import Calltoaction from './section-components/cta';

const FaqDetails = () => {

	let { slug } = useParams();
	
	const [error, setError] = useState(null);
	const [faq, setFaqs] = useState(null);
    const [title, setTitle] = useState("");

	useEffect(() => {
        if(typeof slug !== 'undefined' && slug !== null){
            axios
            .get('https://www.textmercato.com/cms/api/faqs/?filters[slug][$eq]='+slug+'&populate=category')
            .then(response => {
                if(response.data){
                  setFaqs(response.data.data[0].attributes);
                    setTitle(response.data.data[0].attributes.Question);
                }
            })
            .catch((error) => setError(error));
        }
	}, []);

	if (error) {
		return <div>An error occured: {error.message}</div>;
	}

    return ( <div>
	<Helmet>
            <title>{ title }</title>
            <meta name="" />
    </Helmet>
    <Navbar></Navbar>
    
     <section className="blog-list blog-details">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8">
          <h1 className=' mb-3 text-primary pt-0'>{ title ? ( title  ) : '' }</h1>
            { faq !== null && typeof faq !== 'undefined' ? (
              <div dangerouslySetInnerHTML={{ __html: faq.Answer }} />
            ) : "" }
		      
            <div className='recentFaqs mt-5'>
                <h3 className='sidebar__title pb-0 mb-0'>Other Faqs</h3>
                <Faqpage limit="6"></Faqpage>
            </div>

		      </div>{/* /.col-lg-8 */}
		      <Sidebar />
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        <Calltoaction />
        <Footer />

		</div>
		
    )
}

export default FaqDetails;
